import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, startWith, map } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { DataTransferServiceService } from 'src/app/core/services/data-transfer-service.service';
import * as nationalData from '../../../../../assets/national.json'
import { DetailComponent } from 'src/app/modules/detail/detail.component';

@Component({
  selector: 'app-searchform-modal',
  templateUrl: './searchform-modal.component.html',
  styleUrls: ['./searchform-modal.component.scss']
})
export class SearchformModalComponent implements OnInit {

  searchControl = new FormControl();
  accommodationForm: any = FormGroup;
  // filteredOptions: Observable<string[]>;
  hotels : any = []
  checkIn: any;
  checkOut: any;
  date: Date | undefined;
  bsValue: Date = new Date();
  bsRangeValue: Date[] = [];
  maxDate = new Date();
  minDate: Date = new Date();
  maxYearDate: any;
  selectedChildren: number = 0;
  selectedAdults: number = 2;
  selectedRooms: number = 1;
  bookingData: string = '';
  showDropdown: boolean = false;
  childAges: number[] = [];
  initialDate: Date = new Date();
  bsConfig: Partial<BsDaterangepickerConfig> = {};
  national: any[] = nationalData?.national;
  filteredNationalities: Observable<any[]> = new Observable<any[]>();
  nationalityControl = new FormControl();
  nationality: any;
  globalRoomIndex: any;
  submitted: boolean = false;
  userdata: any;
  // selectedInfants: number = 0;
  childrenAges: any;
  passengers: any;
  locations: any = [];
  showLocationDropdown: boolean = false;
  onClearLocation: boolean = false;
  hotelCode: any;
  isallAgesSelected: any;
  comingSoon: boolean = false;
  get roomArray() {
    return this.accommodationForm?.controls?.rooms as FormArray;
  }

  get childrensArray() {
    return this.accommodationForm?.controls?.rooms?.controls[this.globalRoomIndex]
      ?.controls?.childrensAge as FormArray;
  }

  nights: any;

  constructor(
    public bsModalRef: BsModalRef,
    private commonService: CommonService,
    private route: Router,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private dataTransferService: DataTransferServiceService,
    private modalService: BsModalService,
  ) {
    this.maxYearDate = new Date(new Date()?.getFullYear(), 11, 31); 
    this.accommodationForm = this.fb?.group({
      searchControl: this.searchControl,
      date: ['', [Validators?.required]],
      rooms: this.fb?.array([]),
      passengers: [this.bookingData, [Validators?.required]],
      // adults: [this.selectedAdults, [Validators.required]],
      nationalityControl: this.nationalityControl,
    });
    this.bookingData = ` Rooms: ${this.roomArray?.length || 1}, Adults: ${
      this.selectedAdults
    }, Children: ${this.selectedChildren}`;
    this.initialDate?.setDate(this.initialDate?.getDate() + 1);
    this.maxDate?.setDate(this.maxDate?.getDate() + 2);
    this.bsValue?.setDate(this.bsValue?.getDate() + 1);
    this.bsRangeValue = [this.bsValue, this.maxDate];
  }
  
  ngOnInit(): void {
    this.addAnotherRoom();
    this.filteredNationalities = this.nationalityControl?.valueChanges?.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  private _filter(value: any): any[] {
    const filterValue = value?.toLowerCase();
    return this.national?.filter((option) =>
      (
        option?.countryName?.toLowerCase() +
        ' - ' +
        option?.countryCode?.toLowerCase()
      )?.includes(filterValue)
    );
  }

  toggleDropdown(event: Event) {
    event?.preventDefault();
    this.showDropdown = !this.showDropdown;
    this.countPassengers();
  }

  addChildrens(i: any, fromOnChange: any, noOfChildren: any) {
    this.globalRoomIndex = i;
    this.accommodationForm?.controls?.rooms?.controls[
      this.globalRoomIndex
    ]?.controls?.childrensAge?.clear();
    this.addNewChildrens(noOfChildren);
  }

  search() {
    this.submitted = true;

    if (this.accommodationForm?.valid) {
      let params = {
        hotel: sessionStorage?.getItem('accId'),
        // searchHotel: '',
        role: sessionStorage?.getItem('role'),
        // currentPageNumber: 1,
        checkInDate: this.checkIn,
        checkOutDate: this.checkOut,
        nationality: this.nationality,
        residency: this.nationality,
        nights: this.nights,
        rooms: this.passengers?.rooms,
        // rooms: Number(this.accommodationForm.get('rooms').value),
        // adults: Number(this.accommodationForm.get('adults').value),
        // children: Number(this.accommodationForm.get('children').value),
        // infants: '0',
      };
      if (!this.passengers) {
        params['rooms'] = [
          {
            adults: 2,
            children: 0,
            // infants: 0,
            childAges: [],
          },
        ];
      }
      this.comingSoon = true
      
      // if(this.route.url.includes('favourites')) {
      //   this.commonService.getList(params).subscribe(() => {
      //     let detailRequest = {
      //       accId: sessionStorage.getItem('accId'),
      //       searchId: '',
      //       request: params,
            
      //     };
      //     this.commonService.updateDetailQuery(detailRequest);
      //     // this.bsModalRef?.hide()
      //     // this.modalService.show(DetailComponent, {
      //     //   class: 'modal-xl',
      //     // });
      //   })
      // }
      
      // let obj : any = {
      //   "location": this.searchControl.value,
      //   "nationality": this.nationalityControl.value,
      //   "countryCode": this.nationality,
      //   "hotelCode": this.hotelCode ? this.hotelCode : null
      // }
      // sessionStorage.setItem('userData', JSON.stringify(obj))
      // sessionStorage.setItem('national', JSON.stringify(obj.nationality))
      // this.commonService.updateSearchQuery(params);
      // this.route.navigate(['Listing']);
      // this.dataTransferService.searchFormData(this.roomArray)
    }
  }
  onclick(eve: any) {
  }

  addAnotherRoom() {
    if (this.roomArray?.length == 5) return;
    let obj = {
      room: this.fb?.control(1, Validators?.required),
      adults: this.fb?.control(2, Validators?.required),
      children: this.fb?.control(0, Validators?.required),
      // infants: this.fb.control(0, Validators.required),

      childrensAge: this.fb?.array([]),
    };
    let fgr = this.fb?.group(obj);
    this.roomArray?.push(fgr);
  }

  removeRoom(index: number) {
    if (index > 0) {
      this.roomArray?.removeAt(index);
    }
  }

  countPassengers() {
    this.selectedAdults = 0;
    this.selectedChildren = 0;
    // this.selectedInfants = 0;
    this.passengers = {
      rooms: [],
    };
    this.roomArray?.controls?.forEach((ele: any, i) => {
      this.childrenAges = [];
      this.selectedAdults += +ele?.controls?.adults?.value;
      this.selectedChildren += +ele?.controls?.children?.value;
      // this.selectedInfants += +ele.controls.infants.value;
      this.childrenAges = ele?.controls?.childrensAge?.controls?.map(
        (item: any) => {
          return +item?.controls?.age?.value;
        }
      );
      // let localScope = false;
      //   if(this.childrenAges.some((age: any) => age == 0)) {
      //     localScope = true
      //   }

      let object = {
        adults: +ele?.controls?.adults?.value,
        children: +ele?.controls?.children?.value,
        // infants: +ele.controls.infants.value,
        childAges: this.childrenAges,
      };
      this.passengers?.rooms?.push(object);
    });
    this.bookingData = ` Rooms: ${this.roomArray?.length}, Adults: ${this.selectedAdults}, Children: ${this.selectedChildren}`;
  }
  addNewChildrens(noOfChildren: number) {
    if (+noOfChildren == 0) return;
    let obj = {
      age: this.fb?.control(null, Validators?.required),
    };
    let fg = this.fb?.group(obj);
    this.childrensArray?.push(fg);
    this.addNewChildrens(+noOfChildren - 1);
  }

  updateBookingData() {
    this.submitted =true;
    this.countPassengers();
    if(this.roomArray.valid) this.showDropdown = false
  }

  childrenArray(): number[] {
    return Array?.from({ length: this.selectedChildren }, (_, i) => i + 1);
  }

  countSelectedDays(): number | string {
    if (this.bsRangeValue && this.bsRangeValue?.length === 2) {
      const startDate = this.bsRangeValue[0];
      const endDate = this.bsRangeValue[1];

      const timeDifference = endDate?.getTime() - startDate?.getTime();
      const daysDifference = timeDifference / (1000 * 3600 * 24);

      this.nights = daysDifference + 1 - 1;
      return daysDifference + 1 - 1 + ' ' + 'Nights';
    }

    return 0;
  }

  onDateRangeChange(event: any): void {
    // Access the selected date range values from the event
    const checkin = event[0];
    const checkout = event[1];
    if (checkin) {
      const checkInFormattedDate = this.datePipe?.transform(
        checkin,
        'dd-MM-yyyy'
      );
      this.checkIn = checkInFormattedDate;
      // console.log(checkInFormattedDate);

      // Use formattedDate as needed
    }

    if (checkout) {
      const checkOutformattedDate = this.datePipe?.transform(
        checkout,
        'dd-MM-yyyy'
      );
      this.checkOut = checkOutformattedDate;
      // console.log(checkOutformattedDate);

      // Use formattedDate as needed
    }
  }

  onSelectNationality(value: string): void {
    this.nationality = value;
  }


}


