import { Injectable } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private readonly EMAIL_JS_SERVICE_ID = 'service_f2jexre'; // Replace with your EmailJS Service ID
  private readonly EMAIL_JS_TEMPLATE_ID = 'template_gjzl2xq'; // Replace with your EmailJS Template ID
  private readonly EMAIL_JS_USER_ID = 'B3kComISDhcblJIAj';

  constructor() {}

  sendEmail(
    name: string,
    email: string,
    number: string,
    message: string
  ): Promise<EmailJSResponseStatus> {
    const templateParams = {
      name,
      email,
      number,
      message,
    };

    // return emailjs.send(this.EMAIL_JS_SERVICE_ID, this.EMAIL_JS_TEMPLATE_ID, templateParams, this.EMAIL_JS_USER_ID);

    return emailjs.send(
      this.EMAIL_JS_SERVICE_ID,
      this.EMAIL_JS_TEMPLATE_ID,
      {
        from_name: templateParams?.name,
        to_name: 'Rateloc',
        from_email: templateParams?.email,
        from_number: templateParams?.number,
        message: templateParams?.message,
      },
      this.EMAIL_JS_USER_ID
    );
  }
}
