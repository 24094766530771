import { ContactusComponent } from './components/Contactus/Contactus.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AboutusComponent } from './components/Aboutus/Aboutus.component';
import { SharedModule } from '../shared/shared.module';
import { AccommodationComponent } from './components/accommodation/accommodation.component';
import { AttractionsComponent } from './components/attractions/attractions.component';
import { CarRentalComponent } from './components/carRental/carRental.component';
import { TransfersComponent } from './components/transfers/transfers.component';
import { MaterialModule } from '../modules/material/material.module';
import { CommonModule, DatePipe } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { BsDatepickerModule,BsDatepickerConfig  } from 'ngx-bootstrap/datepicker';
import { ForgottenPasswordComponent } from './components/forgottenPassword/forgottenPassword.component';
import { BookingSummaryComponent } from './components/bookingSummary/bookingSummary.component';

@NgModule({
  declarations: [
    AboutusComponent,
    ContactusComponent,
    AccommodationComponent,
    AttractionsComponent,
    CarRentalComponent,
    TransfersComponent,
    ForgottenPasswordComponent,
    BookingSummaryComponent,
  ],
  imports: [
    FormsModule,
    HttpClientModule,
    SharedModule,
    MaterialModule,
    ReactiveFormsModule,
    CommonModule,
    CalendarModule,
    InputTextModule,
    ButtonModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [
    AboutusComponent,
    ContactusComponent,
    AccommodationComponent,
    AttractionsComponent,
    CarRentalComponent,
    TransfersComponent,
    ForgottenPasswordComponent
  ],
  providers: [DatePipe,BsDatepickerConfig],
  bootstrap: []
})
export class CoreModule { }
