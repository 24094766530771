import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataTransferServiceService {
  public shouldShowLoader: boolean = true;
  accountDropdownTransfer: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public accountDropdownTransfer$ =
    this.accountDropdownTransfer?.asObservable();
  logDataTransfer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public logDataTransfer$ = this.logDataTransfer?.asObservable();
  closeModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public closeModal$ = this.closeModal?.asObservable();
  boardbase: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public boardbase$ = this.boardbase?.asObservable();
  bookingHistoryData: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public bookingHistoryData$ = this.bookingHistoryData?.asObservable();
  bookingDetailData: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public bookingDetailData$ = this.bookingDetailData?.asObservable();
  formData: any;
  private transferData = new BehaviorSubject<any>(null);
  transferData$ = this.transferData.asObservable();

  constructor() {}

  logData(data: any) {
    this.logDataTransfer?.next(data);
  }

  accountDropdownData(data: any) {
    this.accountDropdownTransfer?.next(data);
  }

  closeModalM(data: any) {
    this.closeModal?.next(data);
  }
  boardbaseData(data: any) {
    this.boardbase?.next(data);
  }
  bookingDetail(data: any) {
    this.bookingDetailData?.next(data);
  }

  getBookingHistoryData() {
    let result = {};
    let data = sessionStorage?.getItem('bookingData');
    if (data) result = JSON?.parse(data);
    return result;
  }

  setBookingHistoryData(obj: any) {
    sessionStorage?.setItem('bookingData', JSON?.stringify(obj));
  }
  getFormData() {
    let result = null;
    let data = sessionStorage?.getItem('roomsArray');
    if (data) result = JSON?.parse(data);
    return result;
  }

  setFormData(obj: any) {
    sessionStorage?.setItem('roomsArray', JSON?.stringify(obj));
    let backupArray: any = sessionStorage?.getItem('backUpArray');
    const currentTime = new Date().getTime(); // Get the current time in milliseconds
      backupArray = JSON?.parse(backupArray);
      if (backupArray) {
        if (backupArray.expiryTime < currentTime) {
          sessionStorage?.removeItem('backUpArray');
        }
      }
  }

  setTransferData(data: any) {
    this.transferData.next(data);
  }
}
