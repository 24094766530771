import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-Aboutus',
  templateUrl: './Aboutus.component.html',
  styleUrls: ['./Aboutus.component.scss']
})
export class AboutusComponent implements OnInit {

  image: any;
  route: any;

  constructor(private configService : ConfigService, private router: Router) { }

  ngOnInit() {
    this.image = this.configService?.getImages();
    this.route = this.router?.url
  }

}
