import { Injectable } from '@angular/core';
import * as Config  from "../../../assets/config.json";

@Injectable({
  providedIn: 'root',
})

export class ConfigService {

  config: any  = Config
  environmentName: any = this.config?.environment;
  images : any = this.config?.images
  appName: any = this.config?.appName;
  breadCrumb : any = this.config?.breadCrumbs

  constructor() { }

  getApiUrl(): any {
    return this.config?.baseUrls[this.environmentName];
  }

  getBreadcrumb() : any {
    return this.breadCrumb;
  }

  getImages(): any {
    return this.images;
  }

}