import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/core/services/common.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { DataTransferServiceService } from 'src/app/core/services/data-transfer-service.service';

@Component({
  selector: 'app-transfers-summary',
  templateUrl: './transfers-summary.component.html',
  styleUrls: ['./transfers-summary.component.scss']
})
export class TransfersSummaryComponent {
  image: any
  journeyArray:any = []
  paymentMethod: string = '';
  refundable: boolean = false;
  submit : boolean = false
  bookingData: any
  transferBookingForm!: FormGroup
  apiCurrencyValue: any;
  ratehawkApiCurrencyValue: any;
  transferData: any
  departureData: any
  transferBody: any
  @ViewChild('successModal', { static: true }) successModal: any;
  bsModalRef: any = BsModalRef;
  extras = [
    { name: 'Golf Bags', price: 26.27, quantity: 1 },
    { name: 'Extra luggage', price: 8.76, quantity: 0 }
  ];
  currencyCode: any;
  userId: any;
  stripeBookingSub: any;
  priceMap: any

  constructor(private configService : ConfigService, private fb : FormBuilder, private DataTransferServiceService: DataTransferServiceService, private datePipe: DatePipe,
    private commonService: CommonService, private modalService: BsModalService,
  ) {
    this.transferBookingForm = this.fb?.group({
      
      email: this.fb?.control('', [Validators?.required, this.emailValidation()]),
      number: this.fb?.control(''),
      country: this.fb?.control('', Validators?.required),
      agentEmail: this.fb?.control('', [Validators?.required, this.emailValidation()]),
      // number: this.fb?.control(''),
      // country: this.fb?.control(''),
      fname: this.fb?.control('', Validators?.required),
      lname: this.fb?.control('', Validators?.required),
      title: this.fb?.control('', Validators?.required),
      specialRequest: this.fb?.control(null),
      cancellation: this.fb?.control(null, Validators?.required),
      
      fromFlightCode: this.fb?.control('', Validators?.required),
      fromFlightArrival: this.fb?.control('', Validators?.required),
      fromFlightName: this.fb?.control('', Validators?.required),
      toFlightCode: this.fb?.control(null),
      toFlightDeparture: this.fb?.control(null),
      toFlightName: this.fb?.control(null),
    })
  }

  ngOnInit() {
    this.image = this.configService?.getImages();
    this.transferBody = JSON.parse(sessionStorage?.getItem('transferBody')!);
    // this.paymentMethod = JSON.parse(sessionStorage?.getItem('paymentMethod')!);
    this.paymentMethod = sessionStorage?.getItem('paymentMethod') ?? '';
    
    // this.DataTransferServiceService.transferData$.subscribe((data) => {
    //   if (data) {
    //     this.transferData = data;
    //   }
    // });
    // console.log(this.transferBody)
    this.bookingData = sessionStorage.getItem('transferListing')
    if(this.bookingData) this.bookingData = JSON.parse(this.bookingData) 
      console.log(this.bookingData, 'bookingData')
    this.transferData = this.bookingData;
  //   if (Array.isArray(this.bookingData)) {
  //     this.transferData = this.bookingData.find(item => item.direction === 'ARRIVAL');
  //     this.departureData = this.bookingData.find(item => item.direction === 'DEPARTURE');
  // }
    // this.apiCurrencyValue = sessionStorage.getItem('currencyApi');
    this.ratehawkApiCurrencyValue = sessionStorage.getItem('ratehawkCurrencyApi');
    const storedCurrency = sessionStorage?.getItem('currency');
    if(storedCurrency){
      let currencyData = JSON?.parse(storedCurrency);
      this.currencyCode = currencyData?.code;
    }
    this.userId = sessionStorage.getItem('userId');
    
    const email = sessionStorage?.getItem('email');
    const phoneNumber = sessionStorage?.getItem('phoneNumber');
    if (email || phoneNumber) {
      this.transferBookingForm?.get('email')?.setValue(email);
      this.transferBookingForm?.get('phone')?.setValue(phoneNumber);
    }
    
    let natnl = sessionStorage?.getItem('nationality');
    console.log(natnl);
    
    if (natnl) {
      this.transferBookingForm?.get('country')?.setValue(natnl);
    }
     
  }

  getFormattedDate(dateString: string): string | null {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return null; // Return null if the date is invalid
    }
    return this.datePipe.transform(date, 'EEE d MMM - HH:mm');
  }
  
  getEstimatedDropoffTime(pickupDateTime: string, journeyTime: string): string | null {
    const pickupDate = new Date(pickupDateTime);
    if (isNaN(pickupDate.getTime())) {
      return null; // Return null if the date is invalid
    }
  
    // Parse the journey time in minutes
    const journeyMinutes = parseInt(journeyTime, 10);
    if (isNaN(journeyMinutes)) {
      return null; // Return null if the journey time is invalid
    }
  
    // Add the journey time to the pickup time
    pickupDate.setMinutes(pickupDate.getMinutes() + journeyMinutes);
  
    // Return the formatted dropoff time
    return this.datePipe.transform(pickupDate, 'EEE d MMM - HH:mm');
  }

  emailValidation(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const emailRegex: RegExp =
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const valid = emailRegex?.test(control?.value);
      return valid ? null : { invalidEmail: true };
    };
  }

  incrementQuantity(extra: any): void {
    extra.quantity++;
  }

  decrementQuantity(extra: any): void {
    if (extra.quantity > 0) {
      extra.quantity--;
    }
  }

  paymentMethods(value: string) {
    this.paymentMethod = value;
  }

  booking() {
    this.journeyDetails()
    this.submit = true
    let params
    sessionStorage.setItem('paymentMethod', this.paymentMethod);
    if (this.transferBookingForm.valid) {
      params = {
        email: this.transferBookingForm.value.email,
        lastName: this.transferBookingForm.value.lname,
        firstName: this.transferBookingForm.value.fname,
        phoneNumber: this.transferBookingForm.value.number,
        journey: this.journeyArray,
        price: this.priceMap,
        userId: this.userId,
        paymentMethod: this.paymentMethod, // add this line
        pax: this.transferBody.pax
      }
      
      // let manageData = {
      //     email: this.transferBookingForm?.get('email')?.value,
      //     phoneNumber: this.transferBookingForm?.get('phone')?.value,
      //     countryCode: this.transferBookingForm?.get('country')?.value,
      // };
      console.log(params)
      if (this.paymentMethod == 'payByCard') {
        sessionStorage?.setItem('bookingParams', JSON?.stringify(params));

        let bookingType = {
          amount: this.calculateTotalPrice() ,
          currency: this.currencyCode, 
          transfer : true
        };
      this.stripeBookingSub?.unsubscribe()
        this.stripeBookingSub = this.commonService?.stripeBooking(bookingType)?.subscribe((data) => {
    
          window.location.href = data?.url;
        });
        // sessionStorage?.setItem('paymentMethod', 'cardPayment');
        // this.stripeBooking();
        // this.loader = true;
      } else {
        sessionStorage?.setItem('paymentMethod', 'manualPayment');
        // this.apiInProgress = true;
        // this.booking();
        // this.loader = true;
      }
    } 
  }

  journeyDetails() {
    let j = this.transferBody.from.type == 'IATA' ? 0 : this.bookingData.length == 1 ? 0 : 1
    let obj = {}
    while(this.journeyArray.length < this.bookingData.length) {
        obj = {
          direction: this.bookingData[j].direction,
          code: this.journeyArray.length == 0 ? this.transferBookingForm.value.fromFlightCode : this.transferBookingForm.value.toFlightCode,
          time: this.journeyArray.length == 0 ? this.transferBookingForm.value.fromFlightArrival : this.transferBookingForm.value.toFlightDeparture,
          rateKey: this.bookingData[j].rateKey,
        }
     if (this.journeyArray.length == 0) {
      this.priceMap = {
        sellingPrice: this.bookingData[j].price.amount,
        convertedCurrency: this.currencyCode,
        currency: this.bookingData[j].price.currency,
        convertedPrice: this.bookingData[j].price.amount * this.ratehawkApiCurrencyValue
      }
     } else {
        this.priceMap.sellingPrice += this.bookingData[j].price.amount
        this.priceMap.convertedPrice += this.bookingData[j].price.amount * this.ratehawkApiCurrencyValue
      }
     
        this.journeyArray.push(obj)
       if(this.bookingData.length == 2) this.transferBody.from.type == 'IATA' ? j++ : j--
    }
  }
  
  calculateTotalPrice(): number {
    let totalPrice = parseFloat(
      this.priceMap.sellingPrice
    );
    if (!isNaN(totalPrice)) {
      let percentage = this.priceMap.convertedPrice * 1.015;
      return +percentage.toFixed(2);
    }
    return 0;
  }

  openModal() {
    this.bsModalRef = this.modalService?.show(this.successModal, {
      class: 'modal-lg modal-dialog-centered',
      // backdrop: 'static',
    });
  }
 
  closeModal() {
    this.bsModalRef?.hide();
  }
  
}
