import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { data, event } from 'jquery';
import { Observable, startWith, map } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { DataTransferServiceService } from 'src/app/core/services/data-transfer-service.service';
import { LoadingService } from 'src/app/core/services/Loading.service';

@Component({
  selector: 'app-transfers-listing',
  templateUrl: './transfers-listing.component.html',
  styleUrls: ['./transfers-listing.component.scss']
})

export class TransfersListingComponent {


  searchControl = new FormControl();
  options: string[] = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];
  filteredOptions: Observable<string[]>;

  initialDate: Date = new Date();
  maxDate = new Date();
  minDate: Date = new Date();
  maxYearDate: any;
  transfer: any;
  bsRangeValueReturn: any
  dropOffTime: any
  // showDetails: boolean[] = [];
  bsRangeValue: any;
  checkIn: any = sessionStorage.getItem('formattedDate');
  checkOut: any = sessionStorage.getItem('formattedDateReturn');
  nights: any;
  searchForm! : FormGroup
  locations: { name: string; type: string }[] = [];
  dropOffLocations: { name: string; type: string }[] = [];


  // Passengers dropdown arrays
  adultsArray = Array.from({ length: 10 }, (_, i) => i + 1);
  childrenArray = Array.from({ length: 6 }, (_, i) => i); // 0-5
  infantsArray = Array.from({ length: 6 }, (_, i) => i); // 0-5
  childrenAgeArray = Array.from({ length: 16 }, (_, i) => i + 2); // 2-17

  // Object to hold passenger details
  passengerDetails = {
    adults: 2,
    children: 0,
    infants: 0
  };

  // Array to hold ages of selected children
  childAgeArray: number[] = [];

  // To manage the dropdown visibility
  isDropdownOpen = false;
  transferlist: any;
image: any;
dropOffTransfer: any
pickUpTime: any
showDropdown: boolean = false
hotelLocations: any
terminalLocations: any
selectedTransfer: any
hotelData: any
selectedDropoffTransfer: any
showDropdown2: any
dropOffHotelLocations: any
dropOffTerminalLocations: any
dropOffData: any
transferData: any
showReturnInfo: boolean = false;
body: any
showLoader: boolean = false;
// showArrival: boolean = false;
// showDeparture: boolean = false;
showArrival: boolean = true;  // Arrival is open by default
showDeparture: boolean = false;
showDetails: boolean[] = [];  // For handling "More Information" toggling
transferArray : any = []

  constructor(private router: Router, private datePipe: DatePipe, private fb : FormBuilder, private commonService: CommonService, private configService : ConfigService, private DataTransferServiceService: DataTransferServiceService
    , private loadingService: LoadingService
  ) {
    this.filteredOptions = this.searchControl?.valueChanges?.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.initialDate?.setDate(this.initialDate?.getDate() + 1);
    this.maxYearDate = new Date(this.initialDate);
    this.maxYearDate.setDate(this.initialDate?.getDate() + 365);
  }

  ngOnInit() {
    this.loader()
    this.image = this.configService?.getImages();
    let body = sessionStorage.getItem("transferBody");
  
    if (body) {
      const parsedData = JSON.parse(body);
      this.selectedTransfer = parsedData.from
      this.selectedDropoffTransfer  = parsedData.to
      this.bsRangeValue = parsedData.formattedDate
      this.bsRangeValueReturn = parsedData?.formattedDateReturn
      this.pickUpTime = parsedData.onewayDateTime.split('T')[1].slice(0, 5);  // Extract time in HH:mm
      this.dropOffTime = parsedData?.roundTripDateTime?.split('T')[1]?.slice(0, 5);  // Extract time in HH:mm
      this.transfer = parsedData.from.name;
      this.dropOffTransfer = parsedData.to.name;
      this.passengerDetails = parsedData.pax;
      this.showReturnInfo = parsedData?.formattedDateReturn !== undefined;
    }
    
    this.getTransferList(body);
  }

  loader() {
    this.loadingService?.setLoading(true);
    this.showLoader = true
    setTimeout(() => {
      this.loadingService.setLoading(false);
      this.showLoader = false;
    }, 5000);
  }

  ngOnDestroy() {
    sessionStorage.removeItem('formattedDate');
  }

  getTransferList(body: any){
    this.commonService.getTransferList(body).subscribe((data: any)=>{
      this.transferlist=data['data']
      console.log(data)
    })
  }

  // toggleDetails(index: number) {
  //   // Toggle the visibility for the specific card by index
  //   this.showDetails[index] = !this.showDetails[index];
  // }

  // Function to toggle the visibility of the passengers dropdown
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

//   onSearchTransfer(event: any){
//     if(this.transfer.length>2){
//       this.commonService.getTransfers(this.transfer).subscribe((data: any) => {
// console.log("print data"+ data)
//       })
//     }
//   }

  // Function to update the childAgeArray based on the number of children selected
  updateChildrenAges() {
    const childrenCount = this.passengerDetails.children;
    if (childrenCount > this.childAgeArray.length) {
      // Add missing age fields
      for (let i = this.childAgeArray.length; i < childrenCount; i++) {
        this.childAgeArray.push(2); // Default age set to 2
      }
    } else if (childrenCount < this.childAgeArray.length) {
      // Remove extra age fields
      this.childAgeArray.splice(childrenCount);
    }
  }

  // Get a summary of selected passengers to display in the input field
  getPassengerSummary() {
    return `${this.passengerDetails.adults} Adults, ${this.passengerDetails.children} Children, ${this.passengerDetails.infants} Infants`;
  }

  // Existing Onsearch method
  // Onsearch(){
  //   const Obj = {
  //     from: this.transfer,
  //     to: this.dropOffTransfer,
  //     onewayDateTime: this.bsRangeValue[0].toISOString().split('T')[0] + 'T' + this.pickUpTime + ":00",
  //     pax: this.passengerDetails,
  //     pickUpTime: this.pickUpTime 
  //   };
  //   sessionStorage.setItem('transferBody', JSON.stringify(Obj));
  //   this.router.navigate(['transfers-listing']);
  // }

  Onsearch(){
    this.loader()
    console.log(this.bsRangeValue)
    console.log(this.bsRangeValue)
    // const formattedDate = this.datePipe.transform(this.bsRangeValue, 'dd MMM');
    // if (formattedDate !== null) {
    //   sessionStorage.setItem('formattedDate', formattedDate);
    // }

    // const formattedDateReturn = this.showReturnInfo ? this.datePipe.transform(this.bsRangeValueReturn, 'dd MMM') : null;
    // if (formattedDateReturn !== null) {
    //   sessionStorage.setItem('formattedDateReturn', formattedDateReturn);
    // }
    let Obj ={
      from: this.selectedTransfer, 
      to: this.selectedDropoffTransfer,
      role: sessionStorage.getItem("role"),
      onewayDateTime: this.checkIn+ "T" + this.pickUpTime + ":00",
      roundTripDateTime: this.showReturnInfo ? this.checkOut+ "T" + this.dropOffTime + ":00" : undefined,
      pax: this.passengerDetails,
      uplift: sessionStorage.getItem("uplift"),
      formattedDate: this.datePipe.transform(this.checkIn, 'dd MMM'),
      formattedDateReturn: this.showReturnInfo ? this.datePipe.transform(this.checkOut, 'dd MMM') : undefined,
    }
    sessionStorage?.setItem('transferBody', JSON.stringify(Obj));
    console.log(Obj)
    this.getTransferList(Obj)
  }

  // Existing method for handling date range changes
  onPickUpTransfer(event: any): void {
    // Access the selected date range values from the event
    let checkout = event
    if (checkout) {
      const checkOutformattedDate = this.datePipe.transform(
        checkout,
        'yyyy-MM-dd'
      );
      this.checkIn = checkOutformattedDate;
      // console.log(checkOutformattedDate);
      console.log('Check-out:', checkOutformattedDate);
 
      // Use formattedDate as needed
    }
  }

  onDropOffTransfer(event: any) {
    // this.arrivalDate = event;
    let checkout = event
    if (checkout) {
      const checkOutformattedDate = this.datePipe.transform(
        checkout,
        'yyyy-MM-dd'
      );
      this.checkOut = checkOutformattedDate;
      // console.log(checkOutformattedDate);
      console.log('Check-out:', checkOutformattedDate);
 
      // Use formattedDate as needed
    }
 
  }

  // Existing method to count the number of nights between selected dates
  countSelectedDays(checkIn?: any, checkOut?: any) {
    if (checkIn && checkOut) {
      const startDate = checkIn;
      const endDate = checkOut;

      const timeDifference = endDate?.getTime() - startDate?.getTime();
      const daysDifference = timeDifference / (1000 * 3600 * 24);

      this.nights = daysDifference + 1 - 1;
      return daysDifference + 1 - 1 + ' ' + 'Nights';
    }

    return 0;
  }

  // Private method to filter options for search
  private _filter(value: string): string[] {
    const filterValue = value?.toLowerCase();
    return this.options?.filter(option => option?.toLowerCase()?.includes(filterValue));
  }

  submitData(data: any) {
    if (this.showReturnInfo) {
        this.transferArray.push(data);
        if (this.transferArray.length === 2) {
            sessionStorage.setItem("transferListing", JSON.stringify(this.transferArray));
            this.router?.navigate(['/transfers-Summary']);
            console.log('Both selected:', this.transferArray);
        }
    } else {
        this.transferArray.push(data);
        sessionStorage.setItem("transferListing", JSON.stringify(this.transferArray));
        this.router?.navigate(['/transfers-Summary']);
        console.log('Either one selected:', this.transferArray);
    }
    if (this.showArrival) {
        this.toggleArrival();
    }
}

  selectLocation(location: { name: string; type: string }, i: number) {
    this.transfer = location.name;
    const selectedIndex = this.locations.findIndex((loc) => loc.name === location.name);
    this.selectedTransfer = this.hotelData[selectedIndex];
    this.showDropdown = false; // Hide dropdown after selection
  }

  onSearchTransfer(event: any) {
    if (this.transfer.length > 2) {
      this.commonService.getTransfers(this.transfer).subscribe((response: any) => {
        if (response.status && response.data) {
          this.hotelData = response.data.hotels.concat(response.data.terminals);
          this.hotelLocations = response.data.hotels.map((hotel: any) => ({ name: hotel.name, type: 'hotel' }));
          this.terminalLocations = response.data.terminals.map((terminal: any) => ({ name: terminal.name, type: 'terminal' }));
          this.locations = this.hotelLocations.concat(this.terminalLocations); // Keep a combined list if needed
        }
      });
    }
  }

  onSearchDropOff(event: any) {
    if (this.dropOffTransfer.length > 2) {
      this.commonService.getTransfers(this.dropOffTransfer).subscribe((response: any) => {
        if (response.status && response.data) {
          this.dropOffData = response.data.hotels.concat(response.data.terminals);
          this.dropOffHotelLocations = response.data.hotels.map((hotel: any) => ({ name: hotel.name, type: 'hotel' }));
          this.dropOffTerminalLocations = response.data.terminals.map((terminal: any) => ({ name: terminal.name, type: 'terminal' }));
          this.dropOffLocations = this.dropOffHotelLocations.concat(this.dropOffTerminalLocations); // Combined list if needed
        }
      });
    }
  }

  selectLocation2(dropOffLocation: { name: string; type: string }, i: number) {
    this.dropOffTransfer = dropOffLocation.name;
    const selectedIndex = this.dropOffLocations.findIndex((loc) => loc.name === dropOffLocation.name);
    this.selectedDropoffTransfer = this.dropOffData[selectedIndex];
    this.showDropdown2 = false; // Hide dropdown after selection
  }

  toggleReturnInfo() {
    this.showReturnInfo = !this.showReturnInfo;
  }



  // Method to toggle the arrival section
  toggleArrival() {
    this.showArrival = !this.showArrival;
    if (!this.showArrival) {
      this.showDeparture = true; // Collapse departure when arrival is open
    }
  }

  // Method to toggle the departure section
  toggleDeparture() {
    this.showDeparture = !this.showDeparture;
    // if (this.showDeparture) {
    //   this.showArrival = false; // Collapse arrival when departure is open
    // }
  }

  // Method to add selected arrival data
  // addArrival(item: any) {
  //   console.log('Arrival selected:', item);
  //   this.showArrival = false;
  //   this.showDeparture = true
  //   // Save arrival data logic here (e.g., send it to the server or store it)
  // }

  // Method to add selected departure data
  addDeparture(item: any) {
    console.log('Departure selected:', item);
    // Save departure data logic here
  }

  // Method to toggle the details section
  toggleDetails(index: number) {
    this.showDetails[index] = !this.showDetails[index];
  }

}
