import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable, Subscription, map, of, startWith, switchMap } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { debounceTime } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {
  BsDatepickerViewMode,
  BsDaterangepickerConfig,
  BsDaterangepickerDirective,
} from 'ngx-bootstrap/datepicker';

import * as nationalData from '../../../../assets/national.json';
import { DataTransferServiceService } from '../../services/data-transfer-service.service';

@Component({
  selector: 'app-accommodation',
  templateUrl: './accommodation.component.html',
  styleUrls: ['./accommodation.component.scss'],
})
export class AccommodationComponent implements OnInit, OnDestroy {

  searchControl = new FormControl();
  accommodationForm: any = FormGroup;
  // filteredOptions: Observable<string[]>;
  hotels : any = null
  checkIn: any;
  checkOut: any;
  date: Date | undefined;
  bsValue: Date = new Date();
  bsRangeValue: Date[] = [];
  maxDate = new Date();
  minDate: Date = new Date();
  maxYearDate: any;
  selectedChildren: number = 0;
  selectedAdults: number = 2;
  selectedRooms: number = 1;
  bookingData: string = '';
  showDropdown: boolean = false;
  childAges: number[] = [];
  initialDate: Date = new Date();
  bsConfig: Partial<BsDaterangepickerConfig> = {};
  national: any[] = nationalData?.national;
  filteredNationalities: Observable<any[]> = new Observable<any[]>();
  nationalityControl = new FormControl();
  nationality: any;
  globalRoomIndex: any;
  submitted: boolean = false;
  userdata: any;
  // selectedInfants: number = 0;
  childrenAges: any;
  passengers: any;
  locations: any = null;
  showLocationDropdown: boolean = false;
  onClearLocation: boolean = false;
  hotelCode: any;
  isallAgesSelected: any;
  defaultLocations : any;
  defaultHotels : any;
  customDubaiHotels : any;
  customDohaHotels : any;
  customAntalyaHotels : any;
  hotelSub!: Subscription;
  locationSub!: Subscription;
  customMaldivesHotels: any;
  customMauritiusHotels: any;
  customPhuketHotels: any;
  customBaliHotels: any;
  customNewYorkHotels: any;
  customLasVegasHotels: any;
  customAbuDhabiHotels: any;
  customHotels: any;
  registerNationality: boolean = false;
  isRefundableDate: boolean = false;
  get roomArray() {
    return this.accommodationForm?.controls?.rooms as FormArray;
  }

  get childrensArray() {
    return this.accommodationForm?.controls?.rooms?.controls[this.globalRoomIndex]
      ?.controls?.childrensAge as FormArray;
  }

  nights: any;

  constructor(
    private commonService: CommonService,
    private route: Router,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private dataTransferService: DataTransferServiceService
  ) {
    // this.maxYearDate = new Date(new Date()?.getFullYear(), 11, 31); 
    this.initialDate?.setDate(this.initialDate?.getDate() + 1);
    this.maxYearDate = new Date(this.initialDate);
    this.maxYearDate.setDate(this.initialDate?.getDate() + 365);
    this.accommodationForm = this.fb?.group({
      searchControl: this.searchControl,
      date: ['', [Validators?.required]],
      rooms: this.fb?.array([]),
      passengers: [this.bookingData, [Validators?.required]],
      // adults: [this.selectedAdults, [Validators.required]],
      nationalityControl: this.nationalityControl,
    });
    this.bookingData = ` Rooms: ${this.roomArray?.length || 1}, Adults: ${
      this.selectedAdults
    }, Children: ${this.selectedChildren}`;
    this.maxDate?.setDate(this.maxDate?.getDate() + 2);
    this.bsValue?.setDate(this.bsValue?.getDate() + 1);
    this.bsRangeValue = [this.bsValue, this.maxDate];
    // this.filteredOptions = this.searchControl.valueChanges.pipe(
    //   debounceTime(500), // Debounce for 1000 milliseconds (1 second)
    //   startWith(''),
    //   switchMap((value) => {
    //     if (value && value.length >= 3) {
    //       return this.commonService.searchLocation(value);
    //     } else {
    //       return of([]);
    //     }
    //   }),
    //   map((data) =>
    //     data.map((item: any) => item.name + ', ' + item.countryCode)
    //   )     
    // );
  }

  private _filter(value: string): any[] {
    const filterValue = value?.toLowerCase();
    return this.national?.filter((option) =>
      (
        option?.countryName?.toLowerCase() +
        ' - ' +
        option?.countryCode?.toLowerCase()
      )?.includes(filterValue)
    );
  }

  toggleDropdown(event: Event) {
    event.preventDefault();
    this.showDropdown = !this.showDropdown;
    this.countPassengers();
  }

  addChildrens(i: any, fromOnChange: any, noOfChildren: any) {
    this.globalRoomIndex = i;
    this.accommodationForm?.controls?.rooms?.controls[
      this.globalRoomIndex
    ]?.controls?.childrensAge?.clear();
    this.addNewChildrens(noOfChildren);
  }


  addNewChildrens(noOfChildren: number) {
    if (+noOfChildren == 0) return;
    let obj = {
      age: this.fb?.control(0, Validators?.required),
    };
    let fg = this.fb?.group(obj);
    this.childrensArray?.push(fg);
    this.addNewChildrens(+noOfChildren - 1);
  }

  updateBookingData() {
    this.submitted =true;
    this.countPassengers();
    if(this.roomArray.valid) this.showDropdown = false
  }

  childrenArray(): number[] {
    return Array?.from({ length: this.selectedChildren }, (_, i) => i + 1);
  }

  countSelectedDays(checkIn?: any, checkOut?: any) {
    if (checkIn && checkOut) {
      const startDate = new Date(checkIn.getFullYear(), checkIn.getMonth(), checkIn.getDate());
      const endDate = new Date(checkOut.getFullYear(), checkOut.getMonth(), checkOut.getDate());

      const oneDay = 1000 * 60 * 60 * 24;
      const daysDifference = Math.round((endDate.getTime() - startDate.getTime()) / oneDay);
      
      this.nights = daysDifference;
      return daysDifference;
    }

    return 0;
  }

  ngOnInit(): void {
    this.nationality = sessionStorage?.getItem('nationality');
    this.setDefaultValues()
    this.filteredNationalities = this.nationalityControl?.valueChanges?.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
    let data:any = this.dataTransferService?.getFormData()
    this.roomArray?.clear()
    if (data) {
      data?.rooms?.forEach((room: any, i: any) => {
        this.globalRoomIndex = i;
        let obj = {
          room: this.fb?.control(room.room, Validators?.required),
          adults: this.fb?.control(
            room?.adults,
            Validators?.required
          ),
          children: this.fb?.control(
            room?.children,
            Validators?.required
          ),
          // infants: this.fb.control(room.controls.infants.value, Validators.required),
          childrensAge: this.fb?.array([]),
        };
  
        let fgr = this.fb?.group(obj);
        this.roomArray?.push(fgr);
        room?.childrensAge?.forEach((child: any) => {
          let childObj = {
            age: this.fb?.control(child?.age),
          };
          let fg = this.fb?.group(childObj);
          this.childrensArray?.push(fg);
        });
      });
    } else this.addAnotherRoom();
    this.countPassengers()
    const storedData = sessionStorage?.getItem('userData');
    if (storedData) {
      this.userdata = JSON?.parse(storedData);
      this.searchControl?.setValue(this.userdata['location'])
      this.nationalityControl?.setValue(this.userdata['nationality'])
      this.nationality = this.userdata['countryCode'];
      this.hotelCode = this.userdata?.hotelCode;
      this.checkIn = this.userdata?.checkIn;
      this.checkOut = this.userdata?.checkOut;
      if(this.checkIn && this.checkOut) {
        this.bsRangeValue = [this.checkIn, this.checkOut];
        this.countSelectedDays(this.checkIn,this.checkOut)
      }
    }
    if(!this.nationalityControl.value) {
      this.registerNationality = true
    this.nationalityControl?.setValue(this.nationality)
    } 

  }

  locationChange(value: any, type: any) {
    this.searchControl?.setValue(type == 'hotel' ? value.hotels : value)
    this.showLocationDropdown = false
    this.hotelCode = type == 'hotel' ? value?.hotelCode : null
    this.locations = null
    this.hotels = null
  }

  dateCount(event: any){
    const date = new Date(this.bsValue);
    date.setHours(0, 0, 0, 0);
    // console.log(date);
    const newDate = new Date(date.setDate(date.getDate() + 14));
    newDate.setHours(0, 0, 0, 0);
    // console.log(newDate);
    const checkOutDate = new Date(event[0]);
    checkOutDate.setHours(0, 0, 0, 0);
    // console.log(checkOutDate);

    if (date.getTime() <= newDate.getTime() && checkOutDate.getTime() <= newDate.getTime()) {
      this.isRefundableDate = true;
      sessionStorage.setItem('isRefundableDate',JSON.stringify(this.isRefundableDate));
    }
    else{
      this.isRefundableDate = false;
      sessionStorage.setItem('isRefundableDate',JSON.stringify(this.isRefundableDate));
    }

    console.log(this.isRefundableDate, 'date');
    
  }

  onDateRangeChange(event: any): void {
    this.dateCount(event);
    // Access the selected date range values from the event
    if(!event[0] || !event[1]) return
    const checkin = event[0];
    const checkout = event[1];
    if (checkin) {
      const checkInFormattedDate = this.datePipe?.transform(
        checkin,
        'dd-MM-yyyy'
      );
      this.checkIn = checkInFormattedDate;
      // console.log(checkInFormattedDate);

      // Use formattedDate as needed
    }

    if (checkout) {
      const checkOutformattedDate = this.datePipe?.transform(
        checkout,
        'dd-MM-yyyy'
      );
      this.checkOut = checkOutformattedDate;
      // console.log(checkOutformattedDate);

      // Use formattedDate as needed
    }

    if(this.checkIn && this.checkOut) {
      this.countSelectedDays(checkin, checkout)
    }
  }

  onSelectNationality(value: string): void {
    this.registerNationality = false
    this.nationality = value;
  }


  onSearch(event: any): void {
    const searchValue = this.searchControl?.value;
    if (searchValue?.length >= 3) {
        // Set custom hotels
        this.setCustomHotels(searchValue);
        this.showLocationDropdown = true;
        // Fetch data from API
        this.hotelSub?.unsubscribe();
        this.hotelSub = this.commonService?.getHotels(searchValue)?.subscribe(data => {
            if (this.searchControl?.value?.length >= 3) {
                // Map API data and merge with custom hotels
                const apiHotels = data?.map((item: any) => ({
                    hotels: item?.hotels,
                    hotelCode: item?.hotelCode
                }));
                if (this.hotels && this.hotels?.length > 0) {
                    // Merge custom hotels with API hotels
                    this.hotels = this.hotels?.concat(apiHotels);
                } else {
                    // If no custom hotels, show only API hotels
                    this.hotels = apiHotels;
                }
            }
        });

        this.locationSub?.unsubscribe();
        this.locationSub = this.commonService?.searchLocation(searchValue)?.subscribe(location => {
            if (this.searchControl?.value?.length >= 3) {
                this.locations = location?.map((item: any) => item?.name + ', ' + item?.countryCode);
            }
        });
    } else {
        this.locations = null;
        this.hotels = null;
    }
}

  setCustomHotels(searchValue: string): void {
    const searchLowerCase = searchValue?.toLowerCase();
    const keys = Object?.keys(this.customHotels);
    let matched = false;

    for (const key of keys) {
      if (searchLowerCase?.includes(key)) {
        this.hotels = this.customHotels[key];
        matched = true;
        break;
      }
    }

    if (!matched) {
      this.hotels = null;
    }
  }
  
  setDefaultValues(): void {
    this.defaultLocations = [
      'Dubai, UAE, AE',
      'Doha, Qatar, QA',
      'Antalya, Turkey, TR',
      'Maldives, MV',
      'Mauritius, MU',
      'Phuket, Thailand, TH',
      'Bali, Indonesia, ID',
      'New York, USA, US',
      'Las Vegas, USA, US',
      'Abu Dhabi, UAE, AE'
    ];
    this.defaultHotels = [
      { hotels: 'Atlantis The Palm, Dubai', hotelCode: 'hotel1' },
      { hotels: 'W Barcelona', hotelCode: 'hotel2' },
      { hotels: 'Delphin Be Grand, Antalya', hotelCode: 'hotel3' },
      { hotels: 'Rixos Premium Seagate, Sharm El Sheikh', hotelCode: 'hotel4' },
      { hotels: 'Banana Island Resort, Doha', hotelCode: 'hotel5' },
      { hotels: 'Siyam World Maldives', hotelCode: 'hotel6' },
      { hotels: 'Jumeirah Al Naseem, Dubai', hotelCode: 'hotel7' }
    ];

    this.customHotels = {
      "dubai": [
        { "hotels": "Atlantis The Palm", "hotelCode": 10016 },
        { "hotels": "Jumeirah Al Qasr", "hotelCode": 24591 },
        { "hotels": "Sofitel Dubai Jumeirah Beach", "hotelCode": 14858 },
        { "hotels": "Rixos Premium Dubai JBR", "hotelCode": 31223 },
        { "hotels": "Atlantis The Royale", "hotelCode": 76153 },
        { "hotels": "FIVE Palm Jumeirah Hotel", "hotelCode": 15103 },
        { "hotels": "Palace Downtown", "hotelCode": 40476 }
      ],
      "doha": [
        { "hotels": "Four Seasons Hotel Doha", "hotelCode": 76202 },
        { "hotels": "Banana Island Resort Doha by Anantara", "hotelCode": 78286 },
        { "hotels": "Marsa Malaz Kempinski, The Pearl - Doha", "hotelCode": 58419 },
        { "hotels": "The Ritz-Carlton, Doha", "hotelCode": 64990 },
        { "hotels": "The St. Regis Doha", "hotelCode": 10293 },
        { "hotels": "Rixos Gulf Hotel Doha", "hotelCode": 27720 },
        { "hotels": "Raffles Doha", "hotelCode": 29486 }
      ],
      "antalya": [
        { "hotels": "Delphin BE Grand Resort", "hotelCode": 49875 },
        { "hotels": "Aska Lara Resort & SPA", "hotelCode": 36211 },
        { "hotels": "Delphin Imperial Hotel", "hotelCode": 58400 },
        { "hotels": "Rixos Premium Belek", "hotelCode": 37454 },
        { "hotels": "The Land of Legends Kingdom Hotel", "hotelCode": 43750 },
        { "hotels": "Titanic Deluxe Lara", "hotelCode": 98956 },
        { "hotels": "Granada Luxury Belek", "hotelCode": 71288 }
      ],
      "maldives": [
        { "hotels": "LUX* South Ari Atoll", "hotelCode": 50385 },
        { "hotels": "Siyam World Maldives", "hotelCode": 25431 },
        { "hotels": "Jumeirah Olhahali Island Maldives", "hotelCode": 99040 },
        { "hotels": "Ayada Maldives", "hotelCode": 54954 },
        { "hotels": "Velssaru Maldives", "hotelCode": 10622 },
        { "hotels": "Sun Siyam Olhuveli", "hotelCode": 88476 },
        { "hotels": "Anantara Veli Maldives Resort", "hotelCode": 19070 }
      ],
      "mauritius": [
        { "hotels": "LUX Belle Mare", "hotelCode": 51478 },
        { "hotels": "Hilton Mauritius Resort & Spa", "hotelCode": 45101 },
        { "hotels": "InterContinental Resort Mauritius", "hotelCode": 32062 },
        { "hotels": "Maradiva Villas Resort & Spa", "hotelCode": 40701 },
        { "hotels": "Constance Belle Mare Plage", "hotelCode": 58271 },
        { "hotels": "Anantara Iko Mauritius Resort & Villas", "hotelCode": 77224 },
        { "hotels": "SO Sofitel Mauritius", "hotelCode": 67167 }
      ],
      "phuket": [
        { "hotels": "Four Seasons Resort Koh Samui", "hotelCode": 49282 },
        { "hotels": "Banyan Tree Phuket", "hotelCode": 24072 },
        { "hotels": "DoubleTree by Hilton Phuket", "hotelCode": 40881 },
        { "hotels": "Avista Hideaway Phuket", "hotelCode": 84624 },
        { "hotels": "Mövenpick Resort Bangtao Beach", "hotelCode": 37156 },
        { "hotels": "Katathani Phuket Beach Resort", "hotelCode": 50665 },
        { "hotels": "Keemala Hotel Phuket", "hotelCode": 51068 }
      ],
      "bali": [
        { "hotels": "The Apurva Kempinski Bali", "hotelCode": 22901 },
        { "hotels": "Hanging Gardens of Bali", "hotelCode": 37135 },
        { "hotels": "Viceroy Bali", "hotelCode": 92679 },
        { "hotels": "The Wolas Villas and Spa Seminyak", "hotelCode": 52850 },
        { "hotels": "Sofitel Bali Nusa Dua Beach Resort", "hotelCode": 56095 },
        { "hotels": "Anantara Seminyak Bali Resort", "hotelCode": 43130 },
        { "hotels": "Royal Pita Maha Hotel", "hotelCode": 32004 }
      ],
      "new york": [
        { "hotels": "W New York - Times Square", "hotelCode": 90322 },
        { "hotels": "The Times Square EDITION", "hotelCode": 43434 },
        { "hotels": "Park Central Hotel New York", "hotelCode": 63309 },
        { "hotels": "Crowne Plaza Times Square", "hotelCode": 66214 },
        { "hotels": "Paramount Hotel Times Square", "hotelCode": 59864 },
        { "hotels": "The Plaza", "hotelCode": 58509 },
        { "hotels": "Hilton New York Times Square", "hotelCode": 88044 }
      ],
      "las vegas": [
        { "hotels": "Bellagio Hotel & Casino", "hotelCode": 50532 },
        { "hotels": "The Cosmopolitan of Las Vegas", "hotelCode": 27141 },
        { "hotels": "Caesars Palace", "hotelCode": 83101 },
        { "hotels": "Encore Las Vegas", "hotelCode": 33012 },
        { "hotels": "The Venetian Las Vegas", "hotelCode": 85408 },
        { "hotels": "Vdara Hotel & Spa", "hotelCode": 85206 },
        { "hotels": "MGM Grand", "hotelCode": 94443 }
      ],
      "abu dhabi": [
        { "hotels": "Emirates Palace Mandarin Oriental", "hotelCode": 92797 },
        { "hotels": "The Ritz-Carlton Abu Dhabi, Grand Canal", "hotelCode": 54827 },
        { "hotels": "Rixos Premium Saadiyat Island", "hotelCode": 52371 },
        { "hotels": "The St. Regis Saadiyat Island Resort", "hotelCode": 40556 },
        { "hotels": "Jumeirah Saadiyat Island", "hotelCode": 29204 },
        { "hotels": "Fairmont Bab Al Bahr", "hotelCode": 56601 },
        { "hotels": "W Abu Dhabi - Yas Island", "hotelCode": 66907 }
      ]
    };
  }

  toggleLocationDropdown(){
    this.showLocationDropdown = !this.showLocationDropdown
    if(this.searchControl?.value && !this.hotels && !this.locations) this.searchControl?.setValue(null)
  }
  

    search() {
    this.submitted = true;
      let nationality = ''
     if(this.registerNationality) {
      const parts = this.nationality.split('- ');
      nationality = parts.length > 1 ? parts[1] : ''
     }
    if (this.accommodationForm?.valid) {
      let params = {
        searchHotel: '',
        role: sessionStorage?.getItem('role'),
        uplift: sessionStorage?.getItem('uplift'),
        currentPageNumber: this.hotelCode ? undefined : 1,
        location: this.searchControl.value,
        hotelCode: this.hotelCode ? this.hotelCode : undefined,
        checkInDate: this.checkIn,
        checkOutDate: this.checkOut,
        nationality: this.registerNationality ? nationality : this.nationality,
        residency: this.registerNationality ? nationality : this.nationality,
        nights: this.nights,
        rooms: this.passengers?.rooms,
        // rooms: Number(this.accommodationForm.get('rooms').value),
        // adults: Number(this.accommodationForm.get('adults').value),
        // children: Number(this.accommodationForm.get('children').value),
        // infants: '0',
      };
      if (!this.passengers) {
        params['rooms'] = [
          {
            adults: 2,
            children: 0,
            // infants: 0,
            childAges: [],
          },
        ];
      }
      let obj : any = {
        "location": this.searchControl?.value,
        "nationality": this.nationalityControl?.value,
        "countryCode": this.registerNationality ? nationality : this.nationality,
        "hotelCode": this.hotelCode ? this.hotelCode : null,
        checkIn: this.checkIn,
        checkOut: this.checkOut,
      }
      sessionStorage?.setItem('userData', JSON?.stringify(obj))
      sessionStorage?.setItem('national', JSON?.stringify(obj?.nationality))
      this.commonService?.updateSearchQuery(params);
      this.route?.navigate(['Listing']);
      let valueObject = this.accommodationForm.value;
      valueObject['date'] = [this.checkIn, this.checkOut]
      valueObject['totalPassengerCount'] = this.selectedAdults + this.selectedChildren;
      this.dataTransferService?.setFormData(valueObject);
      this.dataTransferService.shouldShowLoader = true;
    }
  }
  onclick(eve: any) {
  }

  addAnotherRoom() {
    if (this.roomArray?.length == 5) return;
    let obj = {
      room: this.fb?.control(1, Validators?.required),
      adults: this.fb?.control(2, Validators?.required),
      children: this.fb?.control(0, Validators?.required),
      // infants: this.fb.control(0, Validators.required),

      childrensAge: this.fb?.array([]),
    };
    let fgr = this.fb?.group(obj);
    this.roomArray?.push(fgr);
  }

  removeRoom(index: number) {
    if (index > 0) {
      this.roomArray?.removeAt(index);
    }
  }

  countPassengers() {
    this.selectedAdults = 0;
    this.selectedChildren = 0;
    // this.selectedInfants = 0;
    this.passengers = {
      rooms: [],
    };
    this.roomArray?.controls?.forEach((ele: any, i) => {
      this.childrenAges = [];
      this.selectedAdults += +ele?.controls?.adults?.value;
      this.selectedChildren += +ele?.controls?.children?.value;
      // this.selectedInfants += +ele.controls.infants.value;
      this.childrenAges = ele?.controls?.childrensAge?.controls?.map(
        (item: any) => {
          return +item?.controls?.age?.value;
        }
      );
      // let localScope = false;
      //   if(this.childrenAges.some((age: any) => age == 0)) {
      //     localScope = true
      //   }

      let object = {
        adults: +ele?.controls?.adults?.value,
        children: +ele?.controls?.children?.value,
        // infants: +ele.controls.infants.value,
        childAges: this.childrenAges,
      };
      this.passengers?.rooms?.push(object);
    });
    this.bookingData = ` Rooms: ${this.roomArray?.length}, Adults: ${this.selectedAdults}, Children: ${this.selectedChildren}`;
    // console.log(this.selectedAdults + this.selectedChildren, 'wahid');
    
  }

  ngOnDestroy() {
    this.hotelSub?.unsubscribe();
    this.locationSub?.unsubscribe();
  }
}
