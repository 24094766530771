<div class="w-full -mt-10 mb-10">
  <h1 class="font-poppins text-center">Supports</h1>

  <div class="main-box pt-6 md:pt-4 font-poppins md:px-40 px-6">
    <div class="text-center">
      <div class="flex flex-col md:flex-row justify-center md:justify-between">

        <!-- First Column -->
        <div class="mb-6 md:mb-0 md:mr-16">
          <p class="text-black text-left pt-3">
            <span class="text-gray-800 font-bold">
              <i class="fa fa-envelope" aria-hidden="true"></i>
              &nbsp;Accounts:&nbsp;
            </span>
            accounts@rateloc.com
          </p>

          <p class="text-black text-left pt-3">
            <span class="text-gray-800 font-bold">
              <i class="fa fa-envelope" aria-hidden="true"></i>
              &nbsp;Admin:&nbsp;
            </span>
            admin@rateloc.com
          </p>
        </div>

        <!-- Second Column -->
        <div class="mb-6 md:mb-0 md:mr-16">
          <p class="text-black text-left pt-3">
            <span class="text-gray-800 font-bold">
              <i class="fa fa-envelope" aria-hidden="true"></i>
              &nbsp;Support:&nbsp;
            </span>
            support@rateloc.com
          </p>

          <p class="text-black text-left pt-3">
            <span class="text-gray-800 font-bold">
              <i class="fa fa-envelope" aria-hidden="true"></i>
              &nbsp;Help:&nbsp;
            </span>
            help@rateloc.com
          </p>
        </div>

        <!-- Phone Number -->
        <div>
          <p class="text-black text-left pt-10">
            <span class="text-gray-800 font-bold">
              <i class="fa fa-phone" aria-hidden="true"></i>
              &nbsp;Call us:&nbsp;
            </span>
            +44 331 630 0226
          </p>
        </div>

      </div>
    </div>
  </div>
</div>