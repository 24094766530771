import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-Contactus',
  templateUrl: './Contactus.component.html',
  styleUrls: ['./Contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  image: any;
  route: any;

  constructor(private configService : ConfigService, private router: Router) { }

  ngOnInit() {
    this.image = this.configService?.getImages();
    this.route = this.router?.url
    console.log(this.route);
  }

}
