<section class="about" id="about">
  <h1 class="font-poppins">Why Choose Rateloc?</h1>
  <div class="row font-poppins">
    <div class="about-col">
      <img
        src="https://irp.cdn-website.com/md/dmtmpl/9f602b60-0fe0-4e35-919f-f6b5651ce140/dms3rep/multi/Grow+your+business.svg"
        alt="" class="icon">
      <h4>Boost your business</h4>
      <p>We prioritize innovation to provide you with top-notch interfaces and tools that make booking accommodations a
        breeze. Our goal is to ensure that your experience is seamless and efficient.</p>
    </div>
    <div class="about-col">
      <img
        src="https://irp.cdn-website.com/md/dmtmpl/9f602b60-0fe0-4e35-919f-f6b5651ce140/dms3rep/multi/Save+time-1714791a.svg"
        alt="" class="icon">
      <h4>Seamless support experience</h4>
      <p>Our multi-lingual support team is here to help you with any inquiries, and you can easily access our online
        support tools without the need to send an email.</p>
    </div>
    <div class="about-col">
      <img
        src="https://irp.cdn-website.com/md/dmtmpl/9f602b60-0fe0-4e35-919f-f6b5651ce140/dms3rep/multi/Friendly+support.svg"
        alt="" class="icon">
      <h4>Friendly & Secure</h4>
      <p>With a decade of experience, our unmatched expertise and reputation for honesty and transparency have earned
        the trust of our partners, new and established.</p>
    </div>
  </div>
</section>