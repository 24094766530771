import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, map, startWith } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CommonService } from '../../services/common.service';
import { LoadingService } from '../../services/Loading.service';

@Component({
  selector: 'app-transfers',
  templateUrl: './transfers.component.html',
  styleUrls: ['./transfers.component.scss']
})
export class TransfersComponent implements OnInit {

  searchControl = new FormControl();
  options: string[] = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];
  filteredOptions: Observable<string[]>;
  transferForm: any = FormGroup;

  initialDate: Date = new Date();
  maxDate = new Date();
  minDate: Date = new Date();
  maxYearDate: any;
  bsRangeValue: any;
  checkIn: any;
  checkOut: any;
  nights: any;
  transfer: string = '';
  dropOffTransfer: string = '';
  locations: { name: string; type: string }[] = [];
  dropOffLocations: { name: string; type: string }[] = [];
  showDropdown: boolean = false;
  showDropdown2: boolean = false;

  // Passengers dropdown arrays
  adultsArray = Array.from({ length: 10 }, (_, i) => i + 1);
  childrenArray = Array.from({ length: 6 }, (_, i) => i); // 0-5
  infantsArray = Array.from({ length: 6 }, (_, i) => i); // 0-5
  childrenAgeArray = Array.from({ length: 16 }, (_, i) => i + 2); // 2-17

  // Object to hold passenger details
  passengerDetails = {
    adults: 2,
    children: 0,
    infants: 0
  };

  // Array to hold ages of selected children
  childAgeArray: number[] = [];

  // To manage the dropdown visibility
  isDropdownOpen = false;
  selectedTransfer: any;
  selectedDropoffTransfer: any;
pickUpTime: any;
dropOffTime: any
  hotelData: any;
  dropOffData: any;
  terminalLocations: any
  hotelLocations: any
  dropOffHotelLocations: any
  dropOffTerminalLocations: any
  pickupLocationType: string = '';
dropOffLocationType: string = '';
isValidSelection: boolean = true;
terminalSelected: boolean = false
hotelSelected: boolean = false
terminalSelected2: boolean = false
hotelSelected2: boolean = false
showReturnInfo: boolean = false
bsRangeValueReturn: any

errorMessages = {
  requiredFields: '',
  terminalHotelError: ''
};

  constructor(private router: Router, private datePipe: DatePipe, private commonService: CommonService) {
    this.filteredOptions = this.searchControl?.valueChanges?.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    this.initialDate?.setDate(this.initialDate?.getDate() + 1);
    this.maxYearDate = new Date(this.initialDate);
    this.maxYearDate.setDate(this.initialDate?.getDate() + 365);
  }

  ngOnInit() {
    console.log(window.location.href, 'checkIn4t4546y76');
    let url = window.location.href;
    let baseUrl = url.split('/').slice(0, 3).join('/') + '/'; // Get the base URL
console.log(baseUrl); // outputs: http://example.com/
    
  }

  // Function to toggle the visibility of the passengers dropdown
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  // Function to update the childAgeArray based on the number of children selected
  updateChildrenAges() {
    const childrenCount = this.passengerDetails.children;
    if (childrenCount > this.childAgeArray.length) {
      // Add missing age fields
      for (let i = this.childAgeArray.length; i < childrenCount; i++) {
        this.childAgeArray.push(2); // Default age set to 2
      }
    } else if (childrenCount < this.childAgeArray.length) {
      // Remove extra age fields
      this.childAgeArray.splice(childrenCount);
    }
  }

  // Get a summary of selected passengers to display in the input field
  getPassengerSummary() {
    return `${this.passengerDetails.adults} Adults, ${this.passengerDetails.children} Children, ${this.passengerDetails.infants} Infants`;
  }

  // onSearchTransfer(event: any){
  //   if(this.transfer.length>2){
  //     this.commonService.getTransfers(this.transfer).subscribe((data: any) => {
  // console.log("print data"+ data)
  //     })
  //   }
  // }

  // onSearchTransfer(event: any) {
  //   if (this.transfer.length > 2) {
  //     this.commonService.getTransfers(this.transfer).subscribe((response: any) => {
  //       if (response.status && response.data) {
  //         // Extract hotel names
  //         const hotelNames = response.data.hotels.map((hotel: any) => hotel.name);
  //         // Extract terminal names
  //         const terminalNames = response.data.terminals.map((terminal: any) => terminal.name);
          
  //         // Merge hotel and terminal names into one array
  //         this.locations = [...hotelNames, ...terminalNames];
  //       }
  //     });
  //   }
  // }


  onSearchTransfer(event: any) {
    if (this.transfer.length > 2) {
      this.commonService.getTransfers(this.transfer).subscribe((response: any) => {
        if (response.status && response.data) {
          this.hotelData = response.data.hotels.concat(response.data.terminals);
          this.hotelLocations = response.data.hotels.map((hotel: any) => ({ name: hotel.name, type: 'hotel' }));
          this.terminalLocations = response.data.terminals.map((terminal: any) => ({ name: terminal.name, type: 'terminal' }));
          this.locations = this.hotelLocations.concat(this.terminalLocations); // Keep a combined list if needed
        }
      });
    }
  }

  onSearchDropOff(event: any) {
    if (this.dropOffTransfer.length > 2) {
      this.commonService.getTransfers(this.dropOffTransfer).subscribe((response: any) => {
        if (response.status && response.data) {
          this.dropOffData = response.data.hotels.concat(response.data.terminals);
          this.dropOffHotelLocations = response.data.hotels.map((hotel: any) => ({ name: hotel.name, type: 'hotel' }));
          this.dropOffTerminalLocations = response.data.terminals.map((terminal: any) => ({ name: terminal.name, type: 'terminal' }));
          this.dropOffLocations = this.dropOffHotelLocations.concat(this.dropOffTerminalLocations); // Combined list if needed
        }
      });
    }
  }

  selectLocation(location: { name: string; type: string }, i: number) {
    // Reset error message
    this.errorMessages.terminalHotelError = '';
  
    // Check if the current selection is a terminal
    if (this.transfer) {
      const existingLocation = this.locations.find((loc) => loc.name === this.transfer);
      if (existingLocation?.type === location.type) {
        // Prevent selecting the same type
        this.errorMessages.terminalHotelError = 'You cannot select the same type for pick-up and drop-off.';
        return;
      }
    }
  
    // If the selected location is a terminal, ensure drop-off is a hotel
    if (location.type === 'terminal' && this.dropOffTransfer) {
      const dropOffLocation = this.dropOffLocations.find((loc) => loc.name === this.dropOffTransfer);
      if (dropOffLocation?.type === 'terminal') {
        this.errorMessages.terminalHotelError = 'You cannot select another terminal for pick-up. Please select a hotel for drop-off.';
        return;
      }
    }
  
    // If the selected location is a hotel, ensure drop-off is a terminal
    if (location.type === 'hotel' && this.dropOffTransfer) {
      const dropOffLocation = this.dropOffLocations.find((loc) => loc.name === this.dropOffTransfer);
      if (dropOffLocation?.type === 'hotel') {
        this.errorMessages.terminalHotelError = 'You cannot select another hotel for pick-up. Please select a terminal for drop-off.';
        return;
      }
    }
  
    // Set the transfer location
    this.transfer = location.name;
    const selectedIndex = this.locations.findIndex((loc) => loc.name === location.name);
    this.selectedTransfer = this.hotelData[selectedIndex];
    this.showDropdown = false; // Hide dropdown after selection
  }
  
  selectLocation2(dropOffLocation: { name: string; type: string }, i: number) {
    // Reset error message
    this.errorMessages.terminalHotelError = '';
  
    // Check if the current selection is a hotel
    if (this.dropOffTransfer) {
      const existingLocation = this.dropOffLocations.find((loc) => loc.name === this.dropOffTransfer);
      if (existingLocation?.type === dropOffLocation.type) {
        // Prevent selecting the same type
        this.errorMessages.terminalHotelError = 'You cannot select the same type for pick-up and drop-off.';
        return;
      }
    }
  
    // If the selected location is a terminal, ensure pick-up is a hotel
    if (dropOffLocation.type === 'terminal' && this.transfer) {
      const pickUpLocation = this.locations.find((loc) => loc.name === this.transfer);
      if (pickUpLocation?.type === 'terminal') {
        this.errorMessages.terminalHotelError = 'You cannot select another terminal for drop-off. Please select a hotel for pick-up.';
        return;
      }
    }
  
    // If the selected location is a hotel, ensure pick-up is a terminal
    if (dropOffLocation.type === 'hotel' && this.transfer) {
      const pickUpLocation = this.locations.find((loc) => loc.name === this.transfer);
      if (pickUpLocation?.type === 'hotel') {
        this.errorMessages.terminalHotelError = 'You cannot select another hotel for drop-off. Please select a terminal for pick-up.';
        return;
      }
    }
  
    // Set the drop-off location
    this.dropOffTransfer = dropOffLocation.name;
    const selectedIndex = this.dropOffLocations.findIndex((loc) => loc.name === dropOffLocation.name);
    this.selectedDropoffTransfer = this.dropOffData[selectedIndex];
    this.showDropdown2 = false; // Hide dropdown after selection
  }

  hideDropdown() {
    // setTimeout(() => this.showDropdown = false, 100); // Delay to allow click event to register
  }

  hideDropdown2() {
    // setTimeout(() => this.showDropdown2 = false, 100); // Delay to allow click event to register
  }
  
  // Existing Onsearch method
  OnSearch(){
    if (!this.selectedTransfer || !this.selectedDropoffTransfer || !this.checkIn || !this.pickUpTime || !this.passengerDetails || !sessionStorage.getItem("role") || !sessionStorage.getItem("uplift")) {
      this.errorMessages.requiredFields = "Please fill in all the required fields";
      return;
    }
  
    console.log(this.bsRangeValue)
    console.log(this.bsRangeValue)
    const formattedDate = this.datePipe.transform(this.checkIn, 'dd MMM');
    if (formattedDate !== null) {
      sessionStorage.setItem('formattedDate', formattedDate);
    }
    const formattedDateReturn = this.datePipe.transform(this.checkOut, 'dd MMM');
    if (formattedDateReturn !== null) {
      sessionStorage.setItem('formattedDateReturn', formattedDateReturn);
    }
    console.log(formattedDateReturn);
    
    // debugger
    
    let Obj ={
      from: this.selectedTransfer, 
      to: this.selectedDropoffTransfer,
      role: sessionStorage.getItem("role"),
      onewayDateTime: this.checkIn+ "T" + this.pickUpTime + ":00",
      roundTripDateTime: this.showReturnInfo ? this.checkOut+ "T" + this.dropOffTime + ":00" : undefined,
      pax: this.passengerDetails,
      uplift: sessionStorage.getItem("uplift"),
      formattedDate: sessionStorage.getItem('formattedDate'),
      formattedDateReturn: this.showReturnInfo ? sessionStorage.getItem('formattedDateReturn') : undefined
    }
    this.errorMessages.requiredFields = '';
    sessionStorage?.setItem('transferBody', JSON?.stringify(Obj));
    console.log(Obj)
    this.router.navigate(['transfers-listing']);
  }


  onPickUpTransfer(event: any) {
    // this.arrivalDate = event;
    let checkout = event
    if (checkout) {
      const checkOutformattedDate = this.datePipe.transform(
        checkout,
        'yyyy-MM-dd'
      );
      this.checkIn = checkOutformattedDate;
      // console.log(checkOutformattedDate);
      console.log('Check-out:', checkOutformattedDate);
 
      // Use formattedDate as needed
    } 
  
  }


  onDropOffTransfer(event: any) {
    // this.arrivalDate = event;
    let checkout = event
    if (checkout) {
      const checkOutformattedDate = this.datePipe.transform(
        checkout,
        'yyyy-MM-dd'
      );
      this.checkOut = checkOutformattedDate;
      // console.log(checkOutformattedDate);
      console.log('Check-out:', checkOutformattedDate);
 
      // Use formattedDate as needed
    }
 
  }

  // Existing method for handling date range changes
  onDateRangeChange(event: any): void {
    // Access the selected date range values from the event
    if(!event[0] || !event[1]) return;
    const checkin = event[0];
    const checkout = event[1];
    if (checkin) {
      const checkInFormattedDate = this.datePipe?.transform(
        checkin,
        'dd-MM-yyyy'
      );
      this.checkIn = checkInFormattedDate;
    }

    if (checkout) {
      const checkOutformattedDate = this.datePipe?.transform(
        checkout,
        'dd-MM-yyyy'
      );
      this.checkOut = checkOutformattedDate;
    }

    if(this.checkIn && this.checkOut) {
      this.countSelectedDays(checkin, checkout);
    }
  }

  

  // Existing method to count the number of nights between selected dates
  countSelectedDays(checkIn?: any, checkOut?: any) {
    if (checkIn && checkOut) {
      const startDate = checkIn;
      const endDate = checkOut;

      const timeDifference = endDate?.getTime() - startDate?.getTime();
      const daysDifference = timeDifference / (1000 * 3600 * 24);

      this.nights = daysDifference + 1 - 1;
      return daysDifference + 1 - 1 + ' ' + 'Nights';
    }

    return 0;
  }

  // Private method to filter options for search
  private _filter(value: string): string[] {
    const filterValue = value?.toLowerCase();
    return this.options?.filter(option => option?.toLowerCase()?.includes(filterValue));
  }

  toggleReturnInfo() {
  this.showReturnInfo = !this.showReturnInfo;
}
}


