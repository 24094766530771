import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { DataTransferServiceService } from 'src/app/core/services/data-transfer-service.service';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-failure',
  templateUrl: './failure.component.html',
  styleUrls: ['./failure.component.scss'],
})
export class FailureComponent implements OnDestroy{
  closeModalSub!: Subscription;
  bookingSub!: Subscription;
  errorMessage: any;
  storedParams: any;
  closeTimeout: any;
  bookingRefId: any;
  ngOnInit(): void {
    this.errorMessage =  sessionStorage.getItem("errorMessage")
    // this.closeModalSub?.unsubscribe()
    // this.closeModalSub = this.dataTransferService?.closeModal$?.subscribe((data) => {
    //   if (String(data) == 'failure') this.closeModal();
    // });

    const bookingParamsString = sessionStorage?.getItem('bookingParams');

    if (bookingParamsString) {
      this.storedParams = JSON?.parse(bookingParamsString);
    }
    this.openModal();
    if (this.storedParams?.paymentStatus != 'success' && this.storedParams != undefined) {
      if (sessionStorage?.getItem('paymentMethod') == 'cardPayment') {
        // setTimeout(() => {
        this.booking();
        // }, 1000);
      } else {
        // setTimeout(() => {
          this.closeModal()
        // },4000)
      }
    } else {
      // setTimeout(() => {
        this.closeModal()
      // },1000)
    }

    this.bookingRefId = sessionStorage.getItem('bookingRefId');

    if (this.bookingRefId) {
      this.bookingWithRefId();
    }
  }

  closingModalRef!: BsModalRef;
  @ViewChild('failureModal', { static: true }) failureModal: any;

  constructor(
    private modalService: BsModalService,
    private router: Router,
    private dataTransferService: DataTransferServiceService,
    private commonService: CommonService,
    private toastService: ToastService
  ) {}
  

  openModal() {
    this.closingModalRef = this.modalService?.show(this.failureModal, {
      class: 'modal-md modal-dialog-centered',
      backdrop: 'static'
    });
  }

  closeModal() {
    this.closeTimeout = setTimeout(() => {
      this.closingModalRef?.hide();
      this.router?.navigate(['/MyBookings']);
    }, 2000);
  }

  bookingWithRefId() {
    let params = {
      paymentStatus: 'Failed',
    };

    this.commonService.updateBooking(params).subscribe((data) => {
      if (data.status = 'success') {
        this.closingModalRef?.hide();
        this.router.navigate(['MyBookings']);
        this.toastService.showSuccess('Payment Failed');
        sessionStorage.removeItem('bookingRefId');
      }
    });
  }

  booking() {
    const bookingParamsString = sessionStorage?.getItem('bookingParams');
    let storedParams;

    if (bookingParamsString) {
      storedParams = JSON?.parse(bookingParamsString);
    }
    if (storedParams) {
      // Update the payment status if the params exist
      storedParams.paymentStatus = 'failed';
      sessionStorage?.setItem('bookingParams', JSON?.stringify(storedParams));
    }

    let params = sessionStorage?.getItem('bookingParams');

    this.bookingSub?.unsubscribe()
    this.bookingSub = this.commonService?.booking(params)?.subscribe((data) => {
      this.closeModal()
      this.router?.navigate(['MyBookings']);
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this.closeTimeout)
    this.closingModalRef?.hide();
    this.closeModalSub?.unsubscribe()
    this.bookingSub?.unsubscribe()
    sessionStorage.removeItem("errorMessage")
  }
}
