import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailService } from 'src/app/core/services/email.service';

@Component({
  selector: 'app-contactDetails',
  templateUrl: './contactDetails.component.html',
  styleUrls: ['./contactDetails.component.scss']
})
export class ContactDetailsComponent implements OnInit {

  contactForm: any = FormGroup;

  constructor(private emailService: EmailService, private fb: FormBuilder,) { }

  ngOnInit() {
    this.contactForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)]],
      number: ['', [Validators.required]],
      message: ['', [Validators.required]],
      
    })
  }

  onContact(){
    const { name, email, number, message } = this.contactForm.value;

    this.emailService.sendEmail(name, email, number, message).then(
      response => {
        console.log('Email sent successfully', response);
        // Handle success (e.g., show a confirmation message)
      },
      error => {
        console.log('Failed to send email', error);
        // Handle error (e.g., show an error message)
      }
    );

    this.contactForm.reset();
  }

}
