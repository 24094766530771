<div class="main-container">
    <app-header class="sticky" [logo]="image?.ratelocLogo" [section1]="'Dashboard'"
        [section2]="'My Bookings'"></app-header>

    <div class="main-box font-poppins">
        <i class="fa fa-angle-left backIcon" (click)="backIcon()"></i>
        <div class="favourites-container">
            <div class="fav-header">
                <div class="d-flex justify-content-between">
                    <h2>My Fav</h2>
                    <div class="search-box">
                        <!-- <label for="">GUEST & ROOM</label> -->
                        <i class="fa fa-magnifying-glass me-3"></i>
                        <input (input)="searchHotels()" [(ngModel)]="searchTerm" type="text" placeholder="Search hotel"/>
                    </div>
                </div>
                <!-- <label for="">No hotels saved yet</label> -->
                <h4>{{favouritesList?.length > 0 ? favouritesList?.length + ' hotels saved' : "No hotels saved yet"}}</h4>
            </div>
            <div *ngIf="favouritesList?.length <= 0" class="no-hotels-container">
                <div class="no-hotels">
                    <div class=""><i class="fa fa-solid fa-plus"></i></div>
                </div>
                <label for="">You've got no hotels saved yet!</label>
                <div class="d-flex justify-content-center explore-button">
                    <button routerLink="/Dashboard" class="main-button">Explore Hotels</button>
                </div>
            </div>
            <ng-container *ngIf="favouritesList?.length > 0">
                <div *ngFor="let favourite of favouritesList" class="my-5">
                    <div class="card-container">
                        <div class="img-container">
                            <img [src]="favourite?.image" alt="">
                        </div>
                        <div class="info-container position-relative pb-0">
                            <h3 class="mb-3 title " [matTooltip]="favourite?.title" matTooltipPosition="above">{{favourite?.title}}</h3>
                            <h6 class="mb-3 mt-2 address" [matTooltip]="favourite?.address" matTooltipPosition="above">{{favourite?.address}}</h6>
                            <h6 class="mb-2 ellipsis name" [matTooltip]="favourite?.name" matTooltipPosition="above">{{favourite?.name}}</h6>
                            <ng-container  *ngFor="let star of starsArray index as i">
                                <i class="fa fa-star text-yellow-400 p-0.5 fa-1.5x" *ngIf="favourite?.rating > i"></i>
                                <i class="fa fa-star-o text-yellow-400 p-0.5 fa-1.5x" aria-hidden="true" *ngIf="favourite?.rating <= i"></i>
                            </ng-container>
                            <div class="button-container">
                                <button (click)="viewData(favourite?.hotelId)" class="view-button">View</button>
                                <button (click)="removeFavourite(favourite?.id)" class="remove-button">Remove</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

</div>