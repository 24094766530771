<ng-template #failureModal>


  <div class="content">
    <div class="check-icon">
      <img src="/assets/images/cancel.png" alt="" />
    </div>
    <div class="center-grid">
      <h4 class="type">Failure!</h4>
      <p class="message-type">
       {{bookingRefId ? "Payment Failed" : 'Your hotel booking has been failed.'}}
      </p>
      <p class="message-type fw-bold">{{errorMessage && errorMessage != "null" ? errorMessage : "There's an error"}}</p>
    </div>
  </div>

</ng-template>