<!--  FOOTER START -->

<div class="footer font-poppins">
  <div class="inner-footer">

    <!--  for company name and description -->
    <div class="footer-items">
      <a class="p-0" [routerLink]="['/']">
        <img class="h-16 w-100 lg:-ml-24 lg:-mt-4 lg:-mb-3 d-block " [src]="'assets/images/logoWhite.svg'" alt="RateLocLogo" />
      </a>
      <p class="lg:-ml-2 lg:-mb-1">&copy; 2023 Rateloc | All Rights Reserved</p>
      <!-- <p class="lg:-ml-2 lg:-mb-1">60 Charles Street, Leicester, LE11FB</p> -->
      <!-- <p class="lg:-ml-2 lg:-mb-1">Company number: 1414579</p> -->
      <!-- <h1>Rate<span class="orange-color">Loc</span></h1> -->
    </div>

    <!--  for quick links  -->
    <div class="footer-items">
      <h4>Legal Terms</h4>
      <div class="border1"></div> <!--for the underline -->
      <ul>
        <a href="javascript:void(0)" (click)="openPdfModal('assets/docs/1. Terms of Use.pdf')">
          <li>Terms & Conditions</li>
        </a>
        <a href="javascript:void(0)" (click)="openPdfModal('assets/docs/5. Data Processing Agreement.pdf')">
          <li>Data Processing</li>
        </a>
        <a href="javascript:void(0)" (click)="openPdfModal('assets/docs/6. Refund and Cancellation Policy.pdf')">
          <li>Refund and Cancellations</li>
        </a>
      </ul>
    </div>

    <!--  for some other links -->
    <div class="footer-items">
      <h4>Site Policies</h4>
      <div class="border1"></div> <!--for the underline -->
      <ul>
        <a href="javascript:void(0)" (click)="openPdfModal('assets/docs/4. Cookies Policy.pdf')">
          <li>Cookie Policy</li>
        </a>
        <a href="javascript:void(0)" (click)="openPdfModal('assets/docs/3. Privacy Policy.pdf')">
          <li>Privacy Policy</li>
        </a>
        <a href="javascript:void(0)" (click)="openPdfModal(('assets/docs/2. Booking Terms and Conditions.pdf'))">
          <li>Booking Terms</li>
        </a>
      </ul>
    </div>

    <!--  for contact us info -->
    <div class="footer-items">
      <h4>Contact us</h4>
      <div class="border1"></div>
      <ul>
        <li><i class="fa fa-phone" aria-hidden="true"></i>+44 331 630 0226</li>
        <li><i class="fa fa-envelope" aria-hidden="true"></i>help@rateloc.com</li>
      </ul>

      <!--   for social links -->
      <div class="social-media">
        <a href="#"><i class="fa fa-instagram"></i></a>
        <a href="#"><i class="fa fa-facebook"></i></a>
        <a href="#"><i class="fa fa-whatsapp"></i></a>
      </div>
    </div>
  </div>
</div>

<ng-template #pdfModal>
  <pdf-viewer [src]="pdfSrc" style="width: 810px; height: 650px; left: -10rem"></pdf-viewer>
</ng-template>

<!--   Footer Bottom end -->