import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { HomeAuthGuard } from './core/guards/homeAuthGuard.guard';
import { BookingSummaryComponent } from './core/components/bookingSummary/bookingSummary.component';
import { MyAccountComponent } from './shared/reusableComponents/my-account/my-account.component';
import { BookingComponent } from './shared/reusableComponents/booking/booking.component';
import { SuccessfulComponent } from './shared/reusableComponents/successful/successful.component';
import { FailureComponent } from './shared/reusableComponents/failure/failure.component';
import { forgotPasswordGuard } from './core/guards/forgot-password.guard';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { FavouritesComponent } from './shared/reusableComponents/favourites/favourites.component';
import { TransfersListingComponent } from './shared/reusableComponents/transfers-listing/transfers-listing.component';
import { TransfersSummaryComponent } from './shared/reusableComponents/transfers-summary/transfers-summary.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '',
    canActivate: [HomeAuthGuard],
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'Dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'Listing',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/listing/listing.module').then((m) => m.ListingModule),
  },
  {
    path: 'Detail',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/detail/detail.module').then((m) => m.DetailModule),
  },
  {
    path: 'MyBookings',
    canActivate: [AuthGuard],
    component: BookingComponent,
  },
  {
    path: 'failure',
    canActivate: [AuthGuard],
    component: FailureComponent,
  },
  {
    path: 'Booking',
    canActivate: [AuthGuard],
    component: BookingSummaryComponent,
  },
  {
    path: 'favourites',
    canActivate: [AuthGuard],
    component: FavouritesComponent,
  },
  {
    path: 'transfers-listing',
    canActivate: [AuthGuard],
    component: TransfersListingComponent,
  },
  {
    path: 'transfers-Summary',
    canActivate: [AuthGuard],
    component: TransfersSummaryComponent,
  },
  {
    // path: ':item/success',
    path: 'success',
    canActivate: [AuthGuard],
    component: SuccessfulComponent,
  },
  {
    path: 'myAccount',
    canActivate: [AuthGuard],
    component: MyAccountComponent,
  },
  {

    path: 'forgot-password',
    canActivate: [forgotPasswordGuard],
    component: ForgotPasswordComponent,

  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
