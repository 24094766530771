import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PreviousUrlService {

  getPreviousUrl() {
    return sessionStorage?.getItem("previousUrl");
  }

  setPreviousUrl(url:string) {
    sessionStorage?.setItem("previousUrl", url);
  }

}
