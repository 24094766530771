import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

constructor() { }

private loadingSubject = new BehaviorSubject<boolean>(true);
  isLoading$ = this.loadingSubject?.asObservable();

  setLoading(isLoading: boolean): void {
    this.loadingSubject?.next(isLoading);
  }

}
