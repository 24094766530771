<div *ngIf="!comingSoon"  class="">
    <h2 class="heading px-4 mt-3">Enter details</h2>
    <div class="travel-booking-form-container">
        <mat-card class="travel-booking-card p-0">
          <mat-card-content class="p-0">
            <form [formGroup]="accommodationForm" (ngSubmit)="search()">
              <!-- Location Field -->
      
              <!-- Check-in & Check-out Fields -->
              <div class="form-field-container row px-4">
                <!-- Date Field -->
                <h2 class="form-field-heading orange-color">Date :</h2>
      
                <mat-form-field appearance="outline" class="w-100">
                  <mat-icon matPrefix fontIcon="date_range" class="orange-color"></mat-icon>
                  <input matInput #drp="bsDaterangepicker" bsDaterangepicker [(ngModel)]="bsRangeValue" formControlName="date"
                    placeholder="Check in - Check out"
                    [bsConfig]="{ isAnimated: true, rangeInputFormat : 'DD/MM/YYYY', dateInputFormat: 'DD/MM/YYYY', minDate: minDate, maxDate: maxYearDate, showWeekNumbers: false}"
                    (bsValueChange)="onDateRangeChange($event)" (click)="onclick($event)">
                  <!-- *ngIf="drp.isOpen" -->
                  <div class="bs-datepicker-footer">
                    <p class="text-sm font-poppins"> {{countSelectedDays()}}</p>
                  </div>
      
                </mat-form-field>
      
      
              </div>
      
      
              <!-- Passengers Field -->
              <div class="form-field-container position-relative row px-4">
                <h2 class="form-field-heading orange-color">Passengers :</h2>
      
                <!-- Rooms Dropdown -->
                <div class="booking-input">
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Select options" [(ngModel)]="bookingData" formControlName="passengers"
                      (click)="toggleDropdown($event)" readonly>
                    <mat-icon matPrefix (click)="toggleDropdown($event)" class="orange-color">person</mat-icon>
                  </mat-form-field>        
                </div>
                <div *ngIf="showDropdown" class="dropdown-content">
                    <div class="dropdown-content-innner">
                      <ng-container formArrayName="rooms">
                        <div class="" *ngFor="let item of accommodationForm?.controls?.rooms?.controls; index as i">
                          <div class="mb-3" [formGroupName]="i">
                            <div class="flex flex-row w-100  p-1">
                              <!-- <label class="text-sm col-md-5 text-left">Rooms:</label>
                              <select formControlName="room" class="text-center border">
                                <option *ngFor="let value of [1, 2, 3, 4, 5]" [value]="value">{{ value }}</option>
                              </select> -->
                              <p class="fw-bold">Room {{i+1}}</p>
                            </div>
                            <!-- </mat-form-field> -->
              
                            <div class="flex flex-row w-100 p-1">
                              <label class="text-sm col-md-5 text-left">Adults:</label>
                              <select formControlName="adults" class="text-center border">
                                <option *ngFor="let value of [1, 2, 3, 4, 5, 6]" [value]="value">{{ value }}</option>
                              </select>
                            </div>
              
                            <div class="flex flex-row w-100 p-1">
                              <label class="text-sm col-md-5 text-left">Children:</label>
                              <select formControlName="children" (change)="addChildrens(i,true,item?.get('children')?.value)" class="text-center border">
                                <option *ngFor="let value of [0, 1, 2, 3, 4]" [value]="value">{{ value }}</option>
                              </select>                      
                            </div>
                          
                            <div *ngIf="item?.get('childrensAge')?.length > 0" >
                              <ng-container formArrayName="childrensAge">
                                <div *ngFor="let child of item?.get('childrensAge')?.controls ;  index as j" class="flex flex-row w-100 p-2">
                                  <label class="text-sm col-md-5 text-left">Child {{j+1}} Age:</label>
                                  <div [formGroupName]="j" class="flex flex-col">
                                    <select formControlName="age" class="text-center border" >
                                      <option *ngFor="let value of [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]" [value]="value">{{ value }}</option>
                                    </select>
                                    <ng-container class="flex flex-col w-100 p-1">
                                      <mat-error *ngIf="(child?.get('age')?.invalid) && (child?.get('age')?.dirty || child?.get('age')?.touched || submitted)"
                                      class="text-xs d-flex align-items-center mt-1">
                                      <mat-icon matPrefix fontIcon="info" class="to-red-600 mr-1"></mat-icon>
                                      child age is required</mat-error>
                                    </ng-container>
                                  </div>
                                 
                          
                                </div>
                           
                            
                                
                               </ng-container> 
                            </div>
                           
                            <!-- <div class="flex flex-row w-100 p-1">
                              <label class="text-sm col-md-5 text-left">Infants:</label>
                              <select formControlName="infants" class="text-center border">
                                <option *ngFor="let value of [0,1, 2, 3, 4, 5, 6, 7, 8, 9]" [value]="value">{{ value }}</option>
                              </select>
                            </div> -->
                            
                          </div>
                          <button (click)="removeRoom(i)" *ngIf="i>0" class="btn btn-sm btn-danger mb-1" type="button">remove room</button>
                        </div>
                        <div class="add-room">
                          <button (click)="addAnotherRoom()" class="btn btn-outline" type="button">Add Another room</button>
                        </div>
                      </ng-container>
                      <button mat-button color="warn" class="flex items-center" type="button" (click)="updateBookingData()">Done</button>
                    </div>
                    
          
                    <!-- <mat-form-field class="w-100" appearance="fill"> -->
                    
                     
                    
          
                  </div>
              </div>
              
      
              <!-- Board Base Dropdown Field -->
              <div class="form-field-container px-4">
                <h2 class="form-field-heading orange-color">Nationality :</h2>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput  formControlName="nationalityControl" [formControl]="nationalityControl" [matAutocomplete]="nationalAutoComplete" placeholder="Choose your nationality" required>
                  <mat-autocomplete #nationalAutoComplete="matAutocomplete">
                    <mat-option *ngFor="let x of filteredNationalities | async" [value]="x?.countryName + ' - ' + x?.countryCode" (click)="onSelectNationality(x?.countryCode)">
                      {{x?.countryName}} - {{x?.countryCode}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="accommodationForm?.get('nationalityControl')?.hasError('required')"
                    class="text-xs d-flex align-items-center mt-1">
                    <mat-icon matPrefix fontIcon="info" class="to-red-600 mr-1"></mat-icon>
                    Nationality is required</mat-error>
                </mat-form-field>
              </div>
              
              <!-- Search Button -->
              <div class="d-flex justify-content-end px-4">
                <button class="main-button p-2 mt-2 mb-3" type="submit">Continue</button>
              </div>
            </form>
          </mat-card-content>
      
        </mat-card>
      
      </div>
</div>
<div *ngIf="comingSoon"   class="wrapper">
  <div class="container comingSoon">
    <h1>Coming soon<span class="dot orange-color">.</span></h1>
  </div>
</div>