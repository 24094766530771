<!-- <div class="travel-booking-form-container">
  <mat-card class="travel-booking-card">
    <mat-card-content>
        <form>
          <div class="form-field-container px-4">
            <h2 class="form-field-heading">Location :</h2>
            <mat-form-field appearance="outline" class="w-100">
              <input type="text" matInput placeholder="Search" [formControl]="searchControl" [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-field-container row px-4">
            <div class="col-md-6">
              <h2 class="form-field-heading">Check-in :</h2>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput [matDatepicker]="checkInPicker">
                <mat-datepicker-toggle matSuffix [for]="checkInPicker"></mat-datepicker-toggle>
                <mat-datepicker #checkInPicker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <h2 class="form-field-heading">Check-out :</h2>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput [matDatepicker]="checkOutPicker">
                <mat-datepicker-toggle matSuffix [for]="checkOutPicker"></mat-datepicker-toggle>
                <mat-datepicker #checkOutPicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          
          <div class="form-field-container px-4">
            <h2 class="form-field-heading">Passengers</h2>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput type="number" placeholder="Enter number of passengers">
            </mat-form-field>
          </div>
          <div class="form-field-container px-4">
            <h2 class="form-field-heading">Board Base</h2>
            <mat-form-field appearance="outline" class="w-100">
              <mat-select placeholder="Any">
                <mat-option value="option1">Option 1</mat-option>
                <mat-option value="option2">Option 2</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="px-4">
            <button class="main-button p-2 mt-2 w-100 mb-3">Search</button>
          </div>
        </form>
      </mat-card-content>
  </mat-card>
</div> -->

<div class="wrapper">
  <div class="container comingSoon">
    <h1>Coming soon<span class="dot orange-color">.</span></h1>
  </div>
</div>