import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {
  private currentVersion: string | null = null; // Initialize as null to fetch the initial version
  private versionUrl = '../../../assets/version.json'; // Path to version.json
    private refreshRoutes = ['', '/Dashboard', '/Listing', '/Detail', '/MyBookings', '/transfers-listing', '/myAccount', '/forgot-password', '/favourites'];
  refreshInterval: any;

  constructor(private http: HttpClient, private router : Router, private authService : AuthenticationService) {}

  public initVersionCheck() {
    const metaTag = document.querySelector('meta[name="app-version"]');
    const currentVersion = metaTag ? metaTag.getAttribute('content') : null;
          this.refreshInterval = setInterval(() => {
            if(this.shouldRefresh() || this.router.url == "/" || !this.router.url) {
              this.http.get<{ version: string }>(this.versionUrl).subscribe(response => {
                const latestVersion = response.version;
                if (currentVersion && latestVersion != currentVersion) {
                  console.log('New version detected.');
                  // alert("reloading")
                  clearInterval(this.refreshInterval)
                  // window.location.reload();
                  // this.authService?.logout();
                  // this.router?.navigate(['/'])
                  }
              });
            }
          }, 3000);
    // }
    
  }

  private shouldRefresh(): boolean {
    const currentRoute = this.router.url;
    return this.refreshRoutes.includes(currentRoute);
  }

}


 // Path to version.json