<!-- splash-screen.component.html -->
<div class="splash-screen" *ngIf="(loadingService.isLoading$ | async)">
  <div class="loader-wrap">
    <div class="preloader">
      <div class="preloader-close">x</div>
      <div id="handle-preloader" class="handle-preloader">
        <div class="animation-preloader">
          <div class="txt-loading">
            <span data-text-preloader="R" class="letters-loading">R</span>
            <span data-text-preloader="A" class="letters-loading">A</span>
            <span data-text-preloader="T" class="letters-loading">T</span>
            <span data-text-preloader="E" class="letters-loading">E</span>
            <span data-text-preloader="L" class="letters-loading">L</span>
            <span data-text-preloader="O" class="letters-loading">O</span>
            <span data-text-preloader="C" class="letters-loading">C</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="progress"></div>
  </div>
  <div class="text font-poppins">Uncovering the finest hotels at unbeatable prices for your perfect stay...</div>
</div>