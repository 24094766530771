import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription, map, of, startWith, switchMap } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-attractions',
  templateUrl: './attractions.component.html',
  styleUrls: ['./attractions.component.scss'],
})
export class AttractionsComponent implements OnInit, OnDestroy {
  yourDateValue = new Date();
  searchControl = new FormControl();
  accommodationForm: any = FormGroup;

  filteredOptions: Observable<string[]>;
  searchLocationSub!: Subscription;

  constructor(private commonService: CommonService, private route: Router) {
    this.filteredOptions = this.searchControl?.valueChanges?.pipe(
      debounceTime(500), // Debounce for 500 milliseconds
      startWith(''),
      switchMap((value) => {
        if (value && value?.length >= 3) {
          return this.commonService?.searchLocation(value);
        } else {
          return of([]); // Return an empty array if the value is less than 3 characters
        }
      }),
      map((data) => {

        if (data && data?.features) {
          return data?.features?.map((item: any) => {

            if (item && item?.properties) {
              return (
                item?.properties?.city +
                ', ' +
                item?.properties?.country +
                ', ' +
                item?.properties?.country_code
              ); // Access name and country_code from properties
            } else {
              return ''; // Return empty string if properties are not available
            }
          });
        } else {
          return []; // Return an empty array if the data or features are not available
        }
      })
    );
  }

  ngOnInit(): void {}

  onSearch(): void {
    const query = this.searchControl?.value;
    if (query) {
      this.searchLocationSub?.unsubscribe()
      this.searchLocationSub = this.commonService?.searchLocation(query)?.subscribe((data) => {
      });
    }
  }

  search() {
    this.route?.navigate(['Listing']);
  }

  ngOnDestroy() {
  this.searchLocationSub?.unsubscribe()
  }
}
