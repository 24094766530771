import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from 'src/app/core/services/Loading.service';

@Component({
  selector: 'app-splashScreen',
  templateUrl: './splashScreen.component.html',
  styleUrls: ['./splashScreen.component.scss']
})
export class SplashScreenComponent implements OnInit, OnDestroy {
  isLoading: any;
  isLoadingSub!: Subscription;

  constructor(public loadingService : LoadingService) { }
  

  ngOnInit() {
    this.isLoadingSub?.unsubscribe()
    this.isLoadingSub = this.loadingService?.isLoading$?.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });// Adjust the delay as needed
    this.startProgress();
  }

  ngOnDestroy() {
    this.isLoadingSub?.unsubscribe()
  }

  startProgress(): void {
    let cnt = 0;
    let per = 0;
    const totalDuration = 18000;
    const intervalDuration = 1000;
    const increment = 100 / (totalDuration / intervalDuration);

    const red = setInterval(() => {
      let bar = document.querySelector('.progress') as HTMLElement;
      if (!bar) {
        console.error('Progress bar element not found');
        clearInterval(red);
        return;
      }

      let percentage = setInterval(() => {
        per += 1;
        if (per >= cnt) clearInterval(percentage);
      }, intervalDuration / increment);

      cnt += increment;

      if (cnt >= 100) {
        cnt = 100;
        clearInterval(red);
      }
      bar.style.width = cnt + '%';
    }, intervalDuration);
}


}
