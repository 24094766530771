import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {
  private currentVersion: string | null = null; // Initialize as null to fetch the initial version
  private versionUrl = '../../../assets/version.json'; // Path to version.json
    private refreshRoutes = ['', '/Dashboard', '/Listing', '/Detail', '/MyBookings', '/transfers-listing', '/myAccount', '/forgot-password', '/favourites'];

  constructor(private http: HttpClient, private router : Router) {}

  public initVersionCheck() {
    this.http.get<{ version: string }>(this.versionUrl).subscribe(response => {
        debugger
      this.currentVersion = response.version; // Set initial version
      localStorage.setItem('appVersion', this.currentVersion);
    });
    // if(this.shouldRefresh()) {
        interval(6000).pipe(
            switchMap(() => this.http.get<{ version: string }>(this.versionUrl))
          ).subscribe(
            response => {
                debugger
                if(this.shouldRefresh() || this.router.url == "/" || !this.router.url) {
                    
                const latestVersion = response.version;
                const storedVersion = localStorage.getItem('appVersion');
                if (storedVersion && latestVersion != storedVersion) {
                console.log('New version detected.');
                localStorage.setItem('newVersionAvailable', 'true');
                localStorage.setItem('appVersion', latestVersion); 
                window.location.reload();
                }
              }
            },
            error => console.error('Error checking app version', error)
          );
    // }
    
  }

  private shouldRefresh(): boolean {
    const currentRoute = this.router.url;
    return this.refreshRoutes.includes(currentRoute);
  }

}


 // Path to version.json