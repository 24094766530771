import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    public isAuthenticated$ = this.isAuthenticated?.asObservable();
    token = '';

    constructor(private http: HttpClient) {
        this.loadToken();
    }

    async loadToken() {
        const token = sessionStorage?.getItem('tokenId');
        if (token) {
            this.token = token;
            this.isAuthenticated?.next(true);
        } else {
            this.isAuthenticated?.next(false);
        }
    }

    setLoginAuthentication(data: any, type: any) {
        if (data) {
            // Assuming 'data' is your token
            this.token = data;
            sessionStorage?.setItem('tokenId', data); // Save the token in sessionStorage
            this.loadToken(); // Call loadToken to update isAuthenticated
            // sessionStorage.setItem('torusToken', data);
            this.isAuthenticated?.next(true);
        } else {
            this.isAuthenticated?.next(false);
        }
    }

    logout(): void {
        this.isAuthenticated?.next(false);
        // sessionStorage.removeItem('id_token');
        // sessionStorage.removeItem('torusSkip');
        // sessionStorage.removeItem('OnboardingStep');
        // localStorage.removeItem('id_token');
        // localStorage.removeItem('torusSkip');
        // localStorage.removeItem('OnboardingStep');
        const storedData = sessionStorage?.getItem('userData');
        let userdata
        if (storedData && storedData!= "undefined") {
            userdata = JSON?.parse(storedData);
            userdata.location = null
            userdata.checkIn = null
            userdata.checkOut = null
            sessionStorage?.clear()
            sessionStorage?.setItem('userData', JSON?.stringify(userdata))
        } else sessionStorage?.clear()
    }

    setLogin() {
        this.isAuthenticated?.next(true);
    }
}
