import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';

@Component({
  selector: 'app-carRental',
  templateUrl: './carRental.component.html',
  styleUrls: ['./carRental.component.scss']
})
export class CarRentalComponent implements OnInit {

  searchControl = new FormControl();
  options: string[] = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];

  filteredOptions: Observable<string[]>;

  constructor() {
    this.filteredOptions = this.searchControl?.valueChanges?.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value?.toLowerCase();

    return this.options?.filter(option => option?.toLowerCase()?.includes(filterValue));
  }


  ngOnInit() {
  }

}
