<!-- <div class="travel-booking-form-container">
  <mat-card class="travel-booking-card">
    <mat-card-content>
        <form>
          <div class="form-field-container px-4">
            <h2 class="form-field-heading">Location :</h2>
            <mat-form-field appearance="outline" class="w-100">
              <input type="text" matInput placeholder="Search" [formControl]="searchControl" [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-field-container row px-4">
            <div class="col-md-6">
              <h2 class="form-field-heading">Check-in :</h2>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput [matDatepicker]="checkInPicker">
                <mat-datepicker-toggle matSuffix [for]="checkInPicker"></mat-datepicker-toggle>
                <mat-datepicker #checkInPicker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <h2 class="form-field-heading">Check-out :</h2>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput [matDatepicker]="checkOutPicker" >
                <mat-datepicker-toggle matSuffix [for]="checkOutPicker"></mat-datepicker-toggle>
                <mat-datepicker #checkOutPicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="form-field-container px-4">
            <h2 class="form-field-heading">Passengers</h2>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput type="number" placeholder="Enter number of passengers">
            </mat-form-field>
          </div>
          <div class="form-field-container px-4">
            <h2 class="form-field-heading">Board Base</h2>
            <mat-form-field appearance="outline" class="w-100">
              <mat-select  placeholder="Any">
                <mat-option value="option1">Option 1</mat-option>
                <mat-option value="option2">Option 2</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="px-4">
            <button class="main-button p-2 mt-2 w-100 mb-3">Search</button>
          </div>
        </form>
      </mat-card-content>
  </mat-card>
</div> -->

<!-- <div class="wrapper">
  <div class="container comingSoon">
    <h1>Coming soonn<span class="dot orange-color">.</span></h1>
  </div>
</div> -->
 <!-- <app-header> </app-header> -->
 <app-splashScreen *ngIf="showLoader"></app-splashScreen>
 <app-header [logo]="image?.ratelocLogo" [section1]="'Dashboard'" [section2]="'My Bookings'" [section3]="''"></app-header>
<div class="wrapper"  *ngIf="!showLoader">
    
     <div class="containerr">
      <form  class="search-form">
        <div class="form-group position-relative">
          <label for="pickup-location">Pick-up Location:</label>
          <input autocomplete="off" type="text" id="pickup-location" name="pickup-location" placeholder="Enter pick-up location" 
                 (input)="onSearchTransfer($event)" [(ngModel)]="transfer" 
                 (focus)="showDropdown = true" >
          
          <div class="dropdown-content" *ngIf="showDropdown && locations.length > 0">
            <div class="column">
              <h3>Hotels</h3>
              <ul class>
                <li *ngFor="let hotel of hotelLocations; let i = index" (click)="selectLocation(hotel, i)">
                  <i class="hotel-icon"></i>
                  {{ hotel.name }}
                </li>
              </ul>
            </div>
  
            <div class="column">
              <h3>Terminals</h3>
              <ul>
                <li *ngFor="let terminal of terminalLocations; let i = index" (click)="selectLocation(terminal, i)">
                  <i class="terminal-icon"></i>
                  {{ terminal.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        
        <div class="form-group position-relative">
          <label for="dropoff-location">Drop-off Location:</label>
          <input autocomplete="off" type="text" id="drop-off-location" name="drop-off-location" placeholder="Enter drop-off location" 
                 (input)="onSearchDropOff($event)" [(ngModel)]="dropOffTransfer" 
                 (focus)="showDropdown2 = true">
          
          <div class="dropdown-content" *ngIf="showDropdown2 && dropOffLocations.length > 0">
            <div class="column">
              <h3>Hotels</h3>
              <ul>
                <li *ngFor="let hotel of dropOffHotelLocations; let i = index" (click)="selectLocation2(hotel, i)">
                  <i class="hotel-icon"></i>
                  {{ hotel.name }}
                </li>
              </ul>
            </div>
            
            <div class="column">
              <h3>Terminals</h3>
              <ul>
                <li *ngFor="let terminal of dropOffTerminalLocations; let i = index" (click)="selectLocation2(terminal, i)">
                  <i class="terminal-icon"></i>
                  {{ terminal.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        
        <div class="form-group">
          <label for="date">Date:</label>
          <input matInput #drp="bsDatepicker" bsDatepicker [(ngModel)]="bsRangeValue" 
          placeholder="Check in - Check out" type="text"
          [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMM', showWeekNumbers: false ,displayMonths: 2}"
          (bsValueChange)="onPickUpTransfer($event)"  [ngModelOptions]="{standalone: true}">
        </div>
        
        <div class="form-group">
          <label for="time">Time:</label>
          <select id="time" name="time" [(ngModel)]="pickUpTime">
            
            <option value="00:00">00:00</option>
            <option value="00:30">00:30</option>
            <option value="01:00">01:00</option>
            <option value="01:30">01:30</option>
            <option value="02:00">02:00</option>
            <option value="02:30">02:30</option>
            <option value="03:00">03:00</option>
            <option value="03:30">03:30</option>
            <option value="04:00">04:00</option>
            <option value="04:30">04:30</option>
            <option value="05:00">05:00</option>
            <option value="05:30">05:30</option>
            <option value="06:00">06:00</option>
            <option value="06:30">06:30</option>
            <option value="07:00">07:00</option>
            <option value="07:30">07:30</option>
            <option value="08:00">08:00</option>
            <option value="08:30">08:30</option>
            <option value="09:00">09:00</option>
            <option value="09:30">09:30</option>
            <option value="10:00">10:00</option>
            <option value="10:30">10:30</option>
            <option value="11:00">11:00</option>
            <option value="11:30">11:30</option>
            <option value="12:00">12:00</option>
            <option value="12:30">12:30</option>
            <option value="13:00">13:00</option>
            <option value="13:30">13:30</option>
            <option value="14:00">14:00</option>
            <option value="14:30">14:30</option>
            <option value="15:00">15:00</option>
            <option value="15:30">15:30</option>
            <option value="16:00">16:00</option>
            <option value="16:30">16:30</option>
            <option value="17:00">17:00</option>
            <option value="17:30">17:30</option>
            <option value="18:00">18:00</option>
            <option value="18:30">18:30</option>
            <option value="19:00">19:00</option>
            <option value="19:30">19:30</option>
            <option value="20:00">20:00</option>
            <option value="20:30">20:30</option>
            <option value="21:00">21:00</option>
            <option value="21:30">21:30</option>
            <option value="22:00">22:00</option>
            <option value="22:30">22:30</option>
            <option value="23:00">23:00</option>
            <option value="23:30">23:30</option>
          </select>
        </div>
        
        <div class="form-group position-relative">
            <label for="passengers">Passengers:</label>
            <div class="passengers-dropdown" (click)="toggleDropdown()">
              <input type="text" id="passengers" readonly [value]="getPassengerSummary()" />
            </div>
            <div class="dropdown-menuu" *ngIf="isDropdownOpen">
                <div class="form-group">
                  <label for="adults">Adults:</label>
                  <select id="adults" name="adults" [(ngModel)]="passengerDetails.adults">
                    <option *ngFor="let adult of adultsArray" [value]="adult">{{ adult }}</option>
                  </select>
                </div>
        
                <div class="form-group">
                  <label for="children">Children:</label>
                  <select id="children" name="children" [(ngModel)]="passengerDetails.children" (change)="updateChildrenAges()">
                    <option *ngFor="let child of childrenArray" [value]="child">{{ child }}</option>
                  </select>
                </div>
        
                <div *ngFor="let child of childAgeArray; let i = index" class="form-group">
                  <label for="child-age-{{i}}">Child {{i + 1}} Age:</label>
                  <select id="child-age-{{i}}" [(ngModel)]="childAgeArray[i]" name="child-age-{{i}}">
                    <option *ngFor="let age of childrenAgeArray" [value]="age">{{ age }}</option>
                  </select>
                </div>
        
                <div class="form-group">
                  <label for="infants">Infants:</label>
                  <select id="infants" name="infants" [(ngModel)]="passengerDetails.infants">
                    <option *ngFor="let infant of infantsArray" [value]="infant">{{ infant }}</option>
                  </select>
                </div>
        
                <button type="button" class="done-btn" (click)="toggleDropdown()">Done</button>
              </div>
          </div>
  
            <div class="form-group" *ngIf="showReturnInfo">
              <label for="date">Return Date:</label>
              <input matInput #drp="bsDatepicker" bsDatepicker [(ngModel)]="bsRangeValueReturn" 
              placeholder="Check in - Check out" type="text"
              [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMM', showWeekNumbers: false ,displayMonths: 2}"
              (bsValueChange)="onDropOffTransfer($event)"  [ngModelOptions]="{standalone: true}">
            </div>
            
            <div class="form-group" *ngIf="showReturnInfo">
              <label for="time">Return Time:</label>
              <select id="time" name="returnTime" [(ngModel)]="dropOffTime">
                
                <option value="00:00">00:00</option>
                <option value="00:30">00:30</option>
                <option value="01:00">01:00</option>
                <option value="01:30">01:30</option>
                <option value="02:00">02:00</option>
                <option value="02:30">02:30</option>
                <option value="03:00">03:00</option>
                <option value="03:30">03:30</option>
                <option value="04:00">04:00</option>
                <option value="04:30">04:30</option>
                <option value="05:00">05:00</option>
                <option value="05:30">05:30</option>
                <option value="06:00">06:00</option>
                <option value="06:30">06:30</option>
                <option value="07:00">07:00</option>
                <option value="07:30">07:30</option>
                <option value="08:00">08:00</option>
                <option value="08:30">08:30</option>
                <option value="09:00">09:00</option>
                <option value="09:30">09:30</option>
                <option value="10:00">10:00</option>
                <option value="10:30">10:30</option>
                <option value="11:00">11:00</option>
                <option value="11:30">11:30</option>
                <option value="12:00">12:00</option>
                <option value="12:30">12:30</option>
                <option value="13:00">13:00</option>
                <option value="13:30">13:30</option>
                <option value="14:00">14:00</option>
                <option value="14:30">14:30</option>
                <option value="15:00">15:00</option>
                <option value="15:30">15:30</option>
                <option value="16:00">16:00</option>
                <option value="16:30">16:30</option>
                <option value="17:00">17:00</option>
                <option value="17:30">17:30</option>
                <option value="18:00">18:00</option>
                <option value="18:30">18:30</option>
                <option value="19:00">19:00</option>
                <option value="19:30">19:30</option>
                <option value="20:00">20:00</option>
                <option value="20:30">20:30</option>
                <option value="21:00">21:00</option>
                <option value="21:30">21:30</option>
                <option value="22:00">22:00</option>
                <option value="22:30">22:30</option>
                <option value="23:00">23:00</option>
                <option value="23:30">23:30</option>
              </select>
            </div>

        <div>
          <p (click)="toggleReturnInfo()"> {{ showReturnInfo ? 'Cancel Return' : 'Add Return' }}</p>
        <button type="submit" class="search-btnn main-button" (click)="Onsearch()">Search</button>
        </div>
   
      </form>
    </div>
  </div>
  
    <div class="container" *ngIf="!showLoader">

        <div class="content">
            <div class="filters">
                <h3>Filters</h3>
                <div class="filter-search">
                    <input type="text" placeholder="Search for a vehicle" />
                    <button class="search-btn">🔍</button>
                </div>
                <div class="filter-category">
                    <h4>Vehicle Name</h4>
                    <ul>
                        <li><input type="checkbox"> Adapted Vehicle <span>1</span></li>
                        <li><input type="checkbox"> Minibus <span>3</span></li>
                        <li><input type="checkbox"> SUV <span>2</span></li>
                        <li><input type="checkbox"> Shuttle <span>2</span></li>
                    </ul>
                </div>
                <div class="filter-category">
                    <h4>Vehicle Type</h4>
                    <ul>
                        <li><input type="checkbox"> Private <span>6</span></li>
                        <li><input type="checkbox"> Shuttle <span>2</span></li>
                    </ul>
                </div>
                <div class="filter-category">
                    <h4>Transfer Provider</h4>
                    <ul>
                        <li><input type="checkbox"> Hotelbeds Transfers <span>8</span></li>
                    </ul>
                </div>
            </div>

            <div class="results-list">
                <h2 class="fw-bold">Total Results: {{transferlist?.arrival?.length + transferlist?.departure?.length}}</h2>
                
                <ng-container *ngIf="transferlist?.arrival?.length > 0 && transferlist?.departure?.length > 0">
                  <!-- Arrival Section -->
                <ng-container *ngIf="transferlist?.arrival?.length > 0">
                  <div class="info-item" (click)="toggleArrival()">
                    <h2>Arrival Transfers {{transferlist?.arrival?.length }}</h2>
                    <i [class]="showArrival ? 'fa fa-angle-up' : 'fa fa-angle-down'"></i>
                  </div>
                  <div *ngIf="showArrival">
                    <ng-container *ngFor="let item of transferlist.arrival; let i = index">
                      <div class="result-item">
                        <div class="result-image">
                          <h2>Private Car - {{item.transferType}}</h2>
                          <img [src]="item.vehicleSpecification.images" alt="SUV Image">
                          <p>Transfers Provided by: A.T.R.L Travel</p>
                        </div>
                        
                        <div class="result-info">
                          <div class="d-flex align-items-center">
                            <i class="fa-solid fa-clock"></i>
                            <h4>Estimated Journey time {{item.vehicleSpecification.transferDetailInfo[0].name.split('.')[0] }}</h4>
                          </div>
                          <!-- <h4>{{ item.vehicleSpecification.vehicle.name }} - {{ item.vehicleSpecification.category.name }}</h4> -->
                           <div class="d-flex align-items-center my-3">
                            <i class="fa-solid fa-user"></i>
                             <p class="m-0">Maximum Number Of Passengers: {{ item.maxPaxCapacity }}</p>
                           </div>
                           <div class="d-flex align-items-center my-3">
                            <i class="fa-solid fa-user"></i>
                             <p class="m-0">Minimum Number Of Passengers: {{ item.minPaxCapacity }}</p>
                           </div>
  
                           <div class="d-flex align-items-center my-3">
                              <i class="fa-solid fa-suitcase"></i>
                             <div>
                              <!-- <p class="m-0">Luggage Allowance</p> -->
                              <p class="mt-1 mb-0">Luggage Allowance {{ item.vehicleSpecification.transferDetailInfo[3].name}}</p>
                             </div>
  
                             <!-- <p>Transfers Provided By: Hotelbeds Transfers</p> -->
                           </div>
                        </div>
                        
                        <div class="result-price">
                          <p>{{ item.price.amount | currency: 'GBP'}}</p>
                          <button class="details-btn" (click)="submitData(item)">Add</button>
                          <div class=" price-info-btn" (click)="toggleDetails(i)">
                            {{ showDetails[i] ? 'Less Information' : 'More Information' }}
                            <i class="fa fa-light fa-angle-down arrowWarning"></i>
                          </div>
                    
                          <!-- <div class="clickable-text" (click)="toggleDetails(i)">
                            {{ showDetails[i] ? 'Hide Important Information' : 'Show Important Information' }}
                          </div> -->
                        </div>
                      </div>
                    
                      <div class="details-content" *ngIf="showDetails[i]">
                        <p>{{ item.vehicleSpecification.transferRemarks[0].description }}</p>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>

                <!-- Departure Section -->
                <ng-container *ngIf="transferlist?.departure?.length > 0">
                  <div class="info-item" (click)="toggleDeparture()">
                    <h2>Departure Transfers {{transferlist?.departure?.length}}</h2>
                    <i [class]="showDeparture ? 'fa fa-angle-up' : 'fa fa-angle-down'"></i>
                  </div>
                  <div *ngIf="showDeparture">
                    <ng-container *ngFor="let item of transferlist.departure; let i = index">
                      <div class="result-item">
                        <div class="result-image">
                          <h2>Private Car - {{item.transferType}}</h2>
                          <img [src]="item.vehicleSpecification.images" alt="SUV Image">
                          <p>Transfers Provided by: A.T.R.L Travel</p>
                        </div>
                        
                        <div class="result-info">
                          <div class="d-flex align-items-center">
                            <i class="fa-solid fa-clock"></i>
                            <h4>Estimated Journey time {{item.vehicleSpecification.transferDetailInfo[0].name.split('.')[0] }}</h4>
                          </div>
                          <!-- <h4>{{ item.vehicleSpecification.vehicle.name }} - {{ item.vehicleSpecification.category.name }}</h4> -->
                           <div class="d-flex align-items-center my-3">
                            <i class="fa-solid fa-user"></i>
                             <p class="m-0">Maximum Number Of Passengers: {{ item.maxPaxCapacity }}</p>
                           </div>
                           <div class="d-flex align-items-center my-3">
                            <i class="fa-solid fa-user"></i>
                             <p class="m-0">Minimum Number Of Passengers: {{ item.minPaxCapacity }}</p>
                           </div>
  
                           <div class="d-flex align-items-center my-3">
                              <i class="fa-solid fa-suitcase"></i>
                             <div>
                              <!-- <p class="m-0">Luggage Allowance</p> -->
                              <p class="mt-1 mb-0">Luggage Allowance {{ item.vehicleSpecification.transferDetailInfo[3].name}}</p>
                             </div>
  
                             <!-- <p>Transfers Provided By: Hotelbeds Transfers</p> -->
                           </div>
                        </div>
                        
                        <div class="result-price">
                          <p>{{ item.price.amount | currency: 'GBP'}}</p>
                          <button class="details-btn" (click)="submitData(item)">See Details</button>
                          <div class=" price-info-btn" (click)="toggleDetails(i)"> {{ showDetails[i] ? 'Less Information' : 'More Information' }}
                            <i class="fa fa-light fa-angle-down arrowWarning"></i>
                          </div>
                    
                          <!-- <div class="clickable-text" (click)="toggleDetails(i)">
                            {{ showDetails[i] ? 'Hide Important Information' : 'Show Important Information' }}
                          </div> -->
                        </div>
                      </div>
                    
                      <div class="details-content" *ngIf="showDetails[i]">
                        <p>{{ item.vehicleSpecification.transferRemarks[0].description }}</p>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>

                </ng-container>

                  <ng-container *ngIf="!(transferlist?.arrival?.length > 0 && transferlist?.departure?.length > 0)">
                    <ng-container *ngFor="let item of transferlist?.arrival?.length > 0 ? transferlist?.arrival : transferlist?.departure let i = index">
                      <!-- <ng-container *ngFor="let item of transferlist.arrival; let i = index"> -->
                          <div class="result-item">
                            <div class="result-image">
                              <h2>Private Car - {{item.transferType}}</h2>
                              <img [src]="item.vehicleSpecification.images" alt="SUV Image">
                              <p>Transfers Provided by: A.T.R.L Travel</p>
                            </div>
                            
                            <div class="result-info">
                              <div class="d-flex align-items-center">
                                <i class="fa-solid fa-clock"></i>
                                <h4>Estimated Journey time {{item.vehicleSpecification.transferDetailInfo[0].name.split('.')[0] }}</h4>
                              </div>
                              <!-- <h4>{{ item.vehicleSpecification.vehicle.name }} - {{ item.vehicleSpecification.category.name }}</h4> -->
                               <div class="d-flex align-items-center my-3">
                                <i class="fa-solid fa-user"></i>
                                 <p class="m-0">Maximum Number Of Passengers: {{ item.maxPaxCapacity }}</p>
                               </div>
                               <div class="d-flex align-items-center my-3">
                                <i class="fa-solid fa-user"></i>
                                 <p class="m-0">Minimum Number Of Passengers: {{ item.minPaxCapacity }}</p>
                               </div>
      
                               <div class="d-flex align-items-center my-3">
                                  <i class="fa-solid fa-suitcase"></i>
                                 <div>
                                  <!-- <p class="m-0">Luggage Allowance</p> -->
                                  <p class="mt-1 mb-0">Luggage Allowance {{ item.vehicleSpecification.transferDetailInfo[3].name}}</p>
                                 </div>
      
                                 <!-- <p>Transfers Provided By: Hotelbeds Transfers</p> -->
                               </div>
                            </div>
                            
                            <div class="result-price">
                              <p>{{ item.price.amount | currency: 'GBP'}}</p>
                              <button class="details-btn" (click)="submitData(item)">See Details</button>
                              <div class=" price-info-btn" (click)="toggleDetails(i)"> {{ showDetails[i] ? 'Less Information' : 'More Information' }}
                                <i class="fa fa-light fa-angle-down arrowWarning"></i>
                              </div>
                        
                              <!-- <div class="clickable-text" (click)="toggleDetails(i)">
                                {{ showDetails[i] ? 'Hide Important Information' : 'Show Important Information' }}
                              </div> -->
                            </div>
                          </div>
                        
                          <div class="details-content" *ngIf="showDetails[i]">
                            <p>{{ item.vehicleSpecification.transferRemarks[0].description }}</p>
                          </div>
                        </ng-container>
                  </ng-container>
                  
                  
                <!-- Add more result-item divs as needed -->
            </div>
        </div>
    </div>
