<section class="about" id="about">
  <h1 class="font-poppins">Why Choose Rateloc?</h1>

  <!-- Row1 -->
  <div class="row font-poppins">
    <div class="box">
      <img [src]="'/assets/images/b2b global icon.svg'" alt="" class="icon">
      <h4 class="text-base">Exposure to a global B2B network</h4>
    </div>
    <div class="box">
      <img [src]="'/assets/images/24by7.svg'" alt="" class="icon">
      <h4 class="text-base">Live availability 24/7</h4>
    </div>
    <div class="box">
      <img [src]="'/assets/images/support.svg'" alt="" class="icon">
      <h4 class="text-base">Dedicated support team</h4>
    </div>
    <div class="box">
      <img [src]="'/assets/images/hotel.svg'" alt="" class="icon">
      <h4 class="text-base">Over 100,000 hotels worldwide</h4>
    </div>
  </div>


    <!-- Row 2 -->
    <div class="row mt-1 font-poppins">
      <div class="box">
        <img [src]="'/assets/images/bus.svg'" alt="" class="icon">
        <h4 class="text-base">Worldwide transfers</h4>
      </div>
      <div class="box">
      <img [src]="'/assets/images/car.svg'" alt="" class="icon">
        <h4 class="text-base">Worldwide car hire</h4>
      </div>
      <div class="box">
        <img [src]="'/assets/images/location.svg'" alt="" class="icon">
        <h4 class="text-base">Attractions & Things to do</h4>
      </div>
      <div class="box">
        <img [src]="'/assets/images/marketing.svg'" alt="" class="icon">
        <h4 class="text-base">Marketing campaign opportunities</h4>
      </div>
    </div>
</section>