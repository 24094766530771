<div class="body">
    <div class="ccontainer">
        <div class="logo-container">
          <img [src]="image?.ratelocLogo" alt="Logo">
        </div>
        <div class="instructions">
            <h2>Create a strong Password</h2>
            <p class="text-muted">Your password must be at least six characters and should include a combination of numbers, letters and special characters (!$@%).</p>
        </div>
        <form id="passwordForm" [formGroup]="passwordForm" (ngSubmit)="submit()">
          <div class="form-group">
            <!-- <div class="input-container"> -->
              <input matInput type="password" formControlName="password" id="password" [placeholder]="'New Password'" name="password" required [type]="hide ? 'password' : 'text'">
              <button class="view-icon" mat-icon-button type="button" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
              <!-- <span id="showPassword" class="view-icon" onclick="togglePasswordVisibility('password')"><i class="fas fa-eye"></i></span> -->
            <!-- </div> -->
            <mat-error *ngIf="passwordForm?.get('password')?.hasError('required') && passwordForm?.controls?.password?.touched"
              class="text-xs d-flex align-items-center mt-1">
              <mat-icon matPrefix fontIcon="info" class="to-red-600 mr-1"></mat-icon>
              Password is required</mat-error>
            <mat-error *ngIf="passwordForm?.get('password')?.hasError('pattern')"
              class="text-xs d-flex align-items-center mt-1">
              <mat-icon matPrefix fontIcon="info" class="to-red-600 mr-1"></mat-icon>
              Password must be of minimum eight characters, at least one uppercase case, one lowercase case, one number
              and one special character</mat-error>
          </div>
          <div class="form-group">
            <!-- <div class="input-container"> -->
              <input matInput type="password" formControlName="passwordAgain" id="confirmPassword" [placeholder]="'New Password Again'" name="confirmPassword" required [type]="confirmHide ? 'password' : 'text'">
              <button class="view-icon" mat-icon-button type="button" matSuffix (click)="confirmHide = !confirmHide"
              [attr.aria-label]="'Hide password'" [attr.aria-pressed]="confirmHide">
              <mat-icon>{{confirmHide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
              <!-- <span id="showConfirmPassword" class="view-icon" onclick="togglePasswordVisibility('confirmPassword')"><i class="fas fa-eye"></i></span> -->
            <mat-error *ngIf="passwordForm?.get('passwordAgain')?.hasError('required') && passwordForm?.get('passwordAgain')?.touched"
              class="text-xs d-flex align-items-center mt-1">
              <mat-icon matPrefix fontIcon="info" class="to-red-600 mr-1"></mat-icon>
              Password is required</mat-error>
            </div>

          <!-- </div> -->
          <p class="text-danger" *ngIf="mismatchPassword">Password does not match</p>
          <div class="bottom-section">
            <div id="message" class="message"></div>
            <button type="submit" class="main-button">Reset Password</button>
          </div>
        </form>
      </div>
</div>
