<div>
  <app-header [logo]="image?.ratelocLogo" [section1]="'Home'" [section2]="'About'" [section3]="'Contact'"></app-header>

  <div class="aboutHeading">
    <div class="image-layer"></div>
    <h1 class="text-7xl md:pb-10 pt-36 text-center font-poppins">Contact Us</h1>
  </div>

  <div class="main-box pt-6 md:pt-2 font-poppins mb-20">
    <div class="font-poppins md:pt-4 text-center md:px-40 px-20">
      <h4 class="text-black pt-3">
        We are here to help you, and we have dedicated call centres with professional agents. At Rateloc, you can always
        count on us to provide you with the support you need.
      </h4>
      <p class="text-gray-800 text-center pt-4">
        To get in touch with us, simply call our main contact number, or email us using the contact details provided
        below. If you prefer a hassle-free way to make an inquiry, just fill out the form below and our team of travel
        specialists will respond promptly.
      </p>
    </div>
  </div>

  <app-support></app-support>
  <app-ourLocations></app-ourLocations>
  <app-contactDetails></app-contactDetails>

  <app-footer></app-footer>

</div>