<form [formGroup]="forgotPassword" class="font-poppins" style="background-color: rgba(253, 243, 236, 1);"
  (ngSubmit)="onSend()">
  <mat-dialog-content *ngIf="!otpSent">
    <h2 class="font-poppins">
      Reset your password</h2>
    <div class="font-poppins">
      <mat-form-field appearance="outline" class="w-100">
        <mat-icon matPrefix fontIcon="mail" class="orange-color"></mat-icon>
        <input matInput type="email" placeholder="Email" formControlName="email" required />
        <!-- Error Message -->
        <mat-error *ngIf="forgotPassword?.get('email')?.hasError('required')"
          class="text-xs d-flex align-items-center mt-1">
          <mat-icon matPrefix fontIcon="info" class="to-red-600 mr-1"></mat-icon>
          Email is required</mat-error>
        <mat-error *ngIf="forgotPassword?.get('email')?.hasError('pattern')"
          class="text-xs d-flex align-items-center mt-1">
          <mat-icon matPrefix fontIcon="info" class="to-red-600 mr-1"></mat-icon>
          Please enter a valid email address</mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="!otpSent">
    <div class="w-100 flex justify-center align-items-center text-center -mt-10">
      <button class="main-button p-2 mx-2" (click)="dialogRef.close()">Cancel</button>
      <button class="main-button p-2 mx-2" type="submit">Send</button>
    </div>
  </mat-dialog-actions>


  <div *ngIf="otpSent" class="relative font-inter antialiased">

    <main class="relative flex flex-col justify-center bg-slate-50 overflow-hidden">
      <div class="w-full max-w-6xl">
        <div class="flex justify-center">

          <div class="max-w-md text-center bg-white px-2 py-3  rounded-xl shadow">
            <header class="mb-8">
              <h1 class="text-2xl font-bold mb-1">Email Verification</h1>
              <p class="text-[15px] text-slate-500">Enter the 4-digit verification code that was sent to
                {{forgotPassword?.value?.email}}.</p>
            </header>

            <div class="flex items-center justify-center gap-3">

              <div formArrayName="otps">
                <input *ngFor="let control of forgotPassword?.controls?.otps?.controls; let i = index"
                  [formControlName]="i" type="text" [id]="'otpInput' + i" (input)="onInput($event, i)" (keydown)="onKeyDown($event,i)" maxlength="1"
                  class="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded mx-2 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100">
              </div>
            </div>
            <p *ngIf="wrongOtp" class="text-danger my-2"> Please enter correct otp!</p>
            <div class="max-w-[260px] mx-auto mt-4">
              <button type="submit"
                class="main-bg-color w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150"
                (click)="verifyAccount()">Verify
                Account</button>
            </div>
            <div  class="text-sm text-slate-500 mt-4">Didn't receive code? <a (click)="otpSent=false"
              [ngClass]="{'disabled': disableButton}"  class="main-color font-medium text-indigo-500 hover:text-indigo-600">Resend</a></div>
          <div *ngIf="timerMessage" class="">Otp can be send after <span class="main-color">{{timerMessage}}</span> seconds.</div>
          </div>
        </div>
      </div>
    </main>
  </div>
</form>