import { Component, HostListener, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ConfigService } from './core/services/config.service';
import { DataTransferServiceService } from './core/services/data-transfer-service.service';
import { AuthenticationService } from './core/services/authentication.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { VersionCheckService } from './core/services/VersionCheckService.sevice';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  inactivityInterval: any;
  closingModalRef!: BsModalRef;
  @ViewChild('inactiveModal',{static:true}) inactiveModal:any
  isModalOpen: boolean = false;
  logDataTransferSub!: Subscription;
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
      const clickedElement = event?.target as HTMLElement;
      const className = clickedElement?.className;
    if(!(className == 'container dropDownContainer mx-4 my-4 p-0' || className == 'dropdown-toggle accounts' || className == 'dropdown-account-toggle-span'))
    this.dataTransferService?.accountDropdownData(true)
  }

  @HostListener('window:mousemove')
  @HostListener('window:scroll')
  @HostListener('window:keydown')
  userPerformedAction() {
    // if (this.route?.url == '/success') this.dataTransferService?.closeModalM('success')
    // if (this.route?.url == '/failure') this.dataTransferService?.closeModalM('failure')
    // if (this.route?.url == '/Register') this.dataTransferService?.closeModalM('register')

    if(this.isModalOpen) this.dis()
    // const currentTime = new Date().getTime();
    // const expiryTime = currentTime + 3600000
    // sessionStorage.setItem('expiryTime',JSON.stringify(expiryTime))
  }
  constructor(private dataTransferService : DataTransferServiceService, private authService: AuthenticationService, private route: Router, public modalService: BsModalService, private versionCheckService : VersionCheckService ){
  }
  
  ngOnInit() {
    // this.versionCheckService.initVersionCheck()
  // Start Interval logic when user login to rateloc
  this.logDataTransferSub?.unsubscribe()
  this.logDataTransferSub = this.dataTransferService?.logDataTransfer$?.subscribe(data => {
      if((String(data) == 'loggedIn')) this.startInterval()
    })
  // Start Interval logic for refreshing the current Page
    if(sessionStorage?.getItem('loggedIn') && this.inactivityInterval == undefined) this.startInterval()

  }

  startInterval() {
    clearInterval(this.inactivityInterval)
    this.inactivityInterval = setInterval(() => {
      const logoutTime = sessionStorage?.getItem('expiryTime');
      if (logoutTime) {
        const currentTime = new Date()?.getTime();
        if (currentTime > parseInt(logoutTime, 10)) {
          clearInterval(this.inactivityInterval)
          this.closingModalRef = this.modalService?.show(this.inactiveModal, {
            class: 'modal-md modal-dialog-centered',
          });
          this.isModalOpen = true
        }
      }
  }, 1000);
  }

  dis() {
    setTimeout(() => {
      this.closingModalRef?.hide()
      this.authService?.logout();
      this.route?.navigate(['/'])
      const storedData = sessionStorage?.getItem('userData');
      let userdata
      if (storedData && storedData!= "undefined") {
        userdata = JSON?.parse(storedData);
        userdata.location = null
        userdata.checkIn = null
        userdata.checkOut = null
        sessionStorage?.clear()
        sessionStorage?.setItem('userData', JSON?.stringify(userdata))
    } else sessionStorage?.clear()
      this.isModalOpen = false
    }, 1000)
    
  }

  ngOnDestroy() {
    this.logDataTransferSub?.unsubscribe()
    }

}
