import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { ConfigService } from 'src/app/core/services/config.service';
import * as bookingData from './bookingHistory.json';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastService } from 'src/app/core/services/toast.service';
import { DataTransferServiceService } from 'src/app/core/services/data-transfer-service.service';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
})
export class BookingComponent implements OnDestroy {
  @Output() bookingData = new EventEmitter<any>();
  image: any;
  bookingHistory: any;
  url!: string;
  bookingHistoryNames: any = [];
  bookingHistoryDeadline: any = [];
  searchQuery: string = '';
  filteredBookings: any = [];
  filteredData: boolean = false;
  // isExpanded: boolean = false;
  expandedRowIndex: number | null = null;
  unpaidCount: any;
  remainingCredit: any;
  bookingRefId: any;
  isActive: boolean = false;
  isActiveRecent: boolean = false;
  isActiveTransfer: boolean = false;
  isActiveUnpaid: boolean = false;
  supplierRefId: any;
  supplier: any;
  bookingHistorySub!: Subscription;
  bookingCancelSub!: Subscription;
  noBookingAvailable: boolean = false;
  apiCurrencyValue: any;
  currencyCode: any;
  loader: boolean = false;
  isTransferRowExpanded : boolean = false;
  // isActiveRecent = false;
  // isActiveUnpaid = false;
  // isActiveTransfer = false;


  constructor(
    private configService: ConfigService,
    private router: Router,
    private commonService: CommonService,
    private modalService: BsModalService,
    private bsModalRef: BsModalRef,
    private toastService: ToastService,
    private dataTransferService: DataTransferServiceService
  ) {}


  closingModalRef!: BsModalRef;
  closingLoaderModalRef!: BsModalRef;
  @ViewChild('cancelModal', { static: true }) cancelModal: any;
  @ViewChild('loaderModal', { static: true }) loaderModal: any;

  ngOnInit() {
    this.toggleActive('recent');
    this.filterRecent();
    this.apiCurrencyValue = sessionStorage.getItem('currencyApi');
    console.log(this.apiCurrencyValue);
    console.log(sessionStorage.getItem('currencyApi'));
    
    
    const storedCurrency = sessionStorage?.getItem('currency');
    if(storedCurrency){
      let currencyData = JSON?.parse(storedCurrency);
      this.currencyCode = currencyData?.code;
    }
    this.url = this.router?.url?.slice(1);
    // this.bookingHistory = bookingData?.bookingHistory;
    this.image = this.configService?.getImages();
    this.bookingHistoryApi();
    // console.log(this.router.url)
  }

  openModal(refId: any, suppId: any, supplier:any) {
    this.bookingRefId = refId;
    this.supplier = supplier
    this.supplierRefId = suppId;
    this.closingModalRef = this.modalService?.show(this.cancelModal, {
      class: 'modal-md modal-dialog-centered',
    });
  }

  openLoaderModal() {
    this.closingLoaderModalRef = this.modalService?.show(this.loaderModal, {
      class: 'modal-md modal-dialog-centered',
    });
  }

  
  
  toggleActive(type: string): void {
      if (type === 'recent') {
          this.isActiveRecent = true;
          this.isActiveUnpaid = false;
          this.isActiveTransfer = false;
      } else if (type === 'unpaid') {
          this.isActiveRecent = false;
          this.isActiveUnpaid = true;
          this.isActiveTransfer = false;
      } else if (type === 'transfers') {
          this.isActiveRecent = false;
          this.isActiveUnpaid = false;
          this.isActiveTransfer = true;
      }
  }
  

  closeModal() {
    // setTimeout(() => {
    this.closingModalRef?.hide();
    // this.router.navigate(['/MyBookings']);
    // }, 2000);
  }
  loaderCloseModal() {
    // setTimeout(() => {
    this.closingLoaderModalRef?.hide();
    // this.router.navigate(['/MyBookings']);
    // }, 2000);
  }

  toggleRow(index: number): void {
    this.expandedRowIndex = this.expandedRowIndex === index ? null : index;
  }

  isRowExpanded(index: number): boolean {
    return this.expandedRowIndex === index;
  }

  navigate() {
    this.router?.navigate(['/MyBookings']);
  }

  payNow(refId: any, price: any){
    let params = {
      amount: this.calculateTotalPrice(price),
      bookingRefId: refId,
      currency: this.currencyCode
    };
    
    this.commonService.stripeBooking(params).subscribe((data) => {
      console.log(data);

      sessionStorage.setItem('bookingRefId', data.bookingRefId);

      window.location.href = data.url;
    });
    
  }

  calculatePercentOfTotalPrice(price: any): number {
    let totalPrice = parseFloat(
      price
    );
    if (!isNaN(totalPrice)) {
      // Calculate the price with an additional 1.5% charge
      return +(totalPrice * 0.015).toFixed(2); // Limit decimals to two
    }
    return 0;
  }

  calculateTotalPrice(price: any): number {
    let totalPrice = parseFloat(
      price
    );
    if (!isNaN(totalPrice)) {
      // Calculate the price with an additional 1.5% charge
      return +(totalPrice * 1.015).toFixed(2); // Limit decimals to two
    }
    return 0; // Or whatever default value you want to return if the calculation fails
  }

  bookingHistoryApi() {
    this.bookingHistorySub?.unsubscribe()
    this.bookingHistorySub = this.commonService?.bookingHistory()?.subscribe((data: any) => {
      
      this.bookingHistory = data?.bookings;
      this.noBookingAvailable = true;
      this.noBookingAvailable = this.bookingHistory?.length === 0;
      this.filteredBookings = data?.bookings;
      this.remainingCredit = data?.remainingCreditLimit;
      const totalPrice = this.bookingHistory?.reduce(
        (total: number, booking: any) => {
          return total + booking?.price;
        },
        0
      );
      const totalPriceUnpaid = this.bookingHistory?.reduce(
        (total: number, booking: any) => {
          if (booking?.paymentStatus === 'Unpaid') {
            return total + booking?.price;
          }
          return total;
        },
        0
      );
      const totalOrdersCount = this.bookingHistory?.length;

      // Confirmed orders count
      const confirmedOrdersCount = this.bookingHistory?.filter(
        (booking: any) => booking?.bookingStatus === 'Confirmed'
      )?.length;

      // Unpaid orders count
      const unpaidOrdersCount = this.bookingHistory?.filter(
        (booking: any) => booking?.paymentStatus === 'Unpaid'
      )?.length;
      this.unpaidCount = unpaidOrdersCount;

      let obj = {
        remainingCredit: this.remainingCredit,
        totalPrice: totalPrice,
        totalPriceUnpaid: totalPriceUnpaid,
        totalOrdersCount: totalOrdersCount,
        confirmedOrdersCount: confirmedOrdersCount,
        unpaidOrdersCount: unpaidOrdersCount,
      };
      this.dataTransferService?.setBookingHistoryData(obj);
      let json = this.bookingHistory?.forEach((ele: any, i: number) => {
        const datePipe = new DatePipe('en-US');
        let dateString = ele?.checkInDate;
        let parts = dateString?.split('-');
        let dateObject = new Date(
          Number(parts[2]),
          Number(parts[1]) - 1,
          Number(parts[0])
        );
        this.bookingHistory[i].checkInDate = datePipe?.transform(
          dateObject,
          'yyyy-MM-dd'
        );
        dateString = ele?.checkOutDate;
        parts = dateString?.split('-');
        dateObject = new Date(
          Number(parts[2]),
          Number(parts[1]) - 1,
          Number(parts[0])
        );
        this.bookingHistory[i].checkOutDate = datePipe?.transform(
          dateObject,
          'yyyy-MM-dd'
        );
        const jsonNamesArray = JSON?.parse(ele?.names);
       if(ele?.remarks && ele?.remarks != 'null') {const remarksArray = JSON?.parse(ele?.remarks);
        this.bookingHistory[i]['remarks'] = remarksArray
       }
        this.bookingHistory[i]['rooms'] = jsonNamesArray
        this.bookingHistory[i]['isCart'] = ["444","666"].includes(ele.supplierName)?true:false;

        console.log(this.bookingHistory);
        
        const jsonDeadlineArray = ele?.deadline;
        this.bookingHistoryNames[i] = jsonNamesArray;
        this.bookingHistoryDeadline[i] = jsonDeadlineArray;
        
      });
      this.bookingData?.emit();
    });
  }

  bookingCancel() {
    this.closeModal();
    let params = {
      bookingRefId: this.bookingRefId,
      supplierRefId: this.supplierRefId,
      supplier: this.supplier 
    };

    this.bookingCancelSub?.unsubscribe()
    this.bookingCancelSub = this.commonService?.bookingCancel(params)?.subscribe((data: any) => {
      // console.log(data);
      if (!data?.status) {
        this.toastService?.showInfo(data?.message);
      } else {
        this.bookingHistoryApi();
        // this.loaderCloseModal();
        this.toastService?.showSuccess(data?.message);
      }
      this.loaderCloseModal();
      this.closeModal();
    });
  }

  search() {
    this.filteredData = false;
    let newArr = [...this.bookingHistory]
    if (this.searchQuery == '') {
      this.bookingHistoryApi();
    } else {
      this.bookingHistory = newArr?.filter(
        (booking: any, i: number) => {
          const jsonNamesArray = JSON?.parse(booking?.names);
          const jsonDeadlineArray = booking?.deadline;
          this.bookingHistoryNames[i] = jsonNamesArray;
          this.bookingHistoryDeadline[i] = jsonDeadlineArray;
          return booking?.bookingRefId?.toLowerCase()?.includes(this.searchQuery?.toLowerCase());
        }
      );
    }
  }

  filterUnpaid() {
    this.isActive = !this.isActive;

    this.filteredData = true;
    this.filteredBookings = this.bookingHistory?.filter(
      (booking: any) => booking?.paymentStatus?.toLowerCase() == 'unpaid'
    );
  }

  filterRecent(): void {
    // Sort the bookings by bookedOn date in descending order
    this.isActive = false;
    this.filteredBookings = this.bookingHistory?.sort((a: any, b: any) => {
      const dateA = new Date(a?.bookedOn);
      const dateB = new Date(b?.bookedOn);
      return dateB?.getTime() - dateA?.getTime();
    });
    
    this.filteredData = true;
  }

  filterTransfers(): void {
    this.isActiveTransfer = true;
    this.filteredBookings = this.bookingHistory?.filter(
        (booking: any) => booking?.type?.toLowerCase() === 'transfer'
    );
    this.filteredData = true;
}


  ngOnDestroy(): void {
    this.bookingHistorySub?.unsubscribe()
    this.bookingCancelSub?.unsubscribe()
  }

  toggleTransferRow() {
    this.isTransferRowExpanded = !this.isTransferRowExpanded;
  }
}
