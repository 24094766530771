<app-header [logo]="image?.ratelocLogo" [section1]="'Dashboard'" [section2]="'My Bookings'" [name]="firstName"
    [firstName]="firstName"></app-header>
<!-- <app-header></app-header> -->
<!-- For Personal Information -->
<div class="main-box font-poppins">
    <!-- <div class="whole-container"> -->
    <!-- <div class="inner-container"> -->
    <!-- Side Div -->
    <div class="side-div">
        <div class="sideNavAccount">
            <img src="/assets/images/person-circle.svg" alt="">
            <h4 class="mb-2 fw-bolder">{{firstName | titlecase }} {{lastName | titlecase }}</h4>
            <h6 class="pb-5">{{email}}</h6>
        </div>
        <ul>
            <li [ngClass]="{'current-page': currentPage== 'personal'}" (click)="onClick('personal')">Personal
                Information</li>
            <hr>
            <li [ngClass]="{'current-page': currentPage== 'billing'}" (click)="onClick('billing')">Billing & Payment
            </li>
            <hr>
            <li [ngClass]="{'current-page': currentPage== 'order'}" (click)="onClick('order')">Order History</li>
            <hr>
            <li [ngClass]="{'current-page': currentPage== 'business'}" (click)="onClick('business')">General Details
            </li>
        </ul>
    </div>

    <div class="main-div">
        <!-- Grid Divs -->
        <div *ngIf="currentPage== 'personal'" class="container-fluid ">
            <div class="top-header">
                <h4 class="fw-bolder heading">Personal Information</h4>
                <p class="pb-3">Manage your personal information, including your phone numbers and email address where
                    you can be contacted</p>
            </div>
            <div class="flex-box">
                <div class="grid-div">
                    <h4>First Name</h4>
                    <p *ngIf="!editingFirstName" (click)="toggleEdit('firstName')">
                        {{ firstName | titlecase }}
                        <img class="edit-name" src="/assets/icons/pencil-solid.svg" width="20" alt="">
                    </p>
                    <div class="updateSection" *ngIf="editingFirstName">
                        <input type="text" [(ngModel)]="updatedFirstName">
                        <div class="buttonSection">
                            <img (click)="cancelEdit('firstName')" src="/assets/icons/x.svg" alt="">
                            <button class="saveButton" (click)="updateName('firstName')">Save</button>
                        </div>
                    </div>
                    <img src="/assets/images/person.svg" style="border: 2px solid #ec6408; border-radius: 40px;"
                        width="25" alt="">
                </div>
                <hr>
                <div class="grid-div">
                    <h4>Last Name</h4>
                    <p *ngIf="!editingLastName" (click)="toggleEdit('lastName')">
                        {{ lastName | titlecase }}
                        <img class="edit-name" src="/assets/icons/pencil-solid.svg" width="20" alt="">
                    </p>
                    <div class="updateSection" *ngIf="editingLastName">
                        <input type="text" [(ngModel)]="updatedLastName">
                        <div class="buttonSection">
                            <img (click)="cancelEdit('lastName')" src="/assets/icons/x.svg" alt="">
                            <button class="saveButton" (click)="updateName('lastName')">Save</button>
                        </div>
                    </div>
                    <img src="/assets/images/person.svg" style="border: 2px solid #ec6408; border-radius: 40px;"
                        width="25" alt="">
                </div>
                <hr>

                <div class="grid-div">
                    <h4>Country Region</h4>
                    <p>{{nationality}}</p>
                    <img src="/assets/images/translate.svg" width="25" alt="">
                </div>
                <hr>

                <!-- <div class="grid-div" >
                        <h4>Country Region</h4>
                        <p *ngIf="!editingNationality" (click)="toggleEditNationality()">
                        <p>
                          {{ nationality }}
                          <img class="edit-name" src="/assets/icons/pencil-solid.svg" width="20" alt="Edit Nationality">
                        </p>
                        <div class="d-flex" *ngIf="editingNationality">
                          <mat-form-field appearance="fill">
                            <input matInput formControlName="nationalityControl" [formControl]="nationalityControl" [matAutocomplete]="nationalAutoComplete" placeholder="Choose your nationality" required>
                            <mat-autocomplete #nationalAutoComplete="matAutocomplete">
                              <mat-option *ngFor="let x of filteredNationalities | async" [value]="x?.countryName + ' - ' + x?.countryCode">
                                {{x?.countryName}} - {{x?.countryCode}}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                          <div class="updateDiv">
                            <button mat-button (click)="onSelectNationality()">Save</button>
                          </div>
                        </div>
                        <img src="/assets/images/globe.svg" width="25" alt="Globe">
                      </div>
                      <hr> -->

                <div class="grid-div">
                    <h4>Phone Number</h4>
                    <p *ngIf="!editingPhoneNumber">{{selectedCountryCode}} {{ phoneNumber }}
                        <img class="edit-name" src="/assets/icons/pencil-solid.svg" width="20" alt=""
                            (click)="toggleEditPhoneNumber()">
                    </p>
                    <div class="updateSection" *ngIf="editingPhoneNumber">
                        <input type="text" [(ngModel)]="updatedPhoneNumber">
                        <div class="buttonSection">
                            <img src="/assets/icons/x.svg" alt="" (click)="cancelEditPhoneNumber()">
                            <button class="saveButton" (click)="savePhoneNumber()">Save</button>
                        </div>
                    </div>
                    <img src="/assets/images/person.svg" style="border: 2px solid #ec6408; border-radius: 40px;"
                        width="25" alt="">
                </div>
                <hr>

                <div class="grid-div">
                    <h4>Language</h4>
                    <p>{{"UK- English"}}</p>
                    <img src="/assets/images/translate.svg" width="25" alt="">
                </div>
                <hr>
                <div class="grid-div">
                    <h4>Contactable at</h4>
                    <p>{{email}}</p>
                    <img src="assets/images/person-lines-fill.svg" width="25" alt="">
                </div>
            </div>
        </div>
        <!-- billing -->
        <div *ngIf="currentPage== 'billing'" class="container-fluid ">
            <div class="top-header">
                <h4 class="fw-bolder heading">Billing & Payment</h4>
                <!-- <p>Manage your personal information, including your phone numbers and email address where you can be contacted</p> -->
            </div>
            <div class="flex-box">
                <div class="grid-div">
                    <div class="row mb-2">
                        <div class="col-md-3">
                            <h4>Total Orders</h4>
                            <p>{{totalOrdersCount}}</p>
                        </div>
                        <div class="col-md-4">
                            <h4>Confirmed Orders</h4>
                            <p>{{confirmedOrdersCount}}</p>
                        </div>
                        <div class="col-md-3">
                            <h4>Unpaid Orders</h4>
                            <p style="color: red">{{unpaidOrdersCount}}</p>
                        </div>
                    </div>
                    <img src="/assets/images/bag-check.svg" width="25" alt="">
                </div>
                <hr>
                <div class="grid-div">
                    <h4>Payment Due</h4>
                    <p class="paymentDue">{{ totalPriceUnpaid*apiCurrencyValue | currency: currencyCode }}</p>
                    <img src="/assets/images/credit-card-2-front.svg" width="25" alt="">
                </div>
                <hr>
                <div class="grid-div">
                    <h4>Total amount spent in {{currencyCode}}</h4>
                    <p>{{ totalPrice*apiCurrencyValue | currency: currencyCode }}</p>
                    <img src="/assets/images/cash-stack.svg" width="25" alt="">
                </div>
            </div>
        </div>
        <!-- order -->
        <div *ngIf="currentPage== 'order'" class="container-fluid ">
            <div class="top-header">
                <h4 class="fw-bolder heading">Order History</h4>
                <app-booking></app-booking>
            </div>
        </div>
        <!-- For business -->
        <div *ngIf="currentPage== 'business'" class="container-fluid ">
            <div class="top-header">
                <h4 class="fw-bolder heading">Business Information</h4>
                <!-- <p>Manage your personal information, including your phone numbers and email address where you can be contacted</p> -->
            </div>
            <div class="flex-box">
                <div class="grid-div">
                    <h4>Company Name</h4>
                    <p>{{"Atlas Travels"}}</p>
                    <img src="/assets/images/buildings.svg" width="25" alt="">
                </div>
                <hr>
                <div class="grid-div">
                    <h4>Company Website</h4>
                    <p>{{"atlastravels.co.uk"}}</p>
                    <img src="/assets/images/globe.svg" width="25" alt="">
                </div>
                <hr>
                <div class="grid-div">
                    <h4>Business Type</h4>
                    <p>{{"B2C"}}</p>
                    <img src="/assets/images/exclamation-circle.svg" width="25" alt="">
                </div>
                <hr>
                <div class="grid-div">
                    <h4>Registration No.</h4>
                    <p>{{"1234567890"}}</p>
                    <img src="/assets/images/bag-check-fill.svg" width="25" alt="">
                </div>
                <hr>
                <div class="grid-div">
                    <h4>Currency</h4>
                    <p>{{"Pound"}}</p>
                    <img src="/assets/images/coin.svg" width="25" alt="">
                </div>
            </div>
        </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
</div>