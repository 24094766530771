<div class="contactContainer">
  <div class="row font-poppins md:px-40 w-100 py-10">
    <div class="col-md-6 text-white font-poppins md:pt-28">
      <p class="text-black text-center">
        <span class="text-2xl font-bold">Have a question?</span>
        We're here to help.
      </p>
      <p class="text-gray-800 px-10 text-center">
        Send Us a message and we'll be in touch.
      </p>
      <img [src]="'assets/images/logo.svg'" alt="" class="h-20 w-100">
    </div>
    <div class="col-md-6">
      <form [formGroup]="contactForm" (ngSubmit)="onContact()">
        <div class="flex justify-center items-center w-100 pt-14">
          <mat-form-field appearance="outline" class="w-50 mr-4">
            <!-- <mat-icon matPrefix fontIcon="mail" class="orange-color"></mat-icon> -->
            <input matInput type="text" placeholder="Name" formControlName="name"/>
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-50">
            <!-- <mat-icon matPrefix fontIcon="mail" class="orange-color"></mat-icon> -->
            <input matInput type="email" placeholder="Email" formControlName="email" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="w-100">
            <!-- <mat-icon matPrefix fontIcon="mail" class="orange-color"></mat-icon> -->
            <input matInput type="number" placeholder="Phone Number" formControlName="number" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="w-100">
            <!-- <mat-icon matPrefix fontIcon="mail" class="orange-color"></mat-icon> -->
            <textarea matInput placeholder="Message" formControlName="message"></textarea>
          </mat-form-field>
        </div>
        <div class="inputbox">
          <button class="main-button p-3 mb-2" type="submit" [disabled]="!contactForm.valid">Send Message</button>
        </div>
      </form>
    </div>
  </div>
</div>