import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { DataTransferServiceService } from 'src/app/core/services/data-transfer-service.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { DetailComponent } from 'src/app/modules/detail/detail.component';
import { SearchformModalComponent } from './searchform-modal/searchform-modal.component';
import { PreviousUrlService } from 'src/app/core/services/navigation.service';

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.scss']
})
export class FavouritesComponent implements OnInit, OnDestroy {
  url: string = '';
  image: any;
  favouritesList: any;
  deleteFavouriteSub!: Subscription;
  getFavouriteSub!: Subscription;
  starsArray!: number[];
  searchTerm: any;
  backupArr: any;

  constructor(    private configService: ConfigService,
    private router: Router,
    private commonService: CommonService,
    private modalService: BsModalService,
    private bsModalRef: BsModalRef,
    private toastService: ToastService,
    private navigationService : PreviousUrlService,
    private dataTransferService: DataTransferServiceService) {}


  ngOnInit(): void {
    this.url = this.router?.url?.slice(1);
    this.image = this.configService?.getImages();
    this.getFavouriteList()
    this.starsArray = this.getStarsArray(5)
  }

  getStarsArray(rating: number): number[] {
    return Array?.from({ length: rating }, (_, index) => index + 1);
  }

  backIcon() {
    let url = this.navigationService?.getPreviousUrl()
    this.router?.navigate([url])
  }

  removeFavourite(id:string) {
    let obj = {
      id: id
    }
    this.deleteFavouriteSub?.unsubscribe()
    this.deleteFavouriteSub = this.commonService?.favourites('Delete', obj)?.subscribe(data => {
      this.getFavouriteList()
      if(data?.message == 'Deleted Successfully') this.toastService?.showSuccess(data?.message, 'Success')
      else this.toastService?.showError('something went wrong', 'Error')
    })
  }

  viewData(id:any) {
    sessionStorage?.setItem('accId', id)
    this.bsModalRef = this.modalService?.show(SearchformModalComponent, {
      class: 'modal-md modal-dialog-centered',
    });
  }

  getFavouriteList() {
    this.getFavouriteSub?.unsubscribe()
    this.getFavouriteSub = this.commonService?.favourites('Get')?.subscribe(data => {
      if(data) {
        this.favouritesList = data?.data;
        this.backupArr = [...this.favouritesList]
      }
    })
  }

  searchHotels() {
    let newArr = [...this.backupArr]
    if (this.searchTerm) {
      this.favouritesList = newArr?.filter(hotel =>
        hotel?.title?.toLowerCase()?.includes(this.searchTerm?.toLowerCase()) ||
        hotel?.address?.toLowerCase()?.includes(this.searchTerm?.toLowerCase())
      );
      // this.favouritesList = newArr;
    } else {
      this.favouritesList = [...this.backupArr];
    }
  }

  ngOnDestroy(): void {
    this.deleteFavouriteSub?.unsubscribe()
    this.getFavouriteSub?.unsubscribe()
  }

}
