<ng-template #successModal>
    <div class="content" *ngIf="success">
        <div class="check-icon">
            <img src="/assets/images/check2.svg" alt="" />
        </div>
        <div class="center-grid">
            <h4 class="type">Payment Successful!</h4>
            <!-- <p class="message-type">
        Your hotel was successfully booked on {{ "25 Feb 2024" }}.
      </p> -->
            <!-- <p class="message-type">We are sending you a confirmation email</p> -->
            <p *ngIf="bookingRefId" class="message-type">Sit back and relax, while we update the payment status of your booking!</p>
            <p *ngIf="!bookingRefId" class="message-type">Sit back and relax, while we confirm the booking for you!</p>
            <p *ngIf="!bookingRefId" class="message-type">Please wait, while we confirm the booking for you!</p>
        </div>
        <div *ngIf="loader">
            <app-loader></app-loader>
        </div>
    </div>
</ng-template>

<ng-template #transferModal>
    <div class="whole-modal">
        <div class="success-header">
            <div class="d-flex justify-content-between">
                <img class="rateloc-logo" src="/assets/images/logo.svg" alt="">
            </div>
            <p class="ref-no">Booking ref:  <span>M1806954</span></p>
          </div>
          
          <div class="transfer-summary">
           <div class="guest-data">
            <h3>1 X Private transfer (1-3 people)</h3>
            <p>Please print this e-Ticket, and present this to our representative or driver.</p>
           </div>
          
            <div class="details-section">
              <div class="details-info">
                <h4><i class="fa-solid fa-user"></i> Primary Passenger</h4>
                <div>
                    <p class="key">Name</p>
                    <p class="value">Mrs Parveen Khan</p>
                </div>
               <div>
                <p class="key">Who is traveling</p>
                <p class="value">3 Adult(s)</p>
               </div>
              </div>
          
              <div class="details-info">
                <h4><i class="fa-solid fa-car-side"></i> Vehicle</h4>
                <div>
                    <p class="key">Vehicle type</p>
                    <p class="value">1 X Private transfer (1-3 people) <span>(Pick-up)</span></p>
                    <p class="value">1 X Private transfer (1-6 people) <span>(Return Pick-up)</span></p>
                </div>
              </div>
            </div>
          
            <div class="journey-section">
              <div class="journey">
                <h4><i class="fa-solid fa-location-dot"></i> Journey 1</h4>
              <div class="d-flex">
                <div class="info-class">
                    <p>From</p>
                    <p>To</p>
                    <p>Date</p>
                    <p>Flight number</p>
                    <p>Airline name</p>
                    <p>Accommodation</p>
                    <p>Luggage Allowance</p>
                </div>
                <div class="data">
                    <p>Bodrum (Milas-Bodrum Airport) (BJV)</p>
                    <p>Torba, Samara Hotel, Bodrum/Mugla, Türkiye</p>
                    <p>Oct 17, 2024 at 20:05</p>
                    <p>2559</p>
                    <p>U2</p> 
                    <p>Torba, Samara Hotel, Bodrum/Mugla, Türkiye, 2</p> 
                    <p>3 suitcases and 3 hold all per vehicle</p> 
                </div>
              </div>
              </div>
          
              <div class="journey">
                <h4><i class="fa-solid fa-location-dot"></i> Journey 2</h4>
              <div class="d-flex">
                <div class="info-class">
                    <p>From</p>
                    <p>To</p>
                    <p>Date</p>
                    <p>Flight number</p>
                    <p>Airline name</p>
                    <p>Accommodation</p>
                    <p>Luggage Allowance</p>
                </div>
                <div class="data">
                    <p>Torba, Samara Hotel, Bodrum/Mugla, Türkiye</p>
                    <p>Bodrum (Milas-Bodrum Airport) (BJV)</p>
                    <p>Oct 27, 2024 at 14:05</p>
                    <p>3004</p>
                    <p>U7</p> 
                    <p>Torba, Samara Hotel, Bodrum/Mugla, Türkiye, 2</p> 
                    <p>3 suitcases and 3 hold all per vehicle</p> 
                </div>
              </div>
              </div>
            </div>
          </div>
    </div>
    
    </ng-template>
    
 
<div class="modal-overlay" *ngIf="showReceiptModal">
    <div class="close-btn" (click)="closeReceiptModal()">&times;</div>
    <div class="modal-content">
        <div class="container font-poppins mt-4" *ngIf="receipt">
            <div class="ccontent">
                <div class="check-fullicon">
                    <img src="/assets/images/check.png" alt="" />
                  </div>
                <div class="center-grid">
                    <h4 class="text-xl font-bold m-3 text-center">Thank you for booking the Hotel with us.</h4>
                    <span class="d-block text-center">Your registration number is <span class="font-bold">{{bookingData?.bookingRefId}}</span></span>
                    <!-- <span class="d-block text-center">Your Suppliers ref no. is <span class="font-bold">{{bookingData?.supplierRefId}}</span></span> -->
                </div>
            </div>
 
            <div class="border pt-8 pb-4 pl-8 pr-8 mt-4">
                <div class="d-flex justify-content-between">
                    <h4 class="text-xl font-bold text-left">{{bookingDetails?.title}}</h4>
                    <!-- <p class=""> <span
                        class=" fw-bold  ">Supplier
                        reference: {{bookingData?.supplierRefId}}</span>
                </p> -->
                </div>
                <span class="text-left">{{bookingDetails?.address}}</span>
                <hr>
                <div class="row">
                    <div class="col-md-7">
                        <div>
                            <span class="text-lg font-bold">Check-in</span>
                            <span class="pl-20">{{bookingDetails?.checkInDate}}</span>
                        </div>
                        <div>
                            <span class="text-lg font-bold">Check-out</span>
                            <span class="pl-16">{{bookingDetails?.checkOutDate}}</span>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div>
                            <span class="text-lg font-bold">Cancellation date:</span>
                            <span class="pl-8">{{bookingDetails?.deadline}}</span>
                        </div>
                        <div>
                            <span class="text-lg font-bold">Property type:</span>
                            <span class="pl-20">{{bookingDetails.propertyType}}</span>
                        </div>
                    </div>
                </div>
                <hr>
                <ng-container *ngIf="bookingData?.isCart === false">
                    
                <div *ngFor="let room of bookingDetails?.rooms" class="row">
                    <div class="col-md-7">
                        <h6 class="fw-bold mb-3 ">{{room?.roomName}}</h6>
                        <strong>Guests : </strong>  
                        <small>
                            <div *ngFor="let guest of room?.guestName">
                             {{guest?.title}} {{ guest?.firstName }} {{ guest?.lastName }}
                             <span *ngIf="guest.type === 'children'">{{guest?.age}}</span>
                            </div>
                          </small>
                    </div>
                    <div class="col-md-5">
                        <p class="fw-bold mb-0">Meal Type</p>
                        <p class="text-sm mt-3">{{room?.boardBase}}</p>
                    </div>
                    <!-- <hr> -->
                </div>
                </ng-container>
            <ng-container *ngIf="bookingData?.isCart === true">
                <div *ngFor="let room of bookingDetails?.rooms; index as roomIndex" class="row">
                    <ng-container *ngFor="let p of room.paxes; index as paxIndex">
                      <div class="col-md-7">
                          <h6 class="fw-bold mb-3" *ngIf="paxIndex === 0">{{room?.roomInformation.roomName}}</h6>
                          <strong>Guests : </strong> <small>{{p?.title}} {{p.fname}} {{p?.lname}} <span *ngIf="p.type ==='children'">({{p.childAges+'year'}})</span></small>
                      </div>
                      <div class="col-md-5">
                          <div *ngIf="paxIndex === 0">
                              <p class="fw-bold mb-0">Meal Type</p>
                              <p class="text-sm mt-3">{{room?.roomInformation.boardBase}}</p>
                          </div>
                          <div>
                              
                          </div>
                         
                      </div>
                    </ng-container>
                      <hr>
                  </div>
            </ng-container>
                <strong *ngIf="bookingDetails?.hotelContact">Hotel Phone number : </strong> <small>{{bookingDetails?.hotelContact}}</small>
            </div>
 
            <span class="d-block text-center mt-4">*We will send you the email with confirmation status shortly</span>
 
            <!-- <div class="reservationDetail">
                <table>
                    <tbody class="mb-3">
                        <tr>
                            <td rowspan="2">
                                <div class="d-flex ">
                                    <div class="ml-2">
                                        <img [src]="bookingDetails?.image" alt="hotelImg" title="hotelImg">
                                    </div>
                                    <div class="ml-4">
                                        <h4>{{bookingDetails?.title}}</h4>
                                        <p>{{bookingDetails?.address}}</p>
                                    </div>
                                </div>
                            </td>
                            <td class="p-1 pr-2">
                                <p class="fw-bold mb-0">Check-in</p>
                                <p class="fw-bold mb-0">{{bookingDetails?.checkInDate}}, from</p>
                                <p class="fw-bold mb-0">14.00.00</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-1 pr-2">
                                <p class="fw-bold mb-0">Check-out</p>
                                <p class="fw-bold mb-0">{{bookingDetails?.checkOutDate}}, from</p>
                                <p class="fw-bold mb-0">14.00.00</p>
                            </td>
 
                        </tr>
                    </tbody>
                </table>
            </div>
 
            <div class="row mt-5" *ngFor="let x of bookingDetails?.rooms">
                <div class="col-md-7 mb-3">
                    <h3 class="fw-bold mb-0 ">{{x?.roomName}}</h3>
                    <strong>Guest : </strong> <small>{{x?.guestName}}</small>
                </div>
                <div class="col-md-5 mb-3">
                    <p class="fw-bold mb-0">Meal Type</p>
                    <p class="text-sm">{{x?.mealType}}</p>
                </div>
                <hr>
            </div>
            <div class="row mt-2">
                <div class="col-md-7">
                    <p class="fw-bold">Important please note</p>
                    <p class="text-sm">{{bookingDetails?.importantNote}}</p>
 
                </div>
                <div class="col-md-5">
                    <p class="fw-bold mb-0">Cancellation policy </p>
                    <p *ngFor="let x of bookingDetails?.cancellationPolicy" class="text-sm">If you cancel this booking
                        from {{x?.FromDate}}, cancellation charges upto {{x?.CancellationCharge}} % would be apply.</p>
                </div>
            </div> -->
        </div>
    </div>
</div>
