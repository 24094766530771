<div>
  <app-header [logo]="image?.ratelocLogo" [section1]="'Home'" [section2]="'About'" [section3]="'Contact'"></app-header>

  <div class="aboutHeading">
    <div class="image-layer"></div>
    <h1 class="text-7xl md:pb-10 pt-36 text-center font-poppins">About Us</h1>
  </div>

  <div class="main-box pt-6 md:pt-2 font-poppins">
    <div class="font-poppins md:pt-4 text-center md:px-40 px-20">
      <!-- <h1 class="text-5xl md:pb-10">About Us</h1>
      <hr class="md:pt-4 md:mx-96"> -->
      <h4 class="text-black pt-4">
        We are an innovative B2B hotel and travel provider, dedicated to simplifying the buying and selling of Hotels &
        ground travel services.
      </h4>
      <p class="text-gray-800 text-center pt-4">
        At RateLoc, we take pride in our over 15 years of experience in the travel industry and our dedicated sales
        team. Our commitment to building a trusted relationship with all of our clients has allowed us to grow and
        maintain a loyal customer base.
      </p>
      <p class="text-gray-800 text-center pt-4">
        Our global marketplace offers powerful distribution solutions that enable travel professionals to easily access
        a wide range of travel services, including hotels and ground transportation. Our commitment to innovation and
        customer service has earned us a reputation as a trusted partner in the travel trade. With our cutting-edge
        technology and extensive network of partners, we offer a seamless experience that allows travel professionals to
        focus on what they do best - providing exceptional service to their clients.
      </p>
      <p class="text-gray-800 text-center pt-4">
        Our multi-lingual team ensures that we can cater to a diverse range of customers. We are constantly working to
        improve rates for our customers and aim to offer the best service and rates possible. With our proven track
        record and commitment to customer satisfaction, why book anywhere else? Choose us for a worry-free travel
        experience.
      </p>
    </div>
  </div>

  <app-chooseUs></app-chooseUs>

  <app-footer></app-footer>
</div>