import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HomeAuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    // Check if the session storage flag is set
    const isLoggedIn = sessionStorage?.getItem('loggedIn') === 'true';

    if (isLoggedIn) {
      // If logged in, navigate to Dashboard
      this.router?.navigate(['/Dashboard']);
      return false; // Return false to prevent access to home route
    } else {
      return true; // Allow access to home route
    }
  }
}
