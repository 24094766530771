<router-outlet></router-outlet>
    <div class="check-class">
      <ng-template #inactiveModal>
        <!-- <div class="modal-header logout-modal-title">
            <h4 id="dialog-static-name" class="modal-title">Alert!!!</h4>
            <br>
          </div> -->
         <div class="col-md-12 d-flex ">
          <div class="col-md-2 p-0 ">
            <i class="fa fa-exclamation-circle"></i>
        </div>
        <div class="col-md-10 text-center modal-body">
            <div class="logout-message">
                <p>You have been logged out due to inactivity. Refresh or return to the sign in screen</p>         
              </div>
        </div>
         </div>
      </ng-template>
    </div>