<div class="crossButton">
  <button type="button" class="close " aria-label="Close" (click)="dis()">
      <span aria-hidden="true" class="larger-cross">
          <i class="fa fa-times"></i> <!-- Font Awesome cross icon -->
      </span>
  </button>
</div>
<ng-container *ngIf="((statusCode == 1000 || DATA?.supplier == '111' || DATA?.supplier == '222' || DATA?.supplier == '333' || DATA?.supplier == '444'))">
  <div class="d-flex">
    <div class="w-75">
        <div class="cursor-pointer rounded-xl bg-white p-2 my-1 shadow-md mx-3 itemRef">
        <div class="flex items-start">
          <div class="col-sm-12 d-flex ">
            <div class="col-sm-3 mr-4">
              <div class="img-size position-relative">
              <img [src]="selectedCartHotel?.images" alt="wallpaper" class="img-size object-cover rounded-xl" />
              <div class="heart-icon">
                <img [ngClass]="{'pink-heart': selectedCartHotel?.isFavourite}" [src]="selectedCartHotel?.isFavourite ? '/assets/icons/favourite_rateloc-red.svg' : '/assets/icons/favourite_rateloc.svg'"  alt="">
              </div>
            </div>
            </div>
            <div class="col-sm-6">
              <div class="flex flex-col">
                <p class="text-slate-700 font-semibold title-text text-lg mb-1" matTooltip="{{selectedCartHotel?.title}}"
                  matTooltipPosition="below">{{selectedCartHotel?.title}}</p>
                <p class="text-xs p-elipsis text-slate-400 mb-2" matTooltip="{{selectedCartHotel?.address}}" matTooltipPosition="below">
                  {{selectedCartHotel?.address}}</p>
                <div class="flex items-center mb-2">
                  <span class="ml-1 text-sm text-slate-400">
                    <!-- <span *ngFor="let star of getStarsArray(getNumericRating(selectedCartHotel?.rating)!)">
                      <i class="fa fa-star text-yellow-400 p-0.5 fa-1.5selectedCartHotel" aria-hidden="true"></i>
                    </span> -->
                <span class="badge listIcon" *ngIf="selectedCartHotel?.isRefundable && selectedCartHotel?.paymentType == 'Refundable' && !isRefundableDate">
                  <img src="/assets/icons/check-icon.svg" alt="">
                  <span class="freeClass">  Free cancellation</span>
                </span>
                <span class="badge listIcon" *ngIf="selectedCartHotel?.isRefundable && selectedCartHotel?.paymentType !== 'Refundable' && !isRefundableDate">
                  <img src="/assets/icons/check-icon.svg" alt="">
                  <span class="freeClass">  Free cancellation is available</span>
                </span>
                <span class="" *ngIf="selectedCartHotel?.isRefundable == false && selectedCartHotel?.paymentType !== 'Refundable' && !isRefundableDate">
                  <!-- <img src="/assets/icons/check-icon.svg" alt=""> -->
                  <span class="cancelClass">No free cancellation available</span>
                </span>
                <span class="" *ngIf="isRefundableDate">
                  <!-- <img src="/assets/icons/check-icon.svg" alt=""> -->
                  <span class="cancelClass">No free cancellation available</span>
                </span>
                <!-- <span style="color: #F4C01E; font-size: 12pselectedCartHotel; display: block; margin-top: 2pselectedCartHotel;" *ngIf="selectedCartHotel?.isRefundable && selectedCartHotel?.paymentType == 'Non-Refundable'">Refundable Rooms Available</span> -->
                    <!-- <strong *ngIf="selectedCartHotel?.rating === 'FiveStars'">5</strong>
                    <strong *ngIf="selectedCartHotel?.rating === 'FourStars'">4</strong>
                    <strong *ngIf="selectedCartHotel?.rating === 'ThreeStars'">3</strong>
                    <strong *ngIf="selectedCartHotel?.rating === 'TwoStars'">2</strong>
                    <strong *ngIf="selectedCartHotel?.rating === 'OneStars'">1</strong> -->
                  </span>
                </div>

                <div class="flex items-center">
                  <span class="ml-1 text-sm text-slate-400"> {{selectedCartHotel?.roomName}}</span>
                </div>
                <div class="flex items-center mt-2">
                  <!-- <span class="text-sm font-bold text-orange-500">$ {{selectedCartHotel?.price?.amount}}</span> -->
                  <span class="ml-1 text-sm text-slate-400">{{request?.checkInDate}} - {{request?.nights}} Nights</span>
                </div>


                <div class="flex items-center mt-3">
                   <i class="fa fa-bed" aria-hidden="true"></i>
                  <span class="ml-1">{{selectedCartHotel.boardBase}}</span> 
                </div>
                <!-- <div class="mt-1 ">
                  <button type="button" class="btn btn-outline-primary show-desc-btn" (click)="showHotelDesc(true)">Show Hotel Description</button>
                </div> -->
              </div>
            </div>
            <div class="col-sm-3 showDetailsBtn ">
              <div>


                <!-- <span class="badge" style="background-color: #485566;">{{selectedCartHotel?.paymentType}}</span> -->
                <span *ngFor="let star of getStarsArray(getNumericRating(selectedCartHotel?.rating)!)">
                  <i class="fa fa-star text-yellow-400 p-0.5 fa-1.5x" aria-hidden="true"></i>
                </span>
                <div class="nightPriceBox" [ngStyle]="{'visibility' :nightPrice ? 'visible' : 'hidden'}">
                  <p class="p-0 m-0">Price per night</p>
                  <p class="p-0 m-0">net price for one night</p>
                  <strong>{{(["222","444", "666"].includes(selectedCartHotel?.supplier)? selectedCartHotel?.price?.perNightPrice*ratehawkApiCurrencyValue: selectedCartHotel?.price?.perNightPrice*apiCurrencyValue) | currency: currencyCode }}</strong>
                </div>
              </div>
              <!-- <div>{{selectedCartHotel?.price?.perNightPrice}} API CURRENCY {{apiCurrencyValue}}</div> -->
              <div class="col-12 showDetailsBtn-container">
                from <p class=" font-bold text-orange-500 priceShowBtn pb-0 mb-0" > {{(["222","444", "666"].includes(selectedCartHotel?.supplier)? selectedCartHotel?.price?.totalPrice*ratehawkApiCurrencyValue: selectedCartHotel?.price?.totalPrice*apiCurrencyValue) | currency:
                  currencyCode }} <span (mouseover)="nightPrice=true" (mouseleave)="nightPrice=false"><i
                      class="fa fa-info-circle" aria-hidden="true" style="color: #F4C01E;"></i>
                  </span></p>
                 <!-- <p class="p-0 m-0"> <button class="btn viewPriceBtn"  *ngIf="showViewPrice && !selectedCartHotel?.filterPrice && !(cardindex == i)" (click)="viewPrice(i,selectedCartHotel?.id, selectedCartHotel?.searchId, selectedCartHotel.supplier)">View price</button> </p> -->

                <!-- <button type="button" class="btn btn-outline-primary show-desc-btn" (click)="onCardClick(selectedCartHotel?.id, selectedCartHotel?.searchId)"> Show Details</button> -->
                <!-- <span class="col-12 price-info-btn" [ngClass]="{'disabled': !stopRecursiveApiCall}" (click)="showHotelDesc(i,selectedCartHotel?.id, selectedCartHotel?.searchId, selectedCartHotel.supplier)">More Prices & Boards -->
                  <!-- <i class="fa fa-light fa-angle-down arrowWarning"></i></span> -->
              </div>
            </div>
          </div>
        </div>
        <table class="role-data-table mt-2" *ngIf="selectedCartHotel?.roleData">
          <thead>
            <tr>
              <th *ngIf="selectedCartHotel?.roleData.supplier">Supplier</th>
              <th>Net Price</th>
              <th>Selling Price</th>
              <th>Margin</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td *ngIf="selectedCartHotel?.roleData.supplier">{{ selectedCartHotel?.roleData.supplier }}</td>
              <td>{{(["222","444", "666"].includes(selectedCartHotel?.supplier)? selectedCartHotel?.roleData?.netPrice*ratehawkApiCurrencyValue: selectedCartHotel?.roleData?.netPrice*apiCurrencyValue) | currency: currencyCode }}</td>
              <td>{{(["222","444", "666"].includes(selectedCartHotel?.supplier)? selectedCartHotel?.roleData?.sellingPrice*ratehawkApiCurrencyValue: selectedCartHotel?.roleData?.sellingPrice*apiCurrencyValue) | currency: currencyCode  }}</td>
              <td>{{(["222","444", "666"].includes(selectedCartHotel?.supplier)? selectedCartHotel?.roleData?.margin*ratehawkApiCurrencyValue: selectedCartHotel?.roleData?.margin*apiCurrencyValue) | currency: currencyCode }}</td>
            </tr>
          </tbody>
        </table>
        <div class="row ">
          <div class="col-sm-12" style="position: relative; z-index: 0;">
            <div *ngIf="!roomDetails">
              <app-loader></app-loader>
            </div>
              <ng-container *ngFor="let ele of roomDetails index as i">
                <div  class="table-container">
                    <table class=" table mt-2 main-menu-table mb-3 p-0 table-hover table-bordered">
                      <!-- <thead class="mb-2 table-header" *ngIf="item.multipleRooms?.length > 0">
                        <tr>
                          <th class="">Room</th>
                          <th class="">Meals</th>
                          <th class="">Cancellation</th>
                          <th class="">Price</th>
                          <th class="text-center">Book Rooms</th>
                        </tr>
                      </thead> -->
                      <thead (click)="assignIndex(i)">
                        <tr><th colspan="5" class="text-center white">Room {{i+1}} - {{ele.criteria.adults}} Adults, <span *ngIf="ele.criteria.children">{{ele.criteria.children}} Children</span> <i *ngIf="!roomsArray[i]" class="fa fa-angle-down"></i><i *ngIf="roomsArray[i]" class="fa fa-angle-up"></i></th></tr>
                        <tr *ngIf="roomsArray[i]" class="non-white">
                          <th class="text-center">Room</th>
                          <th class="text-center">Meals</th>
                          <th class="text-center">Cancellation</th>
                          <th class="text-center">Price</th>
                          <th class="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="roomsArray[i]">
                        <tr *ngFor="let room of ele?.multipleRooms index as k">
                          <td >
                            <div class="d-flex align-items-center" #inputElement>{{room?.roomInformation?.roomName}}
                              <button (click)="copyText (inputElement.textContent)"><i class="fa-regular fa-copy text-base"></i></button>
                            </div>
                            
                            <!-- <ng-container *ngIf="showAllRooms == room.bookingId" >
                              <div class="mb-2"*ngFor="let roomName of room.roomInformation.roomName index as l">{{roomName}}
                                <div *ngIf="l < room.roomInformation?.roomName.length - 1" class="plus-icon m-2"><i class="fa fa-solid fa-plus"></i></div>
                              </div>
                            </ng-container>
                            <div *ngIf="(!showAllRooms || showAllRooms != room.bookingId) && room.roomInformation?.roomName.length > 1" (click)="showAllRooms = room.bookingId" class=""><i class="angle-icon fa fa-light fa-angle-down arrowWarning"></i></div>
                            <div *ngIf="showAllRooms == room.bookingId && room.roomInformation?.roomName.length > 1 " (click)="showAllRooms = ''" class=""><i class="angle-icon fa fa-light fa-angle-up arrowWarning"></i></div> -->
                          </td>
                          <td>{{room?.roomInformation?.boardBase}}</td>
                          <td>{{isRefundableDate?'Non-Refundable':room?.roomInformation?.paymentType}}
                            <div *ngIf="room?.roomInformation?.paymentType === 'Refundable' && !isRefundableDate" class="refundableDiv">Cancellation till {{room?.deadline}}</div>
                          </td>
                          <td>{{(["222","444", "666"].includes(selectedCartHotel?.supplier)? room?.price?.totalPrice*ratehawkApiCurrencyValue: room?.price?.totalPrice*apiCurrencyValue) | currency:currencyCode}}<span>{{currencyCode}}</span>
                          </td>
                          <td class="promotion-container text-center">
                            <div class="bookingDiv">
                              <button style="color: grey; cursor: pointer;"
                              (click)="addToCart(room, k, i)"
                              [ngClass]="{disabled: ele.addedToCart ? room?.addedToCart ? false : true : false}" [ngStyle]="{color: room?.addedToCart ? '#a2c060' : '#ec6408'}"  class="">{{room?.addedToCart ? "In the cart" : "Add to cart"}}</button>
                              <i
                              class=" fa fa-info-circle" (mouseover)="promotionindex = room?.bookingId" (mouseleave)="promotionindex = ''"  *ngIf="room?.roomPromotion" aria-hidden="true" style="color: #F4C01E;"></i>
                              <div class="promotion" [ngStyle]="{'visibility' :promotionindex == room?.bookingId ? 'visible' : 'hidden'}">
                                <p class="p-0 m-0">{{room?.roomPromotion}}</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </ng-container>
          </div>
        </div>

        <!-- <div class="row" *ngIf="isDescVisible[i]">
          <div class="col-sm-11  grid-closing-container">
            <button class="grid-closing-btn"><i
                class="fa fa-light fa-angle-up text-end "></i></button>
          </div>
          <div class="col-sm-1"></div>
        </div> -->



      </div>
    </div>
    <div class="w-25">
        <div *ngIf="cartArray.length > 0" class="h-100 p-2">
            <div class="cart-head">Selected Rooms</div>
            <div class="h-100 cart-container">
                <ng-container *ngFor="let item of cartArray index as i">
                  <div class="room-head mt-3"><span class="room-head-1">Room {{i+1}} </span>{{'('+item.criteria.adults}} Adt{{item.criteria.children ? ',' + item.criteria.children+' ch)' : ')'}}</div>
                    <div class="cart-value-container pt-1">
                        <span (click)="removeFromCart(i)" aria-hidden="true" class="cross">
                            <i class="fa fa-times"></i> <!-- Font Awesome cross icon -->
                        </span>
                        <div class="cart-value">{{item.roomInformation.roomName}}</div>
                        <div class="cart-value">{{item.roomInformation.boardBase}}</div>
                        <div class="cart-value">{{item.roomInformation.paymentType}}</div>
                        <div class="cart-value">{{(["222","444", "666"].includes(selectedCartHotel?.supplier)? item.price.totalPrice*ratehawkApiCurrencyValue : item.price.totalPrice*apiCurrencyValue) | currency : currencyCode}} <span class="fw-bold">{{currencyCode}}</span></div>
                    </div>
                </ng-container>
                
            </div>
        </div>
        <div *ngIf="cartArray.length < 1" class="h-100 p-2 d-flex justify-content-center align-items-center">
            <div class="fw-bold">Cart is Empty!</div>
        </div>
    </div>
 
    
</div>
<!-- <div class="total-cart-price m-2 py-3">
  <div class="fw-bold">Total Price</div>
   
</div> -->
<div class="submit-button-container d-flex justify-content-end">
  <div class="submit-width p-2">
    <div *ngIf="cartArray.length > 0" class="green-color total-cart-price my-2 text-end">{{(["222","444", "666"].includes(DATA?.supplier)? totalCartPrice*ratehawkApiCurrencyValue : totalCartPrice*apiCurrencyValue) | currency : currencyCode}} <span class="fw-bold">{{currencyCode}}</span></div>
    <button class="submit-button" [ngClass]="{disabled: cartArray.length == roomDetails.length ? false : true}" type="submit" (click)="onBooking()">Proceed</button>
  </div>
</div>
</ng-container>


<div *ngIf="isApiCalled && statusCode == 1100" class="container font-poppins py-10">
  <div class="col-sm-12">
      <div class="col-sm-12 col-sm-offset-1 text-center">
        <div class="bg-img">
          <h1 class="text-center">404</h1>
        </div>
        <div class="content">
          <h3 class="h2">No hotels Found!</h3>
          <p>are you sure you want to be here?</p>
          <a (click)="dis()" class="link">Go back to Listing</a>
        </div>
      </div>
    </div>
</div>

<div *ngIf="isApiCalled && statusCode == 1200" class="container font-poppins py-10">
  <div class="col-sm-12">
      <div class="col-sm-12 col-sm-offset-1 text-center">
        <div class="bg-img">
          <h1 class="text-center">404</h1>
        </div>
        <div class="content">
          <h3 class="h2">Oops something went wrong</h3>
          <p>are you sure you want to be here?</p>
          <a (click)="dis()" class="link">Go back to Listing</a>
        </div>
      </div>
    </div>
</div>