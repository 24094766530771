<!-- <div class="travel-booking-form-container">
  <mat-card class="travel-booking-card">
    <mat-card-content>
      <form>
        <div class="form-field-container px-4">
          <h2 class="form-field-heading orange-color">Location :</h2>
          <mat-form-field appearance="outline" class="w-100">
            <mat-icon matPrefix fontIcon="room" class="orange-color"></mat-icon>
            <input type="text" matInput placeholder="Search" [formControl]="searchControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                <mat-icon matPrefix fontIcon="room" class="orange-color"></mat-icon>
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="form-field-container row px-4">
          <h2 class="form-field-heading orange-color">Date :</h2>

          <mat-form-field appearance="outline" class="w-100">
            <mat-date-range-input [rangePicker]="picker1"> 
                <input matStartDate placeholder="Check in" readonly> 
                <input matEndDate placeholder="Check out" readonly> 
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker1"> 
              </mat-datepicker-toggle> 
            <mat-date-range-picker #picker1></mat-date-range-picker> 
        </mat-form-field> 
        </div>

        <div class="form-field-container row px-4">
          <h2 class="form-field-heading orange-color">Passengers :</h2>

          <div class="col-md-4">

            <mat-form-field appearance="outline" class="w-100">
              <mat-select placeholder="Rooms">
                <mat-option value="1">1 Room</mat-option>
                <mat-option value="2">2 Room</mat-option>
                <mat-option value="3">3 Room</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100">
              <mat-select placeholder="Adults">
                <mat-option value="1">1 Adult</mat-option>
                <mat-option value="2">2 Adult</mat-option>
                <mat-option value="3">3 Adult</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-4">

            <mat-form-field appearance="outline" class="w-100">
              <mat-select placeholder="Children">
                <mat-option value="0">0 Children</mat-option>
                <mat-option value="1">1 Children</mat-option>
                <mat-option value="2">2 Children</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>
        <div class="form-field-container px-4">
          <h2 class="form-field-heading orange-color">Board Base :</h2>
          <mat-form-field appearance="outline" class="w-100">
            <mat-select placeholder="Any">
              <mat-option value="option1">Bed and Breakfast</mat-option>
              <mat-option value="option2">Room only</mat-option>
              Add additional options as needed
            </mat-select>
          </mat-form-field>
        </div>
        <div class="px-4">
          <button class="main-button p-2 mt-2 w-100 mb-3" (click)="search()">Search</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div> -->

<div class="wrapper">
  <div class="container comingSoon">
    <h1>Coming soon<span class="dot orange-color">.</span></h1>
  </div>
</div>
