import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/core/services/config.service';
import { DataTransferServiceService } from 'src/app/core/services/data-transfer-service.service';
import * as nationalData from '../../../../assets/national.json';
import { Observable, map, startWith } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { CommonService } from 'src/app/core/services/common.service';
import { getTime } from 'ngx-bootstrap/chronos/utils/date-getters';
import { ToastService } from 'src/app/core/services/toast.service';


@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent {
  // national: any[] = nationalData?.national;
  filteredNationalities: Observable<any[]> = new Observable<any[]>();
  nationalityControl = new FormControl();
  image: any;
  currentPage: string = 'personal';
  // name: any;
  email: any;
  creditLimit: any;
  totalPriceUnpaid: any;
  nationality: any;
  userdata: any;
  updatedName: any;
  // originalName: any;
  // editing: boolean = false;
  firstName: any;
  updatedNationality: any;
  editingNationality: boolean = false;
  nationalities!: { countryName: string; countryCode: string; }[] | undefined;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger!: MatAutocompleteTrigger;
// LastName: any;
  editingFirstName: boolean = false;
  updatedFirstName: any;
  editingLastName: boolean = false;
  updatedLastName: any;
  lastName: any;
phoneNumber: any;
  editingPhoneNumber: boolean = false;
  updatedPhoneNumber: any;
selectedCountryCode: any;
  userId: any;
  totalOrdersCount: any;
  confirmedOrdersCount: any;
  unpaidOrdersCount: any;
  totalPrice: any;
  apiCurrencyValue: any;
  currencyCode: any;
  ratehawkApiCurrencyValue: any;
// selectedCountryCode: any; // Default country code

  constructor(
    private configService: ConfigService,
    private router: Router,
    private dataTransferService: DataTransferServiceService,
    private commonService: CommonService,
    private toastService: ToastService,
  ) {
    this.getCurrency()
  }

  ngOnInit() {
    this.image = this.configService?.getImages();
    // this.name = sessionStorage?.getItem('name');
    this.firstName = sessionStorage?.getItem('firstName');
    this.lastName = sessionStorage?.getItem('lastName');
    this.email = sessionStorage?.getItem('email');
    this.phoneNumber = sessionStorage?.getItem('phoneNumber');
    this.userId = sessionStorage?.getItem('userId');
    this.nationality = sessionStorage?.getItem('nationality');
    this.selectedCountryCode = sessionStorage.getItem('selectedCountryCode'); // Default to UAE if not found in sessionStorage
    // const storedData = sessionStorage?.getItem('userData');
    // if (storedData) {
    //   this.userdata = JSON?.parse(storedData);
    //   this.nationality = this.userdata['nationality'];
    // }

    let obj: any = this.dataTransferService?.getBookingHistoryData()
    if(obj){
      this.creditLimit = Math?.round(obj?.remainingCredit);
      this.totalPriceUnpaid = Math?.round(obj?.totalPriceUnpaid);
      this.totalOrdersCount = obj?.totalOrdersCount;
      this.confirmedOrdersCount = obj?.confirmedOrdersCount;
      this.totalPrice = Math?.round(obj?.totalPrice);
      this.unpaidOrdersCount = obj?.unpaidOrdersCount;
    }

    this.updatedFirstName = this.firstName;
    this.updatedLastName = this.lastName;
    this.updatedPhoneNumber = this.phoneNumber;
    // this.loadNationalities();
    // this.filteredNationalities = this.nationalityControl?.valueChanges?.pipe(
    //   startWith(''),
    //   map((value) => this._filter(value))
    // );

  }

  getCurrency() {
    const storedCurrency = sessionStorage?.getItem('currency');
    if (storedCurrency) {
      let currencyData = JSON?.parse(storedCurrency);
      this.currencyCode = currencyData?.code;
    }
    this.commonService
    .updateCurrency('USD', this.currencyCode)
    .subscribe((data: any) => {
      console.log(data);
      this.apiCurrencyValue = data.rates[this.currencyCode]
      // sessionStorage.setItem('currencyApi', data.rates[this.currencyCode]);
    });
  this.commonService
    .updateCurrency('GBP', this.currencyCode)
    .subscribe((data: any) => {
      console.log(data);
      this.ratehawkApiCurrencyValue = data.rates[this.currencyCode]
      // sessionStorage.setItem('ratehawkCurrencyApi', data.rates[this.currencyCode]);
    });
    // this.apiCurrencyValue = sessionStorage.getItem('currencyApi');
    // this.ratehawkApiCurrencyValue = sessionStorage.getItem(
    //   'ratehawkCurrencyApi'
    // )
    // this.cd.detectChanges();
    // this.cd.markForCheck();
  }


  onClick(type: string) {
    this.currentPage = type;
  }

  updateDetails(obj: any) {
    this.commonService?.updateUserDetails(obj).subscribe(
      (response) => {
        // Handle success
        console.log('User details updated successfully:', response);
      },
      (error) => {
        // Handle error
        console.error('Error updating user details:', error);
      }
    );
  }

  toggleEdit(field: string) {
    if (field === 'firstName') {
      this.editingFirstName = !this.editingFirstName;
      if (!this.editingFirstName) {
        this.updatedFirstName = this.firstName;
      }
    } else if (field === 'lastName') {
      this.editingLastName = !this.editingLastName;
      if (!this.editingLastName) {
        this.updatedLastName = this.lastName;
      }
    }
  }

  cancelEdit(field: string) {
    if (field === 'firstName') {
      this.updatedFirstName = this.firstName;
      this.editingFirstName = false;
    } else if (field === 'lastName') {
      this.updatedLastName = this.lastName;
      this.editingLastName = false;
    }
  }

  updateName(field: string) {
    if (field === 'firstName') {
      this.firstName = this.updatedFirstName;
      this.editingFirstName = false;
      sessionStorage.setItem('firstName', this.firstName);
      this.toastService?.showSuccess('Your first name is updated');
    } else if (field === 'lastName') {
      this.lastName = this.updatedLastName;
      this.editingLastName = false;
      sessionStorage.setItem('lastName', this.lastName);
      this.toastService?.showSuccess('Your last name is updated');
    }
    // sessionStorage.setItem('userId', this.userId)
     // Call updateDetails method after updating the name
     this.updateDetails({
      firstName: this.updatedFirstName,
      lastName: this.updatedLastName,
      // phoneNumber: this.phoneNumber,
    });
  }

  // toggleEditNationality(): void {
  //   this.editingNationality = true;
  //   this.nationalityControl?.setValue(this.nationality); // Set the current nationality as the initial value

  //   // Use setTimeout to ensure the view has updated before opening the panel
  //   setTimeout(() => {
  //     this.autocompleteTrigger?.openPanel();
  //   });
  // }


  // onSelectNationality(): void {
  //   this.updatedNationality = this.nationalityControl?.value;
  //   this.nationality = this.updatedNationality;
  //   this.editingNationality = false;
    
  //   if (this.userdata) {
  //     this.userdata['nationality'] = this.updatedNationality;
  //     sessionStorage?.setItem('userData', JSON?.stringify(this.userdata));
  //   } else{
  //       let userData = {
  //         nationality: this.nationality
  //       }
  //     sessionStorage?.setItem('userData', JSON?.stringify(userData));
  //   }
  // }

  phoneNumberFormats: { [key: string]: RegExp } = {
    "+971": /^(\+971)?[0-9]{7,9}$/, // UAE
    "+91": /^(\+91)?[0-9]{10}$/,     // India
    "+44": /^(\+44)?[0-9]{10,11}$/   // UK
    // Add more formats for other countries as needed
};


  toggleEditPhoneNumber() {
    this.editingPhoneNumber = !this.editingPhoneNumber;
    if (!this.editingPhoneNumber) {
      this.updatedPhoneNumber = this.phoneNumber;
    }
  }

  cancelEditPhoneNumber() {
    this.updatedPhoneNumber = this.phoneNumber;
    this.editingPhoneNumber = false;
  }

  savePhoneNumber() {
    this.phoneNumber = this.updatedPhoneNumber;
    this.editingPhoneNumber = false;
    sessionStorage.setItem('phoneNumber', this.phoneNumber); 
    this.toastService?.showSuccess('Your phone number is updated');
    // sessionStorage.setItem('userId', this.userId)
    // sessionStorage.setItem('selectedCountryCode', this.selectedCountryCode); 
     // Call updateDetails method after updating the name
     this.updateDetails({
      // firstName: this.updatedFirstName,
      // lastName: this.updatedLastName,
      phoneNumber: this.phoneNumber,
    });
  }

//   updatePhoneNumber() {
//     const selectedFormat = this.phoneNumberFormats[this.selectedCountryCode];
//     if (selectedFormat && this.updatedPhoneNumber.match(selectedFormat)) {
//         // Phone number matches the format for the selected country code
//         this.phoneNumber = this.selectedCountryCode + this.updatedPhoneNumber.replace(/\+/g, '');
//     }
//     // sessionStorage.setItem('userId', this.userId)
//      // Call updateDetails method after updating the name
//      this.updateDetails({
//       // firstName: this.updatedFirstName,
//       // lastName: this.updatedLastName,
//       phoneNumber: this.updatePhoneNumber,
//     });
// }

  // private _filter(value: string): any[] {
  //   const filterValue = value?.toLowerCase();
  //   return this.national?.filter((option) =>
  //     (
  //       option?.countryName?.toLowerCase() +
  //       ' - ' +
  //       option?.countryCode?.toLowerCase()
  //     )?.includes(filterValue)
  //   );
  // }
}
