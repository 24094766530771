import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '../../services/common.service';
import { ToastService } from '../../services/toast.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-forgottenPassword',
  templateUrl: './forgottenPassword.component.html',
  styleUrls: ['./forgottenPassword.component.scss'],
})
export class ForgottenPasswordComponent implements OnInit, AfterViewInit, OnDestroy  {
  forgotPassword: any = FormGroup;
  otpSent: boolean = false;
  @ViewChild('otpIput1', {static:true}) otpInput1!: ElementRef<HTMLInputElement>;
  wrongOtp: boolean = false;
  disableButton: boolean = true;
  timerMessage!: any;
  forgotPasswordSub!: Subscription;
  
  constructor(
    public dialogRef: MatDialogRef<ForgottenPasswordComponent>,
    private fb: FormBuilder,
    private commonService: CommonService,
    private toastService : ToastService,
    private route:Router
  ) {
    this.forgotPassword = this.fb?.group({
      email: [
        '',
        [
          Validators?.required,
          Validators?.pattern(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/),
        ],
      ],
      otps: this.fb?.array([
        this.fb?.control(null, Validators?.required),
        this.fb?.control(null, Validators?.required),
        this.fb?.control(null, Validators?.required),
        this.fb?.control(null, Validators?.required)
      ])
    });
  }
 
  ngAfterViewInit(): void {
  }
  ngOnInit() {
    
  }

  onSend() {
    if (this.forgotPassword?.controls?.email?.valid) {
      setTimeout(() => {
      }, 1000);
      let email = this.forgotPassword?.get('email')?.value;
      this.forgotPasswordSub?.unsubscribe()
      this.forgotPasswordSub = this.commonService?.forgotPassword(email)?.subscribe((res) => {
        if (res?.data[0] == "Please check your email to set new password") {
          sessionStorage?.setItem('forgotToken', res?.message)
          this.toastService?.showSuccess('Email Sent Successfully');
          this.otpSent = true
          this.disableButton = true;
          let secondsRemaining = 120; // 2 minutes in seconds
          const timerInterval = setInterval(() => {
            secondsRemaining--;
            this.timerMessage = secondsRemaining

            if (secondsRemaining <= 0) {
              clearInterval(timerInterval);
              this.timerMessage = '';
              this.disableButton = false;
            }
          }, 1000);
        } else this.toastService?.showError(res?.data[0])
      });
    }
  }

  onInput(event: any, index: number): void {
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue?.length === 1) this.focusNext(index+1);
  }

  onKeyDown(event: KeyboardEvent, index:number) {
    const inputValue = (event.target as HTMLInputElement).value;
    if (event?.key === 'Delete' || event?.key === 'Backspace') {
      if (index > 0) {
         if (inputValue?.length === 1) this.forgotPassword?.controls?.otps?.controls[index]?.setValue('')
         else this.focusNext(index-1)
      }
  }
  }

  focusNext(index: number): void {
    if (index < this.forgotPassword?.controls?.otps?.length) {
      const nextInput = document?.getElementById(`otpInput${index}`) as HTMLInputElement;
      if (nextInput) {
        nextInput?.focus();
      }
    }
  }

  verifyAccount(): void {
    let otp = ''
    this.forgotPassword?.value?.otps?.forEach((item:any) => {
      otp += item
    })
    let userdata = sessionStorage?.getItem('forgotToken')
    if(otp == userdata) {
      this.route?.navigate(['forgot-password'])
      sessionStorage?.setItem('isForgot',"true")
      sessionStorage?.setItem('forgotEmail', this.forgotPassword?.value?.email)
      this.dialogRef?.close();
    } else {
      this.wrongOtp = true
      this.forgotPassword?.controls?.otps?.reset()
    }
  }

  ngOnDestroy() {
    this.forgotPasswordSub?.unsubscribe()
  }
  
}
