import { Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { DataTransferServiceService } from 'src/app/core/services/data-transfer-service.service';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as countries from '../../../../assets/country.json';
import { PreviousUrlService } from 'src/app/core/services/navigation.service';
import { CommonService } from 'src/app/core/services/common.service';
import { LoadingService } from 'src/app/core/services/Loading.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isDropdownVisible: boolean = false;
  dataSub!: Subscription;
  selectedCode: any = 'GBP';
  selectedCountry: any = 'United Kingdom, UK';
  modalRef!: BsModalRef;
  countries = countries;
  role!: any;
  lastName: any;
  toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }
  @Input() logo: any;
  @Input() name: any;
  isMenuOpen: boolean = false;
  @Input() section1: any;
  @Input() section2: any;
  @Input() section3: any;
  router: any;
  // name: any;
  @Input() firstName: any;
  @Input() stopRecursiveApiCall: boolean = false;
  email: any;
  hover: any = [];
  @ViewChild('currencyNotice', { static: true }) currencyNotice: any;
  @ViewChild('regionPopup', { static: true }) regionPopup: any;

  constructor(
    private authService: AuthenticationService,
    private route: Router,
    private dataTransferService: DataTransferServiceService,
    private modalService: BsModalService,
    private navigationService: PreviousUrlService,
    private commonService: CommonService,
    private loadingService: LoadingService
  ) {
    this.router = this.route?.url;
    // this.name = sessionStorage?.getItem('name')
    this.firstName = sessionStorage?.getItem('firstName');
    this.lastName = sessionStorage?.getItem('lastName');
  }

  ngOnInit() {
    const storedCurrency = sessionStorage?.getItem('currency');
    if (storedCurrency) {
      let currencyData = JSON?.parse(storedCurrency);
      this.selectedCode = currencyData?.code;
      this.selectedCountry = currencyData?.country;
    }

    this.role = sessionStorage.getItem('role');
    this.dataSub?.unsubscribe();
    this.dataSub =
      this.dataTransferService?.accountDropdownTransfer$?.subscribe((data) => {
        if (data) this.isDropdownVisible = false;
      });
    // Extracting first name from full name
    //  const firstName = sessionStorage?.getItem('firstName');
    //  if (firstName) {
    //    this.firstName = firstName?.split(' ')[0];
    //  }
    // this.name = String(sessionStorage?.getItem('name'))
    this.firstName = String(sessionStorage?.getItem('firstName'));
    this.lastName = String(sessionStorage?.getItem('lastName'));
    this.email = String(sessionStorage?.getItem('email'));
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  navigateDashboard() {
    this.route.navigate(['/'])
    this.modalRef.hide()
    this.modalRef = this.modalService?.show(this.regionPopup, {
      class: 'modal-lg modal-dialog-centered',
    });
  }

  currencyApi() {
    this.commonService
      .updateCurrency('USD', this.selectedCode)
      .subscribe((data: any) => {
        console.log(data);
        sessionStorage.setItem('currencyApi', data.rates[this.selectedCode]);
      });
    this.commonService
      .updateCurrency('GBP', this.selectedCode)
      .subscribe((data: any) => {
        console.log(data);
        sessionStorage.setItem('ratehawkCurrencyApi', data.rates[this.selectedCode]);
      });
  }

  onChangeRegion(modal?: any) {
    if(this.router == '/Listing') {
      modal = this.currencyNotice
    }
    this.modalRef = this.modalService?.show(modal, {
      class: 'modal-lg modal-dialog-centered',
    });
  }

  dis(code?: string, country?: string) {
    this.modalRef?.hide();
    if (code && country) {
      this.selectedCode = code;
      this.selectedCountry = country;
    }
  }

  setUrl() {
    this.navigationService?.setPreviousUrl(this.router);
  }

  onChangeCurrency(currencyCode?: string, country?: string) {
    this.selectedCode = currencyCode;
    this.selectedCountry = country;
    let currency = {
      code: this.selectedCode,
      country: this.selectedCountry,
    };
    sessionStorage?.setItem('currency', JSON?.stringify(currency));
    this.modalRef?.hide();
    this.currencyApi();
    if (this.route.url != '/Listing') {
      setTimeout(() => {
        location.reload();
      }, 1000);
    }
    setTimeout(() => {
      if (this.route.url == '/Listing') {
        this.route?.navigate(['/']);
        let params = sessionStorage.getItem('request');
        if (params) {
          let requestData = JSON.parse(params);
          this.commonService.updateSearchQuery(requestData);       
          this.route?.navigate(['/Listing']);
          this.loadingService?.setLoading(true);
        }
      }
    }, 1000);
  }

  // toggleDropdown(event: Event): void {
  //   event.stopPropagation(); // Stop the event from propagating further
  //   const dropdownElement = document.getElementById('profileDropdown');
  //   if (dropdownElement) {
  //     dropdownElement.classList.toggle('show'); // This will toggle the 'show' class
  //   }
  // }

  logOut() {
    this.authService?.logout();
    this.route?.navigate(['/']);
  }

  ngOnDestroy(): void {
    this.dataSub?.unsubscribe();
  }
}
