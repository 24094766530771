import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  hide = true;
  confirmHide = true;
  image: any;
  passwordForm: any = FormGroup;
  mismatchPassword: boolean = false;
  resetPasswordSub!: Subscription;
  constructor(
    private configService: ConfigService,
    private fb: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    private toastService: ToastService
  ) {
    this.image = this.configService?.getImages();
    this.passwordForm = this.fb?.group({
      password: [
        '',
        [
          Validators?.required,
          Validators?.pattern(
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+]).{8,}$/
          ),
        ],
      ],
      passwordAgain: ['', [Validators?.required]],
    });
  }

  ngOnInit(): void {}

  submit() {
    if (this.passwordForm.valid) {
      if (
        this.passwordForm?.value?.password !=
        this.passwordForm?.value?.passwordAgain
      ) {
        this.mismatchPassword = true;
        return;
      }
      this.mismatchPassword = false;
      let params = {
        password: this.passwordForm?.value?.password,
        confirmPassword: this.passwordForm?.value?.passwordAgain,
      };

      // this.resetPasswordSub?.unsubscribe();
      this.resetPasswordSub = this.commonService?.resetPassword(params)?.subscribe((data: any) => {
          this.toastService?.showSuccess(data.message);
          if (data?.message == 'Updated Successfully') {
            this.router?.navigate(['/']);
            sessionStorage?.removeItem('forgotToken');
            sessionStorage?.removeItem('isForgot');
            sessionStorage?.removeItem('forgotEmail');
          }
        });
    }
    this.passwordForm.controls.password.touched = true;
  }

  ngOnDestroy(): void {
    this.resetPasswordSub?.unsubscribe();
  }
}
