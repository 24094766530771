import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from 'src/app/core/services/toast.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { CommonService } from 'src/app/core/services/common.service';
import { Router } from '@angular/router';
import { DataTransferServiceService } from 'src/app/core/services/data-transfer-service.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit{
isRefundableDate: boolean = false;
nightPrice: boolean = false;
  apiCurrencyValue!: any;
  currencyCode!: any;
  ratehawkApiCurrencyValue:any
  promotionindex:any
roomDetails: any;
  getDetailSub: any;
  roomsData: any;
  isApiCalled: boolean= false;
  detailData: any;
  detailAccommodation: any;
  detailOverview: any;
  DATA: any;
  boardbase: any;
  roomsArray:any = []
  cartArray: any = [];
  totalCartPrice: number = 0;
  statusCode: any;
  

  constructor(private toastService: ToastService, private clipboard: Clipboard, private commonService : CommonService, private route : Router, private dataTransferService : DataTransferServiceService, public bsModalRef: BsModalRef) {}

ngOnInit() {
  const refundableDate = sessionStorage.getItem('isRefundableDate');
  this.isRefundableDate = refundableDate === 'true';
  console.log(this.isRefundableDate);
  this.apiCurrencyValue = sessionStorage.getItem('currencyApi');
  this.ratehawkApiCurrencyValue = sessionStorage.getItem('ratehawkCurrencyApi');
  const storedCurrency = sessionStorage?.getItem('currency');
  if (storedCurrency) {
    let currencyData = JSON?.parse(storedCurrency);
    this.currencyCode = currencyData?.code;
  }
  this.dataTransferService.boardbase$.subscribe((data: any) => {
    // apiCalled = true
    this.boardbase = data;
    this.getDetail();
  });
}
@Input() selectedCartHotel : any
@Input() request : any
@Output() closeModal:  EventEmitter<boolean> = new EventEmitter<boolean>();


getStarsArray(rating: number): number[] {
  return Array?.from({ length: rating }, (_, index) => index + 1);
}

getNumericRating(rating: number) {
  return rating;
}
copyText(text: any) {
  this.clipboard?.copy(text);
  this.toastService.showSuccess('Text copied')
}

assignIndex(i:number, spliced? : boolean) {
  this.roomsArray[i] = spliced ? true : !this.roomsArray[i]
  if(this.roomsArray[i]) {
    this.roomsArray.forEach((data : any, idx: number) =>{
      if(i != idx) this.roomsArray[idx] = false
    })
  }
}

getDetail() {
  let params = this.commonService?.getDetailQuery()?.value;
  console.log(params, "detail")
  this.getDetailSub?.unsubscribe()
  this.getDetailSub = this.commonService?.getDetail(params)?.subscribe((data) => {
    this.statusCode = data.statusCode
    // if(this.statusCode == 1000) this.toastService.showSuccess("Hotel Details Fetched")
    // if(this.statusCode == 1100) this.toastService.showWarning(data.message)
    // if(this.statusCode == 1200) this.toastService.showError("Not able to fetch Hotel Data")
    this.roomsArray[0] = true
    this.roomsData = null
    this.isApiCalled = true;
    this.detailData = data;
    this.request = data?.getListRequest
    this.detailAccommodation = data?.accommodationDetails;
    this.detailOverview = data?.overview;
    if (data) {
      this.DATA = data;
      this.roomsData = data?.rooms;
      this.roomDetails = data?.rooms;
      this.sortByPrice();
      if (this.boardbase) {
        this.roomsData.forEach((room: any, i: number) => {
          console.log(this.roomDetails);
          this.roomDetails[i].multipleRooms = room.multipleRooms.filter(
            (item: any) => {
              const roomOnly = this.boardbase['Room Only'];
              const breakfast = this.boardbase['Breakfast'];
              const halfBoard = this.boardbase['Half Board'];
              const fullBoard = this.boardbase['Full Board'];
              const allInclusive = this.boardbase['All Inclusive'];
              const ultraAllInclusive = this.boardbase['Ultra All Inclusive'];

              if (roomOnly && !breakfast && !halfBoard && !fullBoard && !allInclusive && !ultraAllInclusive) {
                return 'Room Only' == item?.roomInformation?.boardBase;
              } else if (!roomOnly && breakfast && !halfBoard && !fullBoard && !allInclusive && !ultraAllInclusive) {
                return 'Breakfast' == item?.roomInformation?.boardBase;
              } else if (!roomOnly && !breakfast && halfBoard && !fullBoard && !allInclusive && !ultraAllInclusive) {
                return 'Half Board' == item?.roomInformation?.boardBase;
              } else if (!roomOnly && !breakfast && !halfBoard && fullBoard && !allInclusive && !ultraAllInclusive) {
                return 'Full Board' == item?.roomInformation?.boardBase;
              } else if (!roomOnly && !breakfast && !halfBoard && !fullBoard && allInclusive && !ultraAllInclusive) {
                return 'All Inclusive' == item?.roomInformation?.boardBase;
              } else if (!roomOnly && !breakfast && !halfBoard && !fullBoard && !allInclusive && ultraAllInclusive) {
                return 'Ultra All Inclusive' == item?.roomInformation?.boardBase;
              } else if (!halfBoard && !fullBoard && roomOnly && breakfast) {
                return ['Room Only', 'Breakfast'].includes(item?.roomInformation?.boardBase);
              } else if (!breakfast && !halfBoard && roomOnly && fullBoard) {
                return ['Room Only', 'Full Board'].includes(item?.roomInformation?.boardBase);
              } else if (!breakfast && !fullBoard && roomOnly && halfBoard) {
                return ['Room Only', 'Half Board'].includes(item?.roomInformation?.boardBase);
              } else if (!roomOnly && !halfBoard && fullBoard && breakfast) {
                return ['Full Board', 'Breakfast'].includes(item?.roomInformation?.boardBase);
              } else if (!roomOnly && !fullBoard && halfBoard && breakfast) {
                return ['Half Board', 'Breakfast'].includes(item?.roomInformation?.boardBase);
              } else if (!roomOnly && !breakfast && halfBoard && fullBoard) {
                return ['Half Board', 'Full Board'].includes(item?.roomInformation?.boardBase);
              } else if (!halfBoard && !fullBoard && roomOnly && allInclusive) {
                return ['Room Only', 'All Inclusive'].includes(item?.roomInformation?.boardBase);
              } else if (!breakfast && !halfBoard && roomOnly && ultraAllInclusive) {
                return ['Room Only', 'Ultra All Inclusive'].includes(item?.roomInformation?.boardBase);
              } else if (!roomOnly && !halfBoard && allInclusive && breakfast) {
                return ['All Inclusive', 'Breakfast'].includes(item?.roomInformation?.boardBase);
              } else if (!roomOnly && !breakfast && halfBoard && allInclusive) {
                return ['Half Board', 'All Inclusive'].includes(item?.roomInformation?.boardBase);
              } else if (!roomOnly && !breakfast && fullBoard && ultraAllInclusive) {
                return ['Full Board', 'Ultra All Inclusive'].includes(item?.roomInformation?.boardBase);
              } else if (!roomOnly && !fullBoard && halfBoard && ultraAllInclusive) {
                return ['Half Board', 'Ultra All Inclusive'].includes(item?.roomInformation?.boardBase);
              } else if (!roomOnly && !allInclusive && ultraAllInclusive && breakfast) {
                return ['Ultra All Inclusive', 'Breakfast'].includes(item?.roomInformation?.boardBase);
              } else if (!roomOnly && !ultraAllInclusive && allInclusive && breakfast) {
                return ['All Inclusive', 'Breakfast'].includes(item?.roomInformation?.boardBase);
              } else if (!roomOnly && !ultraAllInclusive && allInclusive && fullBoard) {
                return ['All Inclusive', 'Full Board'].includes(item?.roomInformation?.boardBase);
              } else if (!fullBoard && roomOnly && halfBoard && breakfast) {
                return ['Room Only', 'Half Board', 'Breakfast'].includes(item?.roomInformation?.boardBase);
              } else if (!halfBoard && roomOnly && allInclusive && breakfast) {
                return ['Room Only', 'All Inclusive', 'Breakfast'].includes(item?.roomInformation?.boardBase);
              } else if (!ultraAllInclusive && roomOnly && allInclusive && fullBoard) {
                return ['Room Only', 'All Inclusive', 'Full Board'].includes(item?.roomInformation?.boardBase);
              } else if (!roomOnly && ultraAllInclusive && allInclusive && breakfast) {
                return ['Ultra All Inclusive', 'All Inclusive', 'Breakfast'].includes(item?.roomInformation?.boardBase);
              } else if (!fullBoard && roomOnly && halfBoard && ultraAllInclusive) {
                return ['Room Only', 'Half Board', 'Ultra All Inclusive'].includes(item?.roomInformation?.boardBase);
              } else if (!halfBoard && ultraAllInclusive && allInclusive && breakfast) {
                return ['Ultra All Inclusive', 'All Inclusive', 'Breakfast'].includes(item?.roomInformation?.boardBase);
              } else {
                return ['Room Only', 'Breakfast', 'Half Board', 'Full Board', 'All Inclusive', 'Ultra All Inclusive'].includes(item?.roomInformation?.boardBase);
              }
            }
          );
        });
        this.roomsData.forEach((room: any, i: number) => {
          console.log(this.roomDetails);
          this.roomDetails[i].multipleRooms = room.multipleRooms.filter(
            (item: any) => {
              return this.boardbase['Refundable'] &&
                !this.boardbase['Non-Refundable']
                ? 'Refundable' == item.roomInformation.paymentType
                : !this.boardbase['Refundable'] &&
                  this.boardbase['Non-Refundable']
                  ? 'Non-Refundable' == item.roomInformation.paymentType
                  : 'Refundable' == item.roomInformation.paymentType ||
                  'Non-Refundable' == item.roomInformation.paymentType;
            }
          );
        });
      }
    }
    console.log(this.detailData);
  }, (error) => {
    this.statusCode = 1200
    this.isApiCalled = true
    // this.toastService.showError("Not able to fetch Hotel Data")
  });
}
sortByPrice() {
  this.roomDetails.sort((a: any, b: any) => {
    const priceA = ["222","444", "666"].includes(this.DATA.supplier)?a.multipleRooms[0]?.price?.totalPrice * this.ratehawkApiCurrencyValue :a.multipleRooms[0]?.price?.totalPrice * this.apiCurrencyValue;
    const priceB = ["222","444", "666"].includes(this.DATA.supplier)?b.multipleRooms[0]?.price?.totalPrice * this.ratehawkApiCurrencyValue :b.multipleRooms[0]?.price?.totalPrice * this.apiCurrencyValue;
    return priceA - priceB;
  });
}

dis() {
  this.closeModal.emit()
}

removeFromCart(i:any) {
  // this.roomDetails[this.cartArray[i].index].rooms.forEach(( data :any)=>{
  //   if(data['addedToCart']) data['']
  // })
  this.roomDetails[this.cartArray[i].roomIndex].multipleRooms[this.cartArray[i].index]['addedToCart'] = false
  this.roomDetails[this.cartArray[i].roomIndex]['addedToCart'] = false
  this.totalCartPrice -= this.cartArray[i].price.totalPrice
  this.assignIndex(this.cartArray[i].roomIndex,true)
  this.cartArray.splice(i,1)
}

  addToCart(room:any, i:number, roomIndex:number) {
    this.roomDetails[roomIndex].multipleRooms[i]['addedToCart'] = !this.roomDetails[roomIndex].multipleRooms[i]['addedToCart']
    this.roomDetails[roomIndex]['addedToCart'] = !this.roomDetails[roomIndex]['addedToCart']
  if(this.roomDetails[roomIndex].multipleRooms[i]['addedToCart'] && this.roomDetails[roomIndex]['addedToCart']) {
    room['roomIndex'] = roomIndex
    room['criteria'] = this.roomDetails[roomIndex].criteria
    room['index'] = i
    this.totalCartPrice += room.price.totalPrice
    this.cartArray.push(room)
    console.log(this.cartArray)
    this.assignIndex(roomIndex)
    if(this.roomDetails.length > roomIndex+1) this.assignIndex(roomIndex + 1)
  } else {
    this.totalCartPrice -= this.cartArray[i].price.totalPrice
    let cartIndex = this.cartArray.findIndex((data : any) => data.roomIndex == roomIndex)
      if(cartIndex) this.cartArray.splice(cartIndex,1)
  }

  }

onBooking() {
  console.log(this.cartArray)
  console.log(this.selectedCartHotel)
  let bookingRequest = {
    accId: this.selectedCartHotel.id,
    getListRequest: this.request,
    supplier: +this.selectedCartHotel.supplier,
    searchId: this.selectedCartHotel.searchId ? this.selectedCartHotel.searchId : "",
    rooms : this.cartArray
  };

  this.commonService?.updateBookingQuery(bookingRequest);
  this.closeModal.emit()
  this.route?.navigate(['Booking']);
}
}
