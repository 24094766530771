<div class="travel-booking-form-container">
  <mat-card class="travel-booking-card">
    <mat-card-content>
      <form [formGroup]="accommodationForm" (ngSubmit)="search()">
        <!-- Location Field -->
        <div class="form-field-container px-4">
          <h2 class="form-field-heading orange-color">Location :</h2>
          <mat-form-field appearance="outline" (click)="toggleLocationDropdown()" class="w-100">
            <mat-icon matPrefix fontIcon="room" class="orange-color"></mat-icon>
            <input type="text" (input)="onSearch($event)" matInput placeholder="Search" formControlName="searchControl"
              [formControl]="searchControl"  required>
            <mat-autocomplete #auto="matAutocomplete">
              <!-- <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                <mat-icon matPrefix fontIcon="room" class="orange-color"></mat-icon>
                {{ option }}
              </mat-option> -->
            </mat-autocomplete>
            
            <mat-error *ngIf="accommodationForm?.get('searchControl')?.hasError('required')"
              class="text-xs d-flex align-items-center mt-1">
              <mat-icon matPrefix fontIcon="info" class="to-red-600 mr-1"></mat-icon>
              Destination is required</mat-error>
          </mat-form-field>
          <div class="all-options" *ngIf="showLocationDropdown">
            <div class="destinations">
              <label class="text-start" for="">Destinations</label>
              <ng-container *ngIf="locations?.length > 0">
                <div class="px-1 py-1 text-start" *ngFor="let option of locations">
                  <div class="options" (click)="locationChange(option, 'destination')"><img src="/assets/images/locationIcon.png"
                      alt=""><span [matTooltip]="option" matTooltipPosition="right">{{option}}</span></div>
                </div>
              </ng-container>
              <ng-container *ngIf="!locations">
                <div class="px-1 py-1 text-start" *ngFor="let option of defaultLocations">
                  <div class="options" (click)="locationChange(option, 'destination')"><img src="/assets/images/locationIcon.png"
                      alt=""><span [matTooltip]="option" matTooltipPosition="right">{{option}}</span></div>
                </div>
              </ng-container>
            </div>
            <div class="hotels" >
              <label class="text-start" for="">Hotels</label>
              <ng-container *ngIf="hotels?.length > 0">
                <div class="px-1 py-1 text-start" *ngFor="let option of hotels">
                  <div class="options" (click)="locationChange(option, 'hotel')"><img src="/assets/images/hotelIcon.svg"
                      alt=""><span [matTooltip]="option?.hotels" matTooltipPosition="right">{{option?.hotels}}</span></div>
                </div>
              </ng-container>
              <ng-container *ngIf="!hotels">
                <div class="px-1 py-1 text-start" *ngFor="let option of defaultHotels">
                  <div class="options" (click)="locationChange(option, 'hotel')"><img src="/assets/images/hotelIcon.svg"
                      alt=""><span [matTooltip]="option?.hotels" matTooltipPosition="right">{{option?.hotels}}</span></div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- Check-in & Check-out Fields -->
        <div class="form-field-container row px-4">
          <!-- Date Field -->
          <h2 class="form-field-heading orange-color">Date :</h2>

          <mat-form-field appearance="outline" class="w-100">
            <mat-icon matPrefix fontIcon="date_range" class="orange-color"></mat-icon>
            <input matInput #drp="bsDaterangepicker" bsDaterangepicker [(ngModel)]="bsRangeValue" formControlName="date"
              placeholder="Check in - Check out"
              [bsConfig]="{ isAnimated: true, rangeInputFormat : 'DD/MM/YYYY', dateInputFormat: 'DD/MM/YYYY', minDate: initialDate, maxDate: maxYearDate, showWeekNumbers: false, preventChangeToNextMonth: true}"
              (bsValueChange)="onDateRangeChange($event)" (click)="onclick($event)">
            <!-- *ngIf="drp.isOpen" -->
            <div class="bs-datepicker-footer">
              <p class="text-sm font-poppins"> {{nights}} Nights</p>
            </div>

          </mat-form-field>


        </div>


        <!-- Passengers Field -->
        <div class="form-field-container row px-4">
          <h2 class="form-field-heading orange-color">Passengers :</h2>

          <!-- Rooms Dropdown -->
          <div class="booking-input">
            <mat-form-field appearance="outline" class="w-100">
              <input matInput placeholder="Select options" [(ngModel)]="bookingData" formControlName="passengers"
                (click)="toggleDropdown($event)" readonly>
              <mat-icon matPrefix (click)="toggleDropdown($event)" class="orange-color">person</mat-icon>
            </mat-form-field>        
          </div>
        </div>
        <div *ngIf="showDropdown" class="dropdown-content">
          <div class="dropdown-content-innner mx-4">
            <ng-container formArrayName="rooms">
              <div class="" *ngFor="let item of accommodationForm?.controls?.rooms?.controls; index as i">
                <div class="mb-3" [formGroupName]="i">
                  <div class="flex flex-row justify-content-around w-100  p-1">
                    <!-- <label class="text-sm col-md-5 text-left">Rooms:</label>
                    <select formControlName="room" class="text-center border">
                      <option *ngFor="let value of [1, 2, 3, 4, 5]" [value]="value">{{ value }}</option>
                    </select> -->
                    <p class="fw-bold">Room {{i+1}}</p>
                  </div>
                  <!-- </mat-form-field> -->
    
                  <div class="flex flex-row justify-content-around w-100 p-1">
                    <label class="text-sm col-md-5 text-left">Adults:</label>
                    <select formControlName="adults" class="text-center border">
                      <option *ngFor="let value of [1, 2, 3, 4, 5, 6]" [value]="value">{{ value }}</option>
                    </select>
                  </div>
    
                  <div class="flex flex-row justify-content-around w-100 p-1">
                    <label class="text-sm col-md-5 text-left">Children:</label>
                    <select formControlName="children" (change)="addChildrens(i,true,item?.get('children')?.value)" class="text-center border">
                      <option *ngFor="let value of [0, 1, 2, 3, 4]" [value]="value">{{ value }}</option>
                    </select>                      
                  </div>
                
                  <div *ngIf="item?.get('childrensAge')?.length > 0" >
                    <ng-container formArrayName="childrensAge">
                      <div *ngFor="let child of item?.get('childrensAge')?.controls ;  index as j" class="flex flex-row justify-content-around w-100 p-1">
                        <label class="text-sm col-md-5 text-left">Child {{j+1}} Age:</label>
                        <div [formGroupName]="j" class="flex flex-col">
                           <select formControlName="age" class="text-center border" >
                            <option *ngFor="let value of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]" [value]="value">{{ value }}</option>
                          </select>
                          <!-- <div class="d-flex control-property">
                            <img [ngStyle]="{'pointer-events': child.controls.age.value == 0 ? 'none' : 'unset'}"
                              (click)="onClickRoom('minus', i, child.controls.age.value, j)"
                              [src]=" child.controls.age.value == 0 ? '/assets/icons/minus.svg' : '/assets/icons/minus-green.svg' "
                              alt="">
                            <span>{{child.controls.age.value}}</span>
                            <img [ngStyle]="{'pointer-events': child.controls.age.value == 17 ? 'none' : 'unset'}"
                              (click)="onClickRoom('plus', i, child.controls.age.value, j)"
                              [src]=" child.controls.age.value == 17 ? '/assets/icons/plus.svg' : '/assets/icons/plus-green.svg' "
                              alt="">
                          </div> -->
                          <ng-container class="flex flex-col w-100 p-1">
                            <mat-error *ngIf="(child?.get('age')?.invalid) && (child?.get('age')?.dirty || child?.get('age')?.touched || submitted)"
                            class="text-xs d-flex align-items-center mt-1">
                            <mat-icon matPrefix fontIcon="info" class="to-red-600 mr-1"></mat-icon>
                            child age is required</mat-error>
                          </ng-container>
                        </div>
                       
                
                      </div>
                 
                  
                      
                     </ng-container> 
                  </div>
                 
                  <!-- <div class="flex flex-row w-100 p-1">
                    <label class="text-sm col-md-5 text-left">Infants:</label>
                    <select formControlName="infants" class="text-center border">
                      <option *ngFor="let value of [0,1, 2, 3, 4, 5, 6, 7, 8, 9]" [value]="value">{{ value }}</option>
                    </select>
                  </div> -->
                  
                </div>
                <button (click)="removeRoom(i)" *ngIf="i>0" class="btn btn-sm btn-danger mb-1" type="button">remove room</button>
              </div>
              <div class="add-room">
                <button (click)="addAnotherRoom()" class="btn btn-outline" type="button">Add Another room</button>
              </div>
            </ng-container>
            <button mat-button color="warn" class="flex items-center doneButton" type="button" (click)="updateBookingData()">Done</button>
          </div>
          

          <!-- <mat-form-field class="w-100" appearance="fill"> -->
          
           
          

        </div>

        <!-- Board Base Dropdown Field -->
        <div class="form-field-container px-4">
          <h2 class="form-field-heading orange-color">Nationality :</h2>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput  formControlName="nationalityControl" [formControl]="nationalityControl" [matAutocomplete]="nationalAutoComplete" placeholder="Choose your nationality" required>
            <mat-autocomplete #nationalAutoComplete="matAutocomplete">
              <mat-option *ngFor="let x of filteredNationalities | async" [value]="x?.countryName + ' - ' + x?.countryCode" (click)="onSelectNationality(x?.countryCode)">
                {{x?.countryName}} - {{x?.countryCode}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="accommodationForm?.get('nationalityControl')?.hasError('required')"
              class="text-xs d-flex align-items-center mt-1">
              <mat-icon matPrefix fontIcon="info" class="to-red-600 mr-1"></mat-icon>
              Nationality is required</mat-error>
          </mat-form-field>
        </div>
        
        <!-- Search Button -->
        <div class="px-4">
          <button class="main-button p-2 mt-2 w-100 mb-3" type="submit">Search</button>
        </div>
      </form>
    </mat-card-content>

  </mat-card>

</div>