import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { DataTransferServiceService } from 'src/app/core/services/data-transfer-service.service';
import { ToastService } from 'src/app/core/services/toast.service';
 
@Component({
  selector: 'app-successful',
  templateUrl: './successful.component.html',
  styleUrls: ['./successful.component.scss'],
})
export class SuccessfulComponent implements OnInit, OnDestroy {
  receipt: boolean = false;
  showReceiptModal = false;
  loader: boolean = true;
  success: boolean = true;
  bookingDetails: any;
  bookingData: any;
  closeModalSub!: Subscription;
  bookingDetailDataSub!: Subscription;
  statusCode: any;
  bookingRefId: any;
  bookingTimeout: any;
  ngOnInit(): void {
    this.openModal();
    this.bookingRefId = sessionStorage.getItem('bookingRefId');
    if (!this.bookingRefId) {
      if (sessionStorage.getItem('paymentMethod') == 'cardPayment') {
        this.booking();
      }
 
      if (sessionStorage?.getItem('paymentMethod') == 'manualPayment') {
        this.bookingDetailDataSub?.unsubscribe();
        this.bookingDetailDataSub =
          this.dataTransferService?.bookingDetailData$?.subscribe(
            (data: any) => {
              this.closeModal()
              this.bookingData = data;
              this.bookingDetails = data?.bookingDetail;
              this.bookingTimeout = setTimeout(() => {
                this.router?.navigate(['MyBookings']);
              }, 60000);
            }
          );
      }
    }
    if (this.bookingRefId) {
      this.showReceiptModal = false;
      this.receipt = false;
      this.bookingWithRefId();
    }
  }
 
  // Method to close the modal
  closeReceiptModal(): void {
    this.router?.navigate(['MyBookings']);
  }
 
  closingModalRef!: BsModalRef;
  @ViewChild('successModal', { static: true }) successModal: any;
 
  constructor(
    private modalService: BsModalService,
    private bsModalRef: BsModalRef,
    private router: Router,
    private dataTransferService: DataTransferServiceService,
    private commonService: CommonService,
    private toastService: ToastService
  ) {}
 
  openModal() {
    this.closingModalRef = this.modalService?.show(this.successModal, {
      class: 'modal-md modal-dialog-centered',
      backdrop: 'static',
    });
  }
 
  closeModal() {
    this.closingModalRef?.hide();
    this.showReceiptModal = true;
    this.receipt = true;
    this.success = false;
  }
 
  bookingWithRefId() {
    let params = {
      paymentStatus: 'Success',
    };
 
    this.commonService.updateBooking(params).subscribe((data) => {
      if ((data.status = 'success')) {
        this.closingModalRef?.hide();
        this.router.navigate(['MyBookings']);
        this.toastService.showSuccess('Payment Successful');
        sessionStorage.removeItem('bookingRefId');
      }
    });
  }
 
  booking() {
    const bookingParamsString = sessionStorage?.getItem('bookingParams');
    let storedParams;
 
    if (bookingParamsString) {
      storedParams = JSON?.parse(bookingParamsString);
    }
    if (storedParams) {
      // Update the payment status if the params exist
      storedParams.paymentStatus = 'success';
      sessionStorage?.setItem('bookingParams', JSON?.stringify(storedParams));
    }
 
    let params = sessionStorage?.getItem('bookingParams');
 
    this.commonService?.booking(params)?.subscribe((data) => {
      if (data?.status == true) {
        this.statusCode = data.statusCode
        if (data?.bookingDetail) {
          this.closeModal();
          this.bookingData = data;
          this.bookingDetails = data?.bookingDetail;
          if(this.statusCode == 1101 || this.statusCode == 1102 || this.statusCode == 1103) this.toastService.showWarning(data?.message) 
            if(this.statusCode == 1200) this.toastService.showError("Booking Failed") 
            if(this.statusCode == 1000) this.toastService.showSuccess("Booking Successful")
          this.bookingTimeout = setTimeout(() => {
            this.router?.navigate(['MyBookings']);
          }, 60000);
        }
      } else {
        this.closeModal();
        this.statusCode = 1200
        this.toastService.showError("Booking Failed")
        this.router?.navigate(['failure']);
      }
    }, error => {
      this.closeModal();
      this.statusCode = 1200
      this.toastService.showError("Booking Failed")
      this.router?.navigate(['failure'])
    });
  }
 
  ngOnDestroy() {
    clearTimeout(this.bookingTimeout)
    this.closeModalSub?.unsubscribe();
    this.bookingDetailDataSub?.unsubscribe();
  }
}
 