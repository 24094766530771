import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ourLocations',
  templateUrl: './ourLocations.component.html',
  styleUrls: ['./ourLocations.component.scss']
})
export class OurLocationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
