<app-header *ngIf="url == 'MyBookings'" [logo]="image?.ratelocLogo" [section1]="'Dashboard'"
    [section2]="'My Bookings'"></app-header>

<div class="main-box font-poppins"
    [ngStyle]="{'height' : url == 'MyBookings' ? '100vh' : 'auto !important', 'overflow-y': url == 'MyBookings' ? 'scroll' :'visible'}">
    <div
        [ngClass]="{'pt-14': url == 'MyBookings', 'container ': url == 'MyBookings', 'container-dashboard' :  url == 'Dashboard', 'container-account' :  url == 'myAccount'}">
        <p class="row w-100 bg-orange-color fw-bolder m-0 p-2 bookingHistory">Booking History</p>
        <div class="row">
            <div class="col-sm-12 d-flex section-search pr-4 pl-2">

                <!-- <div class="col-sm-3 d-flex">
                    <div class="button1-container mx-1 py-1 px-2">
                        <button class="button1" (click)="filterRecent()">Recent
                        </button>
                    </div>
                    <div class="button2-container mx-1 py-1 px-2">
                        <button class="button2" (click)="filterUnpaid()" >Unpaid
                            <span>{{unpaidCount}}</span>
                        </button>
                    </div>
                </div> -->

                <div class="col-sm-3 d-flex">
                    <div
                        class="mx-1 py-1 px-2"
                        [ngClass]="{'button1-container': isActiveRecent, 'button2-container': !isActiveRecent}"
                    >
                        <button class="button1" (click)="toggleActive('recent'); filterRecent()">Recent</button>
                    </div>
                    <div
                        class="mx-1 py-1 px-2"
                        [ngClass]="{'button1-container': isActiveUnpaid, 'button2-container': !isActiveUnpaid && !isActiveTransfer, 'button3-container': isActiveTransfer}"
                    >
                        <button class="button2" (click)="toggleActive('unpaid'); filterUnpaid()">Unpaid</button>
                        <span>{{ unpaidCount }}</span>
                    </div>
                    <div
                        class="mx-1 py-1 px-2"
                        [ngClass]="{'button3-container': isActiveTransfer, 'button2-container': !isActiveTransfer}"
                    >
                        <button class="button3" (click)="toggleActive('transfers'); filterTransfers()">Transfers</button>
                    </div>
                </div>

                <!-- <div class="col-md-6"></div> -->
                <div class="col-sm-9 search-container" *ngIf="url == 'MyBookings'">
                    <div class="search-input">
                        <input type="text" placeholder="Search by Booking Ref" [(ngModel)]="searchQuery"
                            (input)="search()"><mat-icon class="py-1" fontIcon="search"></mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive my-bookings-table" [ngClass]="{'dasboard-table':url != 'MyBookings'}">
                    <table class="table table-bordered mb-0">
                        <thead *ngIf="!isActiveTransfer">
                            <tr>
                                <th>Booking Ref</th>
                                <th>Status</th>
                                <th>Lead Guest</th>
                                <th>Creation Date</th>
                                <th>Hotels</th>
                                <th>Payment Status</th>
                                <th>Arrival Date</th>
                                <th>Cancel Deadline</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let x of filteredData?filteredBookings:bookingHistory && !isActiveTransfer ; index as i">
                            <tr *ngIf="(i <=2 && true) || url == 'MyBookings'" (click)="toggleRow(i)">
                                <td>{{x?.bookingRefId}}</td>
                                <td [ngClass]="x?.bookingStatus == 'Confirmed' ? 'paid' : 'processing'">
                                    <i
                                        [ngClass]="x?.bookingStatus == 'Confirmed' ? 'fa fa-check-circle' : 'fa fa-info-circle'"></i>
                                    {{x?.bookingStatus}}
                                </td>
                                <td>
                                    <ng-container *ngIf="x?.isCart">
                                        <ng-container *ngFor="let y of x?.rooms">
                                            <div *ngFor="let person of y.paxes">
                                                <p *ngIf="person?.isLeader">
                                                    {{person?.fname}} {{person?.lname}}
                                                </p>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!x?.isCart">
                                        <ng-container *ngFor="let array of bookingHistoryNames[i]">
                                            <p *ngFor="let person of array">
                                                <span *ngIf="person?.isLeader">{{ person?.firstName }} {{ person?.lastName }}</span>
                                            </p>
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td>{{x?.bookedOn | date:'dd MMM yy'}}</td>
                                <td>{{x?.hotelName}}</td>
                                <td
                                    [ngClass]="x?.paymentStatus == 'Unpaid' || x.paymentStatus == 'Failed' ? 'unpaid' : 'paid'">
                                    {{x?.paymentStatus == 'Unpaid' || x.paymentStatus == 'Failed' ? 'Unpaid' : 'Paid'}}
                                </td>
                                <td>{{x?.checkInDate | date:'dd MMM yy'}}</td>
                                <td>{{x?.deadline}}</td>
                                <td>{{x?.price}} {{x?.convertedCurrency}}&nbsp;
                                    <i class="fa fa-chevron-down" style="color: skyblue"
                                        *ngIf="!x?.details?.expanded && url == 'MyBookings'"></i>
                                    <i class="fa fa-chevron-up" style="color: skyblue"
                                        *ngIf="x?.details?.expanded && url == 'MyBookings'"></i>
                                </td>
                            </tr>


                            <tr *ngIf="isRowExpanded(i) && url == 'MyBookings' " class="expandedRow">
                                <td colspan="12" class="expandedTd">
                                    <table class="table table-bordered expandedTable m-0">
                                        <thead class="expandedThead">
                                            <tr>
                                                <th>Stay Dates</th>
                                                <th>Room Name</th>
                                                <th>Boardbase</th>
                                                <th>Lead Guest</th>
                                                <th>Cancel Deadline</th>
                                                <!-- <th>Price</th> -->
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Check-in <p style="font-weight: bold; margin-top: 5px;">
                                                        {{x?.checkInDate | date:'dd MMM yy'}}</p>
                                                    <hr>
                                                    Check-out at<p style="font-weight: bold; margin-top: 5px;">
                                                        {{x?.checkOutDate | date:'dd MMM yy'}}</p>
                                                    <hr>
                                                    <p>{{x?.nights}} Nights</p>
                                                </td>
                                                <td *ngIf="x?.isCart">
                                                    <ng-container *ngFor="let array of x?.rooms">
                                                        <p>
                                                            {{array?.roomInformation.roomName}}
                                                        </p>
                                                        <hr>
                                                    </ng-container>
                                                </td>
                                                <td *ngIf="x?.isCart">
                                                    <ng-container *ngFor="let array of x?.rooms">
                                                        <p>
                                                            {{array?.roomInformation.boardBase}}
                                                        </p>
                                                        <hr>
                                                    </ng-container>
                                                </td>
                                                <td *ngIf="!x?.isCart">
                                                    <ng-container *ngFor="let array of bookingHistoryNames[i]">
                                                        <p>
                                                            {{x?.roomName}}
                                                        </p>
                                                    </ng-container>
                                                </td>
                                                <td *ngIf="!x?.isCart">
                                                    <ng-container *ngFor="let array of bookingHistoryNames[i]">
                                                        <p>
                                                            {{x?.boardBase}}
                                                        </p>
                                                </ng-container>
                                                </td>
                                                <td>
                                                    <ng-container *ngIf="x?.isCart">
                                                        <ng-container *ngFor="let y of x?.rooms">
                                                            <div *ngFor="let person of y.paxes">
                                                                <p *ngIf="person?.isLeader">
                                                                    {{person?.fname}} {{person?.lname}}
                                                                </p>
                                                                <hr *ngIf="person?.isLeader">
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngIf="!x?.isCart">
                                                        <ng-container *ngFor="let array of bookingHistoryNames[i]">
                                                            <p *ngFor="let person of array">
                                                                <span *ngIf="person?.isLeader">{{ person?.firstName }} {{ person?.lastName }}</span>
                                                            </p>
                                                        </ng-container>
                                                    </ng-container>
                                                </td>
                                                <td>
                                                    <p>{{ x?.deadline}}
                                                    </p>
                                                    <hr
                                                        *ngIf="(x?.paymentStatus == 'Unpaid' || x.paymentStatus == 'Failed') && (x?.bookingStatus != 'Cancelled' && x?.bookingStatus != 'Failed')">
                                                    <div
                                                        *ngIf="(x?.paymentStatus == 'Unpaid' || x.paymentStatus == 'Failed') && (x?.bookingStatus != 'Cancelled' && x?.bookingStatus != 'Failed')">
                                                        <button class="btn payNow"
                                                            (click)="payNow(x?.bookingRefId, x?.price)">Pay Now</button>
                                                        <p class="mt-2 redColor">*Additional charge
                                                            {{calculatePercentOfTotalPrice(x?.price)}} would be apply
                                                        </p>
                                                    </div>
                                                    <hr
                                                        *ngIf="x?.paymentStatus == 'Unpaid' && (x?.bookingStatus != 'Cancelled' && x?.bookingStatus != 'Failed')">
                                                    <p
                                                        *ngIf="x?.bookingStatus != 'Cancelled' && x?.bookingStatus != 'Failed'">
                                                        <button class="btn cancel"
                                                            (click)="openModal(x?.bookingRefId,x?.supplierRefId, x.supplierName)">Cancel</button>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p
                                                        [ngClass]="x?.bookingStatus == 'Confirmed' ? 'confirmed' : 'processed'">
                                                        {{x ?.bookingStatus}}</p>
                                                    <button printSectionId="print-section" [openNewTab]="false"
                                                        [useExistingCss]="true" printTitle="RATELOC" class="btn btn-sm"
                                                        ngxPrint styleSheetFile="./booking.component.scss"> <i
                                                            class="fa fa-print" style="font-size:23px;"></i></button>
                                                </td>
                                                <!-- print -->
                                                <div class=" text-capitalize bg-white" id="print-section"
                                                    style="display: none !important; background-color: #fdf3ec !important;">
                                                    <div class="HeaderPrintInfo ">
                                                        <div>
                                                            <img src="../../../../assets/images/logo.svg" alt=""
                                                                class="logoPrint">
                                                            <p class="m-0">{{'+44 331 630 0226'}}</p>
                                                            <p class="m-0">{{'help@rateloc.com'}}</p>
                                                        </div>
                                                        <div>
                                                            <h2>Booking Reference {{x?.bookingRefId}}</h2>
                                                            <!-- <p>this accommodation is booked by our partner</p> -->
                                                        </div>
                                                    </div>
                                                    <div class=" font-poppins mt-4" class="">
                                                        <div class="border p-2 mt-4">
                                                            <div class="">
                                                                <div>
                                                                    <div class="d-flex justify-content-between">
                                                                       <div>
                                                                        <h4 class=" fw-bold ">{{x?.hotelName}}</h4>
                                                                        <span
                                                                        class=" hotelAddress">{{x?.address}}</span>
                                                                       </div>
                                                                       <!-- <div>
                                                                        <p class=""> <span
                                                                            class=" fw-bold  ">Supplier
                                                                            reference: {{x?.supplierRefId}}</span>
                                                                    </p>
                                                                       </div> -->
                                                                       
                                                                    </div>
                                                                    
                                                                    <hr>
                                                                    <div class="d-flex justify-content-between">
                                                                        <div class="">
                                                                            <div>
                                                                                <span class=" fw-bold">Property
                                                                                    type : </span>
                                                                                <span class="">{{x?.roomType}}</span>
                                                                            </div>
                                                                            <div *ngIf="x?.hotelContact">
                                                                                <span class=" fw-bold">Hotel
                                                                                    Contact : </span>
                                                                                <span class="">{{x?.hotelContact}}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="">
                                                                            <div>
                                                                                <span class=" fw-bold">Check-in :
                                                                                </span>
                                                                                <span class="">{{x?.checkInDate}}</span>
                                                                            </div>
                                                                            <div>
                                                                                <span class=" fw-bold">Check-out
                                                                                    : </span>
                                                                                <span
                                                                                    class="">{{x?.checkOutDate}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="px-3 my-3">
                                                            <span class="fw-bold">Status :</span> <span
                                                                style="color: #a2c030; font-weight: 700; font-size: 18px;">{{x?.bookingStatus}}</span>
                                                        </div>
                                                        <div class="px-3 my-3">
                                                            <div *ngFor="let g of bookingHistoryNames[i]; index as i"
                                                                class="row"
                                                                [ngClass]="{'border-bottom': bookingHistoryNames?.length - 1 >  i,'mt-3': bookingHistoryNames?.length  >  i,  }">
                                                                <div class="d-flex justify-content-between">
                                                                    <h6 class="fw-bold mb-3 roomName fw-18">
                                                                        {{x?.roomName}}</h6>
                                                                    <p class="fw-bold mb-0">Meal Type</p>
                                                                </div>
                                                                <ng-container *ngIf="x?.isCart === false">
                                                                    <ng-container *ngFor="let itrm of g; index as i">
                                                                        <div class="d-flex justify-content-between mb-3">
                                                                            <div>
                                                                                <strong class="fw-18" [ngStyle]="{visibility: i==0? 'visible' : 'hidden'}">Guests : </strong>
                                                                                <small class="fw-15">{{itrm?.firstName }}
                                                                                    {{itrm?.lastName}}</small>
                                                                                    <span *ngIf="itrm.childAges"> ({{itrm.childAges}}) </span>
                                                                            </div>
                                                                            <div [ngStyle]="{visibility:  i==0? 'visible' : 'hidden'}">
                                                                                <p class="m-0">{{x?.boardBase}}</p>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-container>
                                                               
                                                                <ng-container *ngIf="x?.isCart">
                                                                    <ng-container *ngFor="let z of x?.rooms; index as i">
                                                                        <div class="d-flex justify-content-between mb-3" *ngFor="let q of z.paxes; index as iii">
                                                                            <div>
                                                                                <strong class="fw-18" [ngStyle]="{visibility: iii==0 && i==0? 'visible' : 'hidden'}">Guests : </strong>
                                                                                <small class="fw-15">{{q?.fname }}
                                                                                    {{q?.lname}}</small>
                                                                                    <span *ngIf="q?.childAges"> ({{q?.childAges}})</span>
                                                                            </div>
                                                                            <div [ngStyle]="{visibility: iii==0 && i==0? 'visible' : 'hidden'}">
                                                                                <p class="m-0">{{z?.roomInformation.boardBase}}</p>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                    
                                                                </ng-container>
                                                           
                                                            </div>
                                                        </div>
                                                        <div class="d-flex justify-content-between px-3 my-3">
                                                            <div *ngIf="x?.remarks?.length > 0 && x?.remarks != 'null'">
                                                                <h3>Important. Please Note</h3>
                                                                <div *ngFor="let s of x?.remarks">
                                                                    <p class="m-0">&#x2022; {{s}}</p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h3>Cancellation Policy</h3>
                                                                <p>Cancellation date before {{x?.deadline}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tr>


                                        </tbody>
                                    </table>
                                </td>
                            </tr>

                        </tbody>

                        <thead *ngIf="isActiveTransfer">
                            <tr>
                                <th>Booking Ref</th>
                                <th>Status</th>
                                <th>Lead Guest</th>
                                <th>Creation Date</th>
                                <th>From</th>
                                <th>Payment Status</th>
                                <th>Cancel Deadline</th>
                                <th>Price</th>
                            </tr>
                        </thead>

                        <tbody *ngIf="isActiveTransfer" (click)="toggleTransferRow()">
                            <tr>
                                <td>{{ "ousiou4448" }}</td>
                                <td>
                                    <span>{{ "Confirmed" }}</span>
                                    <!-- <span *ngIf="transfer.status === 'Pending'" class="status pending">{{ transfer.status }}</span>
                                    <span *ngIf="transfer.status === 'Cancelled'" class="status cancelled">{{ transfer.status }}</span> -->
                                </td>
                                <td>{{"Some"}} {{"One"}}</td>
                                <td>{{ "24-06-2025" }}</td>
                                <td>{{ "Dubai Airport" }}</td>
                                <td>{{ "Paid" }}</td>
                                <td>{{ "12-06-2025" }}</td>
                                <td>{{ "GBP 265" }}&nbsp;
                                    <i class="fa fa-chevron-down" style="color: skyblue"
                                        *ngIf="!isTransferRowExpanded"></i>
                                    <i class="fa fa-chevron-up" style="color: skyblue"
                                        *ngIf="isTransferRowExpanded"></i>
                                </td>
                            </tr>
                            <tr *ngIf=" isTransferRowExpanded && isActiveTransfer">
                                <td colspan="12" class="expandedTd">
                                    <table class="table table-bordered expandedTable m-0">
                                        <thead class="expandedThead">
                                            <tr>
                                                <th>Dates</th>
                                                <th>Vehicle Type</th>
                                                <th>Detail</th>
                                                <th>Guests</th>
                                                <th>Cancel Deadline</th>
                                                <!-- <th>Price</th> -->
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                         <tr>
                                            <td>
                                                <p>Pick-up</p>
                                                <p>{{"15-09-25"}}</p>
                                                <hr>
                                                <p>Return Pick-up</p>
                                                <p>{{"25-09-25"}}</p>
                                            </td>
                                            <td>
                                                <p>{{"1 X Private transfer (1-3 people)"}}</p>
                                                <hr>
                                                <p>{{"1 X Private transfer (1-6 people)"}}</p>
                                            </td>
                                            <td>
                                                <p>From</p>
                                                <p>{{"Dubai Airport"}}</p>
                                                <hr>
                                                <p>From</p>
                                                <p>{{"Jbr beach hotel"}}</p>
                                            </td>
                                            <td>
                                                <p>{{"Some"}} {{"One"}}</p>
                                            </td>
                                            <td>
                                                <p>{{"18 May 2025"}}</p>
                                                <hr>
                                                <button>Cancel</button>
                                            </td>
                                            <td>
                                                <p>Confirmed</p>
                                            </td>
                                         </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>

                        

                    </table>
                </div>
            </div>
        </div>

        <!-- No Data Container -->
        <div *ngIf="noBookingAvailable" class="col-md-12 noDataContainer">
            <img src="/assets/icons/calendar-check.svg" alt="">
            <h2>Your haven't made your first booking yet !!!</h2>
        </div>
        <div *ngIf="(url == 'Dashboard' || url == 'myAccount') && !noBookingAvailable" class="boxRow">
            <div class="col-md-12 view-more-container">
                <button (click)="navigate()">View More</button>
            </div>
        </div>
    </div>
</div>

<ng-template #cancelModal>
    <div class="outer-container">
        <div class="inner-container">
            <div class="header">Are you sure?</div>
            <div class="note-box">
                <div class="note-header">Note</div>
                <p class="note-text">If you click on yes your booking {{bookingRefId}} will be cancelled.</p>
            </div>
            <div class="buttons">
                <button class="cancel-button" (click)="closeModal()">Cancel</button>
                <button class="yes-button" (click)="bookingCancel();openLoaderModal()">Yes</button>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #loaderModal>
    <div class="p-4">
        <div id="loading-indicator">
            <div class="lds-hourglass"></div>
        </div>
        <p class="message-type text-center">Please wait, while we cancel the booking!</p>
    </div>
</ng-template>