import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  pdfSrc: any;

  constructor(
    private modalService: BsModalService,
    private bsModalRef: BsModalRef,
  ) { }

  closingModalRef!: BsModalRef;
  @ViewChild('pdfModal', { static: true }) pdfModal: any;

  ngOnInit() {
  }

  openPdfModal(pdfPath: any) {
    this.pdfSrc = pdfPath;
    this.closingModalRef = this.modalService?.show(this.pdfModal, {
      class: 'modal-md',
    });
  }

}
