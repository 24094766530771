import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import * as nationalData from '../../../../assets/national.json';
import { CommonService } from '../../services/common.service';
import { ConfigService } from '../../services/config.service';
import { DataTransferServiceService } from '../../services/data-transfer-service.service';
import { LoadingService } from '../../services/Loading.service';
import { ToastService } from '../../services/toast.service';

function onlyLettersValidator(control: any) {
  const regex = /^[a-zA-Z\s]*$/; // Regex to allow only letters and spaces
  if (!regex?.test(control?.value)) {
    return { invalidChars: true }; // Return error if the input contains invalid characters
  }
  return null;
}
@Component({
  selector: 'app-bookingSummary',
  templateUrl: './bookingSummary.component.html',
  styleUrls: ['./bookingSummary.component.scss'],
})
export class BookingSummaryComponent implements OnInit, OnDestroy {
  checkOutDate: any;
  checkInDate: any;
  bookingSummarySub!: Subscription;
  stripeBookingSub!: Subscription;
  bookingSub!: Subscription;
  apiCurrencyValue: any;
  ratehawkApiCurrencyValue: any;
  currencyCode: any;
  isCart: boolean=false;
  vendorPrice: number = 0;
  totalPrice: number = 0;
  convertedVendorPrice: number = 0;
  perNightPrice: number = 0;
  cartConvertedPrice: number = 0;
  cartRatehawkConvertedPrice: number = 0;
  allowBooking: boolean = false;
statusCode: any;
  BackIcon() {
    this.dataTransferService.shouldShowLoader = false;
    this.router.navigate(['/Listing']);
  }
  refundable: boolean = false;
  nonRefundable: boolean = false;
  userCount: number = 0;
  addUserForm: any = FormGroup;
  cancellationPolicy: boolean = true;
  AmmenitiesShow: boolean = false;
  payByCard: boolean = false;
  manualPayment: boolean = true;
  submitted: boolean = false;
  bookingAccommodation: any;
  paymentMethod: string = '';
  rating: string = 'FiveStars';
  successfullModalRef!: BsModalRef;
  image: any;
  bookingParams: any;
  passengerParams: any = { rooms: [[]] };
  url: any;
  globalIndex: number = 0;
  national = nationalData?.national;
  userdata: any;
  country = new FormControl();
  apiInProgress: boolean = false;
  loader: boolean = false;
  isRefundableDate: boolean = false;
  showImpInfo: boolean = false;
  openmodal: boolean = false;
  closingModalRef!: BsModalRef;
  roomsList:any
  @ViewChild('impModal', { static: true }) impModal: any;
  @ViewChild('restrictNotice', { static: true }) restrictNotice: any;
  @ViewChild('notFound', { static: true }) notFound: any;


  get paxesArray() {
    return this.addUserForm?.controls?.rooms?.controls[this.globalIndex]?.controls?.paxes as FormArray;
  }
  get roomsArray() {
    return this.addUserForm?.controls?.rooms as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    public loadingService: LoadingService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private _configService: ConfigService,
    private router: Router,
    private dataTransferService: DataTransferServiceService,
    private toastService : ToastService
  ) {
    this.addUserForm = this.fb.group({
      rooms: this.fb?.array([]),
      email: this.fb?.control('', [Validators?.required, this.emailValidation()]),
      phone: this.fb?.control(''),
      country: this.fb?.control('', Validators?.required),
      specialRequest: this.fb?.control(null),
      cancellation: this.fb?.control(null, Validators?.required),
      offers: this.fb?.control(null),
    });
  }

  ngOnInit(): void {

    const refundableDate = sessionStorage.getItem('isRefundableDate');
    this.allowBooking = sessionStorage.getItem('allowBooking') == 'true';
    if(!this.allowBooking) this.openModal(this.restrictNotice)
    this.isRefundableDate = refundableDate === 'true';
    console.log(this.isRefundableDate);

    this.apiCurrencyValue = sessionStorage.getItem('currencyApi');
    this.ratehawkApiCurrencyValue = sessionStorage.getItem('ratehawkCurrencyApi');
    console.log(this.apiCurrencyValue);
    console.log(sessionStorage.getItem('currencyApi'));
    
    
    const storedCurrency = sessionStorage?.getItem('currency');
    if(storedCurrency){
      let currencyData = JSON?.parse(storedCurrency);
      this.currencyCode = currencyData?.code;
    }
    this.loadingService?.setLoading(true);
    this.getBooking();
    this.image = this._configService?.getImages();

    this.url = this.router?.url;
    const email = sessionStorage?.getItem('email');
    const phoneNumber = sessionStorage?.getItem('phoneNumber');
    if (email || phoneNumber) {
      this.addUserForm?.get('email')?.setValue(email);
      this.addUserForm?.get('phone')?.setValue(phoneNumber);
    }

    let natnl = JSON?.parse(sessionStorage?.getItem('national')!);

    if (natnl) {
      this.addUserForm?.get('country')?.setValue(natnl);
    }
  }
  // phoneNumberValidator(): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: any } | null => {
  //     const phoneNumberRegex = /^\+\d{2,3}\s\d{10}$/;
  //     const valid = phoneNumberRegex.test(control.value);
  //     return valid ? null : { invalidPhoneNumber: true };
  //   };
  // }
  emailValidation(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const emailRegex: RegExp =
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const valid = emailRegex?.test(control?.value);
      return valid ? null : { invalidEmail: true };
    };
  }

  dis() {
    this.closingModalRef?.hide();
    this.router.navigate(['/Listing'])
  }

  getBooking() {
    let params = this.commonService?.getBookingQuery()?.value;
    let params1 = sessionStorage?.getItem('bookingSummary');

    this.bookingSummarySub?.unsubscribe();
    this.bookingSummarySub = this.commonService
      ?.bookingSummary(this.url == 'Booking/success' ? params1 : params)
      ?.subscribe((data) => {
        this.statusCode = data?.statusCode
        // if(this.statusCode == 1000) this.toastService.showSuccess("Booking Summary Fetched")
          // if(this.statusCode == 1100) this.toastService.showWarning(data?.message)
          // if(this.statusCode == 1200) this.toastService.showError("Not able to fetch Booking Summary")
          if(this.statusCode == 1100 || this.statusCode == 1200) this.openModal(this.notFound)
        sessionStorage?.setItem('bookingSummary', JSON?.stringify(params));
        this.loadingService?.setLoading(false);
        this.bookingAccommodation = data;
        const datePipe = new DatePipe('en-US');
        let dateString =  this.bookingAccommodation?.getListRequest?.checkInDate
        let parts = dateString?.split('-');
        let dateObject = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
        this.checkInDate = datePipe?.transform(dateObject, 'yyyy-MM-dd');

         dateString =  this.bookingAccommodation?.getListRequest?.checkOutDate
        parts = dateString?.split('-');
         dateObject = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
        this.checkOutDate = datePipe?.transform(dateObject, 'yyyy-MM-dd');
        this.roomsList= ["444", "666"].includes(this.bookingAccommodation.supplier)?  data.rooms : data?.getListRequest?.rooms;
        if(["444", "666"].includes(this.bookingAccommodation.supplier)) {
          this.isCart = true
          this.roomsList?.forEach((room: any, i: any) => {
            this.totalPrice += room.price.totalPrice
            this.perNightPrice += room.price.perNightPrice
            this.vendorPrice += room.price.vendorPrice
            let obj = {
              paxes: this.fb?.array([]),
              roomInformation : this.fb.group({
                boardBase : this.fb.control(room.roomInformation.boardBase),
                paymentType : this.fb.control(room.roomInformation.paymentType),
                roomName : this.fb.control(room.roomInformation.roomName),
                boardCode : this.fb.control(room.boardCode),
                bookingId : this.fb.control(room.bookingId),
                price : this.fb.group(room.price),
                convertedVendorPrice: ['222', '444',"666"].includes(this.bookingAccommodation.supplier)? room?.price?.vendorPrice*this.ratehawkApiCurrencyValue :room?.price?.vendorPrice*this.apiCurrencyValue
              })
            };
            this.convertedVendorPrice += ['222', '444',"666"].includes(this.bookingAccommodation.supplier)? room?.price?.vendorPrice*this.ratehawkApiCurrencyValue :room?.price?.vendorPrice*this.apiCurrencyValue
            let fgr = this.fb?.group(obj);
            this.roomsArray?.push(fgr);
            this.globalIndex = i;
            for (const key in room.criteria) {
              if (+room.criteria[key] > 0 && key != 'childAges')
                this.addcustomer(key, +room.criteria[key],i);
            }
          });
        } else {
          this.roomsList?.forEach((room: any, i: any) => {
            let obj = {
              paxes: this.fb?.array([]),
            };
            let fgr = this.fb?.group(obj);
            this.roomsArray?.push(fgr);
            this.globalIndex = i;
            for (const key in room) {
              if (+room[key] > 0 && key != 'childAges')
                this.addcustomer(key, +room[key],i);
            }
          });
        }

        

        // this.bookingAccommodation.request.rooms.forEach((e:any) => {
        //     let count = +e.adults + +e.children + +e.infants;
        //     console.log(count);
        //     console.log(e);

        //     console.log('add 2 room')
        //     for(let i =0; i<count; i++){
        //       this.addcustomer()
        //     }

        //   })
        this.cartConvertedPrice = this.totalPrice * this.apiCurrencyValue
        this.cartRatehawkConvertedPrice = this.totalPrice * this.ratehawkApiCurrencyValue
        if (data?.paymentType == 'Refundable' && !this.isRefundableDate) {
          this.refundable = true;
          this.paymentMethod = 'manual';
        } else {
          this.paymentMethod = 'payByCard';
          this.nonRefundable = true;
        }
      },(error => {
        this.statusCode = 1200
        this.loadingService?.setLoading(false);
        this.openModal(this.notFound)
        // this.toastService.showError("Not able to fetch Booking Summary")
      })
    );
  }

  getPassengerDetails() {
    this.roomsArray?.controls?.forEach((room: any, i: number) => {
      this.passengerParams.rooms[i] = [];
      room?.controls?.paxes?.controls?.forEach((user: any, j: number) => {
        let obj = {
          title: user?.controls?.title?.value,
          firstName: user?.controls?.fname?.value,
          lastName: user?.controls?.lname?.value,
          type: user?.controls?.type?.value,
          isLeader: j == 0,
          childAge: user.controls.childAges.value ? user.controls.childAges.value :undefined
        };
        if(this.isCart) {
          // this.addUserForm.controls.rooms?.controls[i]?['controls']?['paxes']?['controls'][j]?['controls']?['childAges']?.setValue(user.controls.childAges.value ? user.controls.childAges.value :undefined)
          user.controls.childAges?.setValue(user.controls.childAges.value ? user.controls.childAges.value :undefined)
          user.controls.isLeader?.setValue(j == 0)
        }
        this.passengerParams?.rooms[i]?.push(obj);
      });
    });
  }

  stripeBooking() {
    if(this.isCart) {
      this.bookingParams = {
        rooms: this.roomsArray.value,
        manageBookings: {
          email: this.addUserForm?.get('email')?.value,
          phoneNumber: this.addUserForm?.get('phone')?.value,
          countryCode: this.addUserForm?.get('country')?.value,
        },
        paymentMethod: sessionStorage?.getItem('paymentMethod'),
        accId : this.bookingAccommodation.accId,
        checkInDate: this.bookingAccommodation?.getListRequest?.checkInDate,
        checkOutDate: this.bookingAccommodation?.getListRequest?.checkOutDate,
        userId: Number(sessionStorage?.getItem('userId')),
        title: this.bookingAccommodation?.title,
        rating: this.bookingAccommodation.rating,
        deadline: this.bookingAccommodation?.deadline,
        totalPrice: Number(this.calculateTotalPrice().toFixed(2)),
        vendorPrice: +this.vendorPrice.toFixed(2),
        currency: this.currencyCode,
        convertedVendorPrice: +this.convertedVendorPrice.toFixed(2),
        address: this.bookingAccommodation?.address,
        images: this.bookingAccommodation?.images,
        paymentStatus: 'pending',
        nights: this.bookingAccommodation?.getListRequest?.nights,
        supplier: +this.bookingAccommodation.supplier,
        remarks: this.bookingAccommodation.remarks,
        specialRequest: this.addUserForm?.get('specialRequest')?.value
          ? this.addUserForm?.get('specialRequest')?.value
          : '',
      };
    } else {
      this.bookingParams = {
        rooms: this.passengerParams?.rooms,
        // rooms: [
        //   [
        //     {
        //       title: 'Mr.',
        //       firstName: 'Taha',
        //       middleName: 'Aquib',
        //       lastName: 'Khan',
        //       type: 'Adult',
        //     },
        //     {
        //       title: 'Mrs',
        //       firstName: 'Zainab',
        //       middleName: 'Taha',
        //       lastName: 'Khan',
        //       type: 'Adult',
        //     },
        //   ],
        // ],
        manageBookings: {
          email: this.addUserForm?.get('email')?.value,
          phoneNumber: this.addUserForm?.get('phone')?.value,
          countryCode: this.addUserForm?.get('country')?.value,
        },
        // accId : this.bookingAccommodation.accId,
        paymentMethod: sessionStorage?.getItem('paymentMethod'),
        userId: Number(sessionStorage?.getItem('userId')),
        bookingId: this.bookingAccommodation?.bookingId,
        evaluationToken:this.bookingAccommodation.evaluationToken,
        searchId:this.bookingAccommodation.getListRequest.searchId,
        title: this.bookingAccommodation?.title,
        roomType: this.bookingAccommodation?.roomType,
        roomName: this.bookingAccommodation.roomName,
        rating: this.bookingAccommodation.rating,
        deadline: this.bookingAccommodation.deadline,
        totalPrice: Number(this.calculateTotalPrice().toFixed(2)),
        vendorPrice: this.bookingAccommodation?.priceList?.vendorPrice,
        currency: this.currencyCode,
        convertedVendorPrice: ["222","444", "666"].includes(this.bookingAccommodation.supplier)? this.bookingAccommodation?.priceList?.vendorPrice*this.ratehawkApiCurrencyValue :this.bookingAccommodation?.priceList?.vendorPrice*this.apiCurrencyValue,
        checkInDate: this.bookingAccommodation?.getListRequest?.checkInDate,
        checkOutDate: this.bookingAccommodation?.getListRequest?.checkOutDate,
        nights: this.bookingAccommodation?.getListRequest?.nights,
        address: this.bookingAccommodation?.address,
        images: this.bookingAccommodation?.images,
        remarks: this.bookingAccommodation.remarks,
        paymentStatus: 'pending',
        boardBase: this.bookingAccommodation?.boardBase,
        nationality: this.bookingAccommodation?.getListRequest?.nationality,
        supplier: +this.bookingAccommodation.supplier,
        specialRequest: this.addUserForm?.get('specialRequest')?.value
          ? this.addUserForm?.get('specialRequest')?.value
          : '',
      };
    }
    

    sessionStorage?.setItem('bookingParams', JSON?.stringify(this.bookingParams));
    let url = window.location.href;
    let baseUrl = url.split('/').slice(0, 3).join('/') + '/';
    let params = {
      amount: this.calculateTotalPrice() ,
      currency: this.currencyCode,
      url: baseUrl + "accomodations"
    };
    sessionStorage.setItem("stripe",JSON.stringify(params))
    console.log(params);
    
  this.stripeBookingSub?.unsubscribe()
    this.stripeBookingSub = this.commonService?.stripeBooking(params)?.subscribe((data) => {

      window.location.href = data?.url;
    });
  }

  booking() {
    let params
    if(this.isCart) {
      params = {
        rooms: this.roomsArray.value,
        manageBookings: {
          email: this.addUserForm?.get('email')?.value,
          phoneNumber: this.addUserForm?.get('phone')?.value,
          countryCode: this.addUserForm?.get('country')?.value,
        },
        accId : this.bookingAccommodation.accId,
        bookingId: this.bookingAccommodation?.bookingId,
        evaluationToken:this.bookingAccommodation.evaluationToken,
        searchId:this.bookingAccommodation.getListRequest.searchId,
        paymentMethod: sessionStorage?.getItem('paymentMethod'),
        userId: Number(sessionStorage?.getItem('userId')),
        title: this.bookingAccommodation?.title,
        rating: this.bookingAccommodation.rating,
        checkInDate: this.bookingAccommodation?.getListRequest?.checkInDate,
        checkOutDate: this.bookingAccommodation?.getListRequest?.checkOutDate,
        deadline: this.bookingAccommodation?.deadline,
        totalPrice: +this.totalPrice.toFixed(2),
        vendorPrice: +this.vendorPrice.toFixed(2),
        remarks: this.bookingAccommodation.remarks,
        currency: this.currencyCode,
        convertedVendorPrice: +this.convertedVendorPrice.toFixed(2),
        address: this.bookingAccommodation?.address,
        images: this.bookingAccommodation?.images,
        nights: this.bookingAccommodation?.getListRequest?.nights,
        // paymentStatus: 'pending',
        supplier: +this.bookingAccommodation.supplier,
        specialRequest: this.addUserForm?.get('specialRequest')?.value
          ? this.addUserForm?.get('specialRequest')?.value
          : '',
      };
    } else {
      params = {
        rooms: this.passengerParams?.rooms,
        // rooms: [
        //   [
        //     {
        //       title: 'Mr.',
        //       firstName: 'Taha',
        //       middleName: 'Aquib',
        //       lastName: 'Khan',
        //       type: 'Adult',
        //     },
        //     {
        //       title: 'Mrs',
        //       firstName: 'Zainab',
        //       middleName: 'Taha',
        //       lastName: 'Khan',
        //       type: 'Adult',
        //     },
        //   ],
        // ],
        manageBookings: {
          email: this.addUserForm?.get('email')?.value,
          phoneNumber: this.addUserForm?.get('phone')?.value,
          countryCode: this.addUserForm?.get('country')?.value,
        },
        paymentMethod: sessionStorage?.getItem('paymentMethod'),
        userId: Number(sessionStorage?.getItem('userId')),
        bookingId: this.bookingAccommodation?.bookingId,
        evaluationToken:this.bookingAccommodation.evaluationToken,
        searchId:this.bookingAccommodation.getListRequest.searchId,
        accId:this.bookingAccommodation.accId,
        remarks:this.bookingAccommodation.remarks,
        title: this.bookingAccommodation?.title,
        roomType: this.bookingAccommodation?.roomType,
        roomName: this.bookingAccommodation?.roomName,
        rating: this.bookingAccommodation?.rating,
        deadline: this.bookingAccommodation?.deadline,
        totalPrice: Number((["222","444"].includes(this.bookingAccommodation.supplier)? this.bookingAccommodation?.priceList?.totalPrice*this.ratehawkApiCurrencyValue :this.bookingAccommodation?.priceList?.totalPrice*this.apiCurrencyValue).toFixed(2)),
        vendorPrice: this.bookingAccommodation?.priceList?.vendorPrice,
        currency: this.currencyCode,
        convertedVendorPrice: ["222","444"].includes(this.bookingAccommodation.supplier)? this.bookingAccommodation?.priceList?.vendorPrice*this.ratehawkApiCurrencyValue :this.bookingAccommodation?.priceList?.vendorPrice*this.apiCurrencyValue,
        checkInDate: this.bookingAccommodation?.getListRequest?.checkInDate,
        checkOutDate: this.bookingAccommodation?.getListRequest?.checkOutDate,
        nights: this.bookingAccommodation?.getListRequest?.nights,
        address: this.bookingAccommodation?.address,
        images: this.bookingAccommodation?.images,
        boardBase: this.bookingAccommodation?.boardBase,
        nationality: this.bookingAccommodation?.getListRequest?.nationality,
        supplier: +this.bookingAccommodation.supplier,
        specialRequest: this.addUserForm?.get('specialRequest')?.value
          ? this.addUserForm?.get('specialRequest')?.value
          : '',
      };
    }
    this.bookingSub?.unsubscribe()
    this.bookingSub = this.commonService?.booking(params)?.subscribe((data) => {
      this.apiInProgress = false;
      if (data?.status) {
        this.dataTransferService?.bookingDetail(data);
        this.router?.navigate(['/success']);
      } else {
        if(this.statusCode == 1101 || this.statusCode == 1102 || this.statusCode == 1103) {
          this.toastService.showWarning(data?.message)
          sessionStorage.setItem("errorMessage", data.message)
        } 
        if(this.statusCode == 1200) this.toastService.showWarning("Booking Failed") 
        if(this.statusCode == 1000) this.toastService.showSuccess("Booking Successful")
        this.router?.navigate(['failure']);
      }
    }, error => {
      this.statusCode = 1200
      this.toastService.showError("Booking Failed")
      this.router?.navigate(['failure'])
    }
  );
  }

  calculatePercentOfTotalPrice(): number {
    let totalPrice = parseFloat(this.isCart ? this.totalPrice : this.bookingAccommodation?.priceList?.totalPrice);
    if (!isNaN(totalPrice)) {
      let convertedPrice = (["222","444", "666"].includes(this.bookingAccommodation.supplier)? totalPrice * this.ratehawkApiCurrencyValue :totalPrice * this.apiCurrencyValue);
      let percentage = convertedPrice * 0.015;
      return +percentage.toFixed(2);
    }
    return 0;
  }
  

  calculateTotalPrice(): number {
    let totalPrice = parseFloat(
      this.isCart ? this.totalPrice : this.bookingAccommodation?.priceList?.totalPrice 
    );
    if (!isNaN(totalPrice)) {
      let convertedPrice = (["222","444", "666"].includes(this.bookingAccommodation.supplier)? totalPrice * this.ratehawkApiCurrencyValue :totalPrice * this.apiCurrencyValue);
      let percentage = convertedPrice * 1.015;
      return +percentage.toFixed(2);
    }
    return 0;
  }
  onAddUser() {
    // console.log(this.addUserForm);
    if(this.allowBooking) {
      this.submitted = true;
      this.cancellationPolicy = true;
  
      //  this.onCancellation()
      if (this.addUserForm?.valid && this.addUserForm?.controls?.cancellation?.value) {
        this.getPassengerDetails();
        if (this.paymentMethod == 'payByCard') {
          sessionStorage?.setItem('paymentMethod', 'cardPayment');
          this.stripeBooking();
          this.loader = true;
        } else {
          sessionStorage?.setItem('paymentMethod', 'manualPayment');
          this.apiInProgress = true;
          this.booking();
          this.loader = true;
        }
      }
    } else {
      this.openModal(this.restrictNotice)
    }
  }

  deleteItem(i: number) {
    this.paxesArray?.removeAt(i);
    if (i > 0) {
      this.userCount--;
    }
  }

  addUserBooking() {}

  addcustomer(type: string, count: number,ind:number) {
    for (let i = 0; i < count; i++) {
      let obj = {
        title: this.fb?.control('Mr', Validators?.required),
        fname: this.fb?.control(null, Validators?.required),
        type: this.fb?.control(type),
        lname: this.fb?.control(null, Validators?.required),
        isLeader: this.fb?.control(null),
        childAges:type=="children" ? this.isCart ? this.roomsList[ind].criteria['childAges'][i] : this.roomsList[ind]['childAges'][i] : undefined
      };
      const fgr = this.fb?.group(obj);
      this.paxesArray?.push(fgr);
    }
    console.log(this.addUserForm)
    this.userCount++;
  }

  onKeyPress(event: KeyboardEvent,allowSpace?:boolean) {
    const charCode = event.which ? event.which : event.keyCode;
    if ((charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122) && (charCode !== 32 || !allowSpace)) {
      event?.preventDefault();
    }
  }

  onAmmenities() {
    this.AmmenitiesShow = true;
  }

  onAmmentiesLeave() {
    this.AmmenitiesShow = false;
  }
  onCancellation() {
    this.cancellationPolicy = !this.cancellationPolicy;
  }

  onShowImpInfo() {
    this.openModal(this.impModal);
  }

  openModal(modal:any) {
    this.openmodal = true;
    this.closingModalRef = this.modalService?.show(modal, {
      class: 'modal-lg modal-dialog-centered',
      ignoreBackdropClick: false,
    });
  }

  closeModal() {
    if (this.openmodal) {
      // setTimeout(() => {
      this.openmodal = false;
      this.closingModalRef?.hide();
      // }, 2000);
    }
  }

  paymentMethods(value: string) {
    this.paymentMethod = value;
  }

  // payByCards() {
  //   this.payByCard =!this.payByCard;
  //   this.manualPayment =false
  //   this.addUserForm.controls.paymentMethod.setValue('payByCard')
  // }
  // manualPayments() {
  // this.manualPayment =!this.manualPayment;
  // this.payByCard=false
  // this.addUserForm.controls.paymentMethod.setValue('manual')

  // }
  getStarsArray(rating: number): number[] {
    return Array.from({ length: rating }, (_, index) => index + 1);
  }

  getNumericRating(rating: number) {
    return rating;
  }

  getTotalGuest(): number {
    let total = 0;
    this.bookingAccommodation?.getListRequest?.rooms?.forEach((booking: any) => {
      total += booking?.adults + booking?.children;
    });
    return total;
  }

  ngOnDestroy() {
    this.bookingSummarySub?.unsubscribe();
    this.stripeBookingSub?.unsubscribe()
    this.bookingSub?.unsubscribe()
  }
}
